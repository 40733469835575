<div penjiContentHeight (output_height)="getHeight($event)" class="absolute inset-0 py-3.5 px-5">

  <div class="flex justify-between items-center pb-2 pr-20">
    <h6 class="text-primary-500">Member By Squad</h6>
    <a [routerLink]="['/member']"
    [queryParams]="{ squad_id: squad_id }"
    queryParamsHandling="merge" class="view-all font-bold text-primary-500 hover:text-primary-600 hidden">View All</a>
  </div>
  <ng-container *ngIf="{list_member:list_member$ | async} as vm">
    <ng-container *ngIf="vm.list_member; else loading">
      <nz-table
        #table_list
        [nzFrontPagination]="false"
        [nzShowPagination]="false"
        [nzData]="vm.list_member"
        [nzBordered]="false"
        [nzSize]="'middle'"
        class="theme theme-default"
        [nzScroll]="{ x: '36.146vw', y: container_height - 120 + 'px' }">
        <thead>
          <tr>
            <th nzLeft nzWidth="240px">Member name</th>
            <th nzWidth="95px" [nzSortFn]="sortActiveProject">Project</th>
            <th nzWidth="120px">Status</th>
            <th nzWidth="170px">Performance 7 days</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of table_list.data; trackBy:trackBy">
            <tr inViewport (inViewportAction)="viewPort($event, item.id!)">
              <ng-container *ngIf="show_view_port[item.id!]; else loading_tr">
                <td nzLeft><penji-user-item [user_id]="item.id" [user_avatar]="true" [user_name]="true"></penji-user-item></td>
                <td>{{item?.total_active_project ?? 0}} / {{item?.out_put_design ?? 0}}</td>
                <td><penji-member-checkin-status-by-uid [uid]="item.id!"></penji-member-checkin-status-by-uid></td>
                <td><penji-member-last-seven-days-feedback [uid]="item.id!"></penji-member-last-seven-days-feedback></td>
              </ng-container>
            </tr>
          </ng-container>
          <ng-template #loading_tr>
            <td [colSpan]="4">
              <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzParagraph]="{ rows: 1, width: '100%' }"></nz-skeleton>
            </td>
          </ng-template>
        </tbody>
      </nz-table>
    </ng-container>
    <ng-template #loading>
      <nz-spin nzTip="Loading..." nzSize="large" class="absolute inset-0"></nz-spin>
    </ng-template>
  </ng-container>
</div>
