import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { UserSelectComponent } from '@penji/team/shared/shared-ui/user-select';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { UserArrayItemComponent } from '@penji/team/shared/shared-ui/user-array-item';
import { NzButtonModule } from 'ng-zorro-antd/button';

@Component({
  selector: 'penji-hr-management-assigned',
  standalone: true,
  imports: [
    CommonModule,
    NzPopoverModule,
    UserItemComponent,
    SvgIconComponent,
    UserSelectComponent,
    NzButtonModule,
    UserArrayItemComponent
  ],
  templateUrl: './hr-management-assigned.component.html',
  styleUrls: ['./hr-management-assigned.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HrManagementAssignedComponent {
  @Input() reassign_on = false;
  @Input() user_size: 'xs' | 'sm' | 'md' | 'lg' | 'xxl' = 'md';
  @Input() assign_by: string[] = [];
  @Output() list_user_id_output = new EventEmitter<string[]>();

  visible = false;
  async getListUserId(list_out_assign_by: string[]) {
    if (list_out_assign_by && list_out_assign_by.length > 0) {
    this.list_user_id_output.emit(list_out_assign_by);
      this.visible = false;
    }
  }
}
