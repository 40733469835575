import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectService, WhereQueryInterface } from '@penji/shared/data-access';
import * as firebase from 'firebase/firestore';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'penji-last-seven-days-feedback',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent
  ],
  templateUrl: './last-seven-days-feedback.component.html',
  styleUrls: ['./last-seven-days-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastSevenDaysFeedbackComponent {

  cdr = inject(ChangeDetectorRef);
  projectSV = inject(ProjectService);
  @Input() client_team_id!: string;
  result$: Promise<any> | undefined; 
  ngOnChanges() {
    if (this.client_team_id) {
      this.result$ = Promise.all([
        this.projectSV.getTotalProjectCompleteForCustomer(this.client_team_id, 2), 
        this.projectSV.getTotalProjectCompleteForCustomer(this.client_team_id, 7),
        this.projectSV.getTotalProjectCompleteForCustomer(this.client_team_id, 4)
      ])
    }
  }
}
