import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, customClaims, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '@penji/client-one-off/shared/shared-ui/layout';
import { map, pipe } from 'rxjs';

// guard
const clientOnly = () => pipe(customClaims, map(claims => {
  return claims.role === 3 || ['404'];
}));

// const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login-process']);
const redirectLoggedInToDashboard = () => redirectLoggedInTo(['/']);

const routes: Routes = [
  // {
  //   path: 'login',
  //   loadComponent: () => import('@penji/shared/auth/auth-ui/login').then(mod => mod.LoginComponent),
  //   canActivate: [AngularFireAuthGuard],
  //   data: { authGuardPipe: redirectLoggedInToDashboard }
  // },
  {
    path: 'login-process',
    loadComponent: () => import('@penji/shared/auth/auth-ui/login-processing').then(mod => mod.LoginProcessingComponent),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToDashboard }
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: '',
        redirectTo: 'project',
        pathMatch: 'full'
      },
      {
        path: 'project',
        loadChildren: () => import('@penji/client-one-off/project/features').then(mod => mod.FeaturesModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: clientOnly }
      },
      {
        path: 'profile',
        loadChildren: () => import('@penji/client-one-off/client/client-shell').then(mod => mod.ClientShellModule),
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: clientOnly }
      },
    ]
  },
  {
    path: '404',
    loadComponent: () => import('@penji/shared/ui/element/page404').then(mod => mod.Page404Component)
  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'top',
  })],
  exports: [RouterModule]
})
export class ShellRoutingModule { }
