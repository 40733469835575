/* eslint-disable */
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { DrawerService, Project } from '@penji/shared/data-access';
import { InputComponent } from '@penji/shared/ui/element/input';
import { CategoryTitleComponent } from '@penji/team/categories/category-ui/category-title';
import { DesignerTeamNameComponent } from '@penji/team/designer-team/designer-team-ui/designer-team-name';
import { ProjectStoreService } from '@penji/team/project/data-access';
import { ProjectAssignedComponent } from '@penji/team/project/project-ui/project-assigned';
import { ProjectCreateUpdateComponent } from '@penji/team/project/project-ui/project-create-update';
import { ProjectStatusComponent } from '@penji/team/project/project-ui/project-status';
import { CommonAlgoliaPaginatorComponent } from '@penji/team/shared/shared-ui/common-algolia-paginator';
import { CopyItemComponent } from '@penji/team/shared/shared-ui/copy-item';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { take } from 'rxjs';
import { ProjectDeadlineItemComponent } from '../../../project-deadline-item/src/lib/project-deadline-item/project-deadline-item.component';
import { ProjectReviewItemComponent } from '../../../project-review-item/src/lib/project-review-item/project-review-item.component';
import { InViewportAction, InViewportDirective } from 'ng-in-viewport';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
type DataItem = any;
@Component({
  selector: 'penji-project-w-table',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    InputComponent,
    NzTableModule,
    NzDividerModule,
    ProjectStatusComponent,
    ProjectAssignedComponent,
    DesignerTeamNameComponent,
    CategoryTitleComponent,
    DatePipe,
    SvgIconComponent,
    NzPopconfirmModule,
    NzAlertModule,
    ProjectReviewItemComponent,
    CopyItemComponent,
    ProjectDeadlineItemComponent,
    NzButtonModule,
    NzToolTipModule,
    NzDropDownModule,
    UserItemComponent,
    CommonAlgoliaPaginatorComponent,
    ProjectWTableComponent,
    InViewportDirective,
    NzSkeletonModule
  ],
  templateUrl: './project-w-table.component.html',
  styleUrls: ['./project-w-table.component.scss'],
  providers: [NzModalService, ProjectStoreService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectWTableComponent {
  @Input() list_project: Project[] = [];
  @Input() loading = true;
  @Input() class_table = 'theme-overdue';
  @Input() container_height = 0;

  drawerSv = inject(DrawerService);
  modal = inject(NzModalService);
  show_view_port: any = {};

  openDrawer(data: any) {
    const temp = {
      type: 'project',
      data: data
    }
    console.log(data);
    this.drawerSv.openDrawer(temp);
  }
  async editProject(project: Project) {
    const ref: NzModalRef = this.modal.create({
      nzContent: ProjectCreateUpdateComponent,
      nzBodyStyle: { padding: '10px', overflow: 'auto', background: '#fff' },
      nzComponentParams: {
        form_type: 'edit',
        project_data: project
      },
      nzTitle: 'Edit project',
      nzWidth: '90%',
      nzStyle: { top: '14px' },
      nzFooter: null,
    });
    const pj = await ref.afterClose.pipe(take(1)).toPromise();
    if (pj) {
      const temp = { ...project, ...pj };
      console.log(temp);
      // this.ProjectStoreService.updateProject$(temp);
    }
  }
  deleleProject(project: Project) {
    // this.ProjectStoreService.deleteProject$(project);
  }
  ngOnDestroy(): void {
    this.drawerSv.close();
  }
  viewPort(event: InViewportAction, id: string): void {
    if(event.visible){
      this.show_view_port[id] = true;
    }
  }
  trackBy(index:any, item: any) {
    return item.id;
  }
}
