import { SquadGroupStoreService } from '@penji/team/squad-group/data-access';
/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AuthService } from '@penji/shared/auth/data-access';
import { DesignerTeam, SquadGroup, UserData, UserService } from '@penji/shared/data-access';
import { DesignerTeamStoreService } from '@penji/team/designer-team/data-access';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { BehaviorSubject, Observable, combineLatest, debounceTime, distinctUntilChanged, map, of, share, switchMap, tap } from 'rxjs';

@Component({
  selector: 'penji-input-select-form',
  standalone: true,
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzTagModule
  ],
  templateUrl: './input-select-form.component.html',
  styleUrls: ['./input-select-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputSelectFormComponent implements OnInit {
  @Input() settings_form!: UntypedFormGroup;
  @Input() key!: string;
  @Input() value!: string;
  afs = inject(AngularFirestore);
  authSV = inject(AuthService);
  userSV = inject(UserService);
  DesignerTeamStoreService = inject(DesignerTeamStoreService);
  SquadGroupStoreService = inject(SquadGroupStoreService);
  selectedValue = '';
  list_user$: Observable<UserData[]> | undefined;
  list_designer_team$: Observable<DesignerTeam[]> | undefined;
  list_squad$: Observable<SquadGroup[]> | undefined;
  nzFilterOption = (): boolean => true;
  action_search$ = new BehaviorSubject<string>('');
  isLoading = false;

  ngOnInit(): void {
    if (this.key) {
      this.settings_form.addControl(this.key, new UntypedFormControl());
      this.settings_form.patchValue({
        [this.key]: this.value ?? null
      });
      this.selectedValue = this.value;
      switch (this.key) {
        case 'user_id':
          this.list_user$ = this.onSearchUser([2, 4, 7])
          break;
        case 'designer_team_id':
          this.list_designer_team$ = this.DesignerTeamStoreService.data$;
          break;
        case 'squad_id':
          this.list_squad$ = this.SquadGroupStoreService.data$;
          break;
        default:
          break;
      }

    }
  }
  submitSearchUser(value: string) {
    if (value.trim() !== '')
      this.action_search$.next(value);
  }
  onSearchUser(role?: number[], list_recent: any[] = [], isCheck = true) {
    return this.action_search$.pipe(
      tap(() => this.isLoading = true),
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(keyword => {
        if (keyword.trim() != '') {
          if (list_recent.length > 0) {
            const filter_list = list_recent.find(res => res.keyword == keyword.trim());
            if (filter_list) {
              return combineLatest(of('recent'), of(keyword), of(filter_list.list_user as UserData[]));
            }
          }
          return combineLatest(of('new'), of(keyword), this.userSV.searchUserAlgolia(keyword, role!));
        } else {
          return combineLatest(of(''), of(''), new BehaviorSubject(null).pipe(
            switchMap(() => {
              if (isCheck)
                return this.authSV.getUserDetail(this.value);
              isCheck = false;
              return of(null);
            }),
            map(user => {
              if (user) {
                return [user]
              }
              return [];
            })
          ));
        }
      }),
      map(([type, keyword, list_user]) => {
        if (type == 'new') {
          list_user = list_user.map(item => {
            item.uid = item.objectID;
            return item;
          })
          list_recent = [...list_recent, ...[{ keyword: keyword, list_user: list_user }]];
        }
        this.isLoading = false;
        return list_user;
      }),
      share()
    )
  }
}
