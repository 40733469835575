

<ng-container *ngIf="{media:media$ | async} as vm;">
  <ng-container *ngIf="vm.media; else no_media">
    <penji-media-cover [image_url]="media_ref.parent.parent.id==='8i7FhXui3qU9Q2DWgIOY3t3oV3l1'?vm.media['avatar']:vm.media[media_size]" [clickable]="clickable" [image_auto_fit]="image_auto_fit"></penji-media-cover>
  </ng-container>
</ng-container>
<ng-template #no_media>
  <penji-media-cover [image_url]="media_size === 'avatar'?'./assets/images/no-avatar.png':'./assets/images/no-image.png'"></penji-media-cover>
</ng-template>

