<div penjiContentHeight (output_height)="getHeight($event)" class="absolute inset-0 py-3.5 px-5">
  <div class="flex justify-between items-center pb-2">
    <h6 class="text-primary-500">Watched Customer</h6>
  </div>
  <ng-container *ngIf="{list_customer:list_customer$ | async} as vm">
    <ng-container *ngIf="vm.list_customer && vm.list_customer.length > 0; else loading">
      <nz-table
      #table_list
      [nzBordered]="false"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzSize]="'middle'"
      [nzData]="vm.list_customer"
      class="theme theme-default"
      [nzScroll]="{x: '36.146vw', y: container_height - 120+ 'px'}"
    >
      <thead>
        <tr>
          <th nzLeft nzWidth="240px">Customer Name</th>
          <th nzWidth="120px">Membership</th>
          <th nzWidth="200px">Latest 7 days feedback</th>
          <th nzWidth="110px">Duration</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of table_list.data; trackBy:trackBy">
          <tr inViewport (inViewportAction)="viewPort($event, item.id!)">
            <ng-container *ngIf="show_view_port[item.id!]; else loading_tr">
              <td nzLeft class="relative">
                <penji-user-item
                    [user_id]="item?.id"
                    [user_avatar]="true"
                    [user_name]="true"
                  ></penji-user-item>
                  <button
                  (click)="openDrawer(item)"
                  nz-button
                  nzType="default"
                  nzSize="small"
                  nz-tooltip
                  nzTooltipTitle="Open in side peek"
                  nzTooltipPlacement="bottom"
                  nzTooltipOverlayClassName="tooltip-simple"
                  class="hidden show-on-hover items-center justify-center absolute right-2 top-1/2 -translate-y-1/2"
                >
                  <svg-icon
                    key="layout-sidebar-inset-reverse"
                    fontSize="18px"
                  ></svg-icon>
                </button>
              </td>
              <td>
                <penji-customer-membership-status
                  [membership_status]="
                    item?.payment? item?.payment?.plan!.includes('pro')
                        ? 'pro'
                        : item?.payment?.plan!.includes('team')
                        ? 'team'
                        : 'agency'
                      : 'invited'
                  "
                ></penji-customer-membership-status>
                
              </td>
              <td>
                <penji-last-seven-days-feedback
                  [client_team_id]="item.team_active!"
                ></penji-last-seven-days-feedback>
              </td>
              <td>
                <span>{{ item?.created_at?.toDate() | amTimeAgo }}</span>
              </td>
            </ng-container>
          </tr>
        </ng-container>
        <ng-template #loading_tr>
          <td [colSpan]="4">
            <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzParagraph]="{ rows: 1, width: '100%' }"></nz-skeleton>
          </td>
        </ng-template>
      </tbody>
      </nz-table>
    </ng-container>
    <ng-template #loading>
      <nz-spin nzTip="Loading..." nzSize="large" class="absolute inset-0"></nz-spin>
    </ng-template>
  </ng-container>
</div>


 <!-- {{list_customer$ | async}} -->
