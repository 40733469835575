import { DocumentData, DocumentReference, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { EntityState } from '@ngrx/entity';

export class UserData {
  id?:string;
  uid?: string;
  objectID?: string; // algolia
  doc?: QueryDocumentSnapshot<DocumentData>;
  base?: number;
  created_at?: Timestamp;
  complete_profile?: boolean;
  complete_percent?: number;
  role?: number;
  team_active?: string;
  team_ref?: DocumentReference;
  search_terms?: string[];
  user_info: {
    email?: string;
    avatar?: DocumentReference | string;
    first_name?: string;
    last_name?: string;
    full_name?: string;
    key_search?: [];
    address?: string;
    city?: string;
    state?: string;
    phone?: string;
    zipcode?: string;
    company_name?: string;
    country?: string;
    time_zone?: string;
  } = {};
  payment: {
    coupon?: string,
    status?:string,
    plan?:string,
    one_off_design?:boolean
  } = {};
  setting_toggles: {
    member?: boolean;
    new_design?: boolean;
    newsletter?: boolean;
    penji_soft?: boolean;
    social?: boolean;
    team_member?: boolean;
  } = {}
  questionnaire: {
    find_us?: string;
    find_us_more?: string;
    industries_sector?: string;
    keyword_use?: string;
    number_of_employees?: string;
    who_using_penji?: string;
    how_many_designs?: number;
    website?: string;
    position_company?: string;
    company_do?: string;
    experience?: string;
    what_choose?: string;
  } = {};
  theme?: string;
  login_question?: boolean;
  slack_access_token?: string;
  team_id?: string;
  team_designer_active?: string;
  squad_id?:string;
  client_team_follow?: any;
  designer_follow?: any;
  project_follow?: any;
  permission?: {[key: string]: boolean};
  permission_multiple_team?: any[];
  is_pm?: boolean;
  permission_all_team?: boolean;
  chat_support?: boolean;
  owner?: boolean;
  ticket_feedback_count?: any[];
  notification_new?: object;
  enable_pto?: boolean;
  pto_earn?: number;
  pto_used?: number;
  schedule?: object;
  auto_assign?: boolean;
  auto_verify?: boolean;
  out_put_design?: number;
  total_active_project?:number;
  disabled?: boolean;
  deleted?: boolean;
  the_best?: string[];
  the_worst?: string[];
  suggest_search?: any[];
  pm_uid?: string;
  customer_following?: string[];
  last_url_routing?: string;
  last_url_routing_tabs?: any;
  log_type = 'user';
  static parseObject(data: any) {
    const transform: any = {};
    for (const key in data) {
      const value = data[key];
      if (typeof data[key] === 'object') {
        for (const keyDeep in data[key]) {
          if (key === 'user_info') {
            transform[`user_info.${keyDeep}`] = data[key][keyDeep];
          } else if (key === 'setting_toggles') {
            transform[`setting_toggles.${keyDeep}`] = data[key][keyDeep];
          } else if (key === 'questionnaire') {
            transform[`questionnaire.${keyDeep}`] = data[key][keyDeep];
          } else{
            transform[key] = value;
          }
        }
      } else {
        transform[key] = value;
      }
    }
    return transform;
  }
}

export class UserSimple {
  id = '';
  uid = '';
  email = '';
  avatar: DocumentReference | string = '';
  first_name = '';
  last_name = '';
  full_name = '';
  role = 4;
  client_team_id = '';
  checkin_status = 0; // 0: N/A, 1: PTO, 2: UPTO, 3: Checkin, 4: Checkin late, 5: Checkout
  checkin_at?: Timestamp;
  checkout_at?: Timestamp;
}
export type UserState = EntityState<UserSimple>
