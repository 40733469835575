import { SearchComponent } from '@penji/team/shared/shared-ui/search';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Store, select } from '@ngrx/store';
import { Category, Project, UserData } from '@penji/shared/data-access';
import { list_delivery } from '@penji/shared/environments';
import { SafeHtmlPipe } from '@penji/shared/pipes';
import { ModalOutputStringComponent } from '@penji/shared/ui/element/modal-output-string';
import { AuthStore } from '@penji/shared/auth/data-access';
import { CategoryClassDropdownComponent } from '@penji/team/categories/category-ui/category-class-dropdown';
import { getCategory } from '@penji/team/categories/data-access';
import * as firebase from 'firebase/firestore';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { QuillModule } from 'ngx-quill';
import Quill from 'quill';
import { Observable, map, take, tap } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ProjectStoreService } from '@penji/team/project/data-access';

const Link = Quill.import('formats/link');
class CustomLink extends Link {
  static PROTOCOL_WHITELIST: any;
  static sanitize(url:any) {
    const value = super.sanitize(url);
    if (value) {
      for (let i = 0; i < this.PROTOCOL_WHITELIST.length; i++)
        if(value.startsWith(this.PROTOCOL_WHITELIST[i]))
          return value;

        return `http://${value}`
    }
    return value;
  }
}
Quill.register(CustomLink);

@Component({
  selector: 'penji-project-create-update',
  standalone: true,
  imports: [
    CommonModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    SvgIconComponent,
    ReactiveFormsModule,
    FormsModule,
    CategoryClassDropdownComponent,
    NzRadioModule,
    SafeHtmlPipe,
    NzIconModule,
    QuillModule,
    NzAlertModule,
    NzCheckboxModule,
    NzGridModule,
    SearchComponent,
    UserItemComponent,
  ],
  templateUrl: './project-create-update.component.html',
  styleUrls: ['./project-create-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectCreateUpdateComponent {

  @Input() form_type?: string = 'add';
  @Input() project_data?: Project;

  afs = inject(AngularFirestore);
  store = inject(Store);
  fb = inject(FormBuilder);
  ref = inject(NzModalRef);
  modal = inject(NzModalService);
  cdr = inject(ChangeDetectorRef);
  projectStoreSv = inject(ProjectStoreService);

  authProfile$: Observable<UserData | null>;
  validateForm!: UntypedFormGroup;
  selectedCate$!: Observable<Category>;

  custom_page: any;
  selected_custom_page!: any;
  selected_length!: any;
  custom_dimension: any;
  selected_custom_dimension!: any;
  custom_dimension_mobile: any;
  selected_custom_dimension_mobile!: any;

  selected_delivery!: string;
  list_delivery = list_delivery;

  constructor(private readonly authStore: AuthStore) {
    this.authProfile$ = this.authStore.authProfile$;
  }

  ngOnInit() {
    this.validateForm = this.fb.group({
      // system required
      team_id: [this.project_data ? this.project_data.team_id : null, [Validators.required]],
      owner_id: [this.project_data ? this.project_data.owner_id : null, [Validators.required]],
      updated_at: [firebase.Timestamp.now(), [Validators.required]],
      status: [this.project_data ? this.project_data.status : 1, [Validators.required]],
      status_addition: [this.project_data ? this.project_data.status_addition : 0, [Validators.required]],
      in_queue: [this.project_data ? this.project_data.in_queue : false, [Validators.required]],
      version: [4, [Validators.required]],
      circle_type: [this.project_data ? this.project_data.circle_type : 1, [Validators.required]],
      pause: [this.project_data ? this.project_data.pause : false, [Validators.required]],
      pending_review: [this.project_data ? this.project_data.pending_review : false, [Validators.required]],
      assigned: [this.project_data ? this.project_data.assigned : false, [Validators.required]],
      // project required
      title: [this.project_data ? this.project_data.title : null, [Validators.required]],
      project_cat_id: [this.project_data ? this.project_data.project_cat_id : null, [Validators.required]],
      custom_page: [null],
      length: [null],
      dimensions: [null],
      dimensions_mobile: [null],
      example_web_link: [this.project_data?.example_web_link ? this.project_data?.example_web_link : null],
      example_app_link: [this.project_data?.example_app_link ? this.project_data?.example_app_link : null],
      presentation_number: [this.project_data?.presentation_number ? this.project_data?.presentation_number : null],
      description: [this.project_data ? this.project_data.description : null, [Validators.required]],
      required_file: [this.project_data ? this.project_data.required_file : ['any'], [Validators.required]],
    });

    if (this.form_type == 'add') {
      this.selected_delivery = 'any';
    } else if  (this.form_type == 'edit') {
      this.selected_delivery = this.project_data?.required_file ? this.project_data?.required_file[0] : 'any';
      // check current category
      if (this.project_data?.project_cat_id) {
        this.selectedCate$ = this.store.pipe(select(getCategory(this.project_data.project_cat_id))).pipe(
          tap(rs => {
            this.validateForm.patchValue({
              project_cat_id: rs.id,
            });
          }),
          map(rs => {
            if (rs.pages) {
              const tmp = [...rs.pages];
              tmp.forEach((p, index) => tmp[index] = {
                ...Object.keys(p).sort().reduce((r: any, k) => (r[k] = p[k], r), {})
              });
              rs.pages = tmp;

              // temp variable
              if (this.project_data?.custom_page && this.project_data?.custom_page.length > 0) {
                this.selected_custom_page = rs.pages.filter((dm: any) => dm.name == this.project_data?.custom_page[0].name)[0];
                if (!this.selected_custom_page) {
                  this.addCustomPage(this.project_data?.custom_page[0].name);
                }
              }
            }

            if (rs.dimensions) {
              const tmp = [...rs.dimensions];
              tmp.forEach((p, index) => tmp[index] = {
                ...Object.keys(p).sort().reduce((r: any, k) => (r[k] = p[k], r), {})
              });
              rs.dimensions = tmp;

              // temp variable
              if (rs.dimension_multiple) {
                if (this.project_data?.dimensions && this.project_data?.dimensions.length > 0) {
                  this.project_data?.dimensions.forEach((aaa: any) => {
                    const index = rs.dimensions.findIndex((dm: any) => dm.name == aaa.name);
                    if (index >= 0) {
                      if (this.selected_custom_dimension) {
                        this.selected_custom_dimension.push(rs.dimensions[index]);
                      } else {
                        this.selected_custom_dimension = [rs.dimensions[index]];
                      }
                    } else {
                      this.addCustomDimension(false, true, aaa.name, aaa.size);
                    }
                  });
                }
              } else {
                if (this.project_data?.dimensions && this.project_data?.dimensions.length > 0) {
                  this.selected_custom_dimension = rs.dimensions.filter((dm: any) => dm.name == this.project_data?.dimensions[0].name)[0];
                  if (!this.selected_custom_dimension) {
                    this.addCustomDimension(false, false, this.project_data?.dimensions[0].name, this.project_data?.dimensions[0].size);
                  }
                }
              }
            }

            if (rs.dimensions_mobile) {
              const tmp = [...rs.dimensions_mobile];
              tmp.forEach((p, index) => tmp[index] = {
                ...Object.keys(p).sort().reduce((r: any, k) => (r[k] = p[k], r), {})
              });
              rs.dimensions_mobile = tmp;

              // temp variable
              if (rs.dimension_multiple) {
                if (this.project_data?.dimensions_mobile && this.project_data?.dimensions_mobile.length > 0) {
                  this.project_data?.dimensions_mobile.forEach((aaa: any) => {
                    const index = rs.dimensions_mobile.findIndex((dm: any) => dm.name == aaa.name);
                    if (index >= 0) {
                      if (this.selected_custom_dimension_mobile) {
                        this.selected_custom_dimension_mobile.push(rs.dimensions_mobile[index]);
                      } else {
                        this.selected_custom_dimension_mobile = [rs.dimensions_mobile[index]];
                      }
                    } else {
                      this.addCustomDimension(false, true, aaa.name, aaa.size);
                    }
                  });
                }
              } else {
                if (this.project_data?.dimensions_mobile && this.project_data?.dimensions_mobile.length > 0) {
                  this.selected_custom_dimension_mobile = rs.dimensions_mobile.filter((dm: any) => dm.name == this.project_data?.dimensions_mobile[0].name)[0];
                  if (!this.selected_custom_dimension_mobile) {
                    this.addCustomDimension(false, false, this.project_data?.dimensions_mobile[0].name, this.project_data?.dimensions_mobile[0].size);
                  }
                }
              }
            }

            if (rs.length) {
              const tmp = [...rs.length];
              tmp.forEach((p, index) => tmp[index] = {
                ...Object.keys(p).sort().reduce((r: any, k) => (r[k] = p[k], r), {})
              });
              rs.length = tmp;

              if (this.project_data?.length && this.project_data?.length.length > 0) {
                this.selected_length = rs.length.filter((dm: any) => dm.name == this.project_data?.length[0].name)[0];
              }
            }
            return rs;
          })
        )
      }
    }
  }

  async selectClient(client_id: string) {
    const user = await this.afs.firestore.doc(`user/${client_id}`).get().then(rs => rs.data() as UserData).catch(() => null);
    if (user) {
      this.validateForm.patchValue({
        owner_id: client_id,
        team_id: user.team_active
      });
      this.cdr.detectChanges();
    }
  }

  selectCate(project_cat_id: string) {
    this.selectedCate$ = this.store.pipe(select(getCategory(project_cat_id)));
    this.validateForm.patchValue({
      project_cat_id: project_cat_id,
    })
  }

  async openCustomPage() {
    const ref: NzModalRef = this.modal.create({
      nzContent: ModalOutputStringComponent,
      nzBodyStyle: { padding: '10px', overflow: 'auto', background: '#fff' },
      nzComponentParams: {
        size: false
      },
      nzTitle: 'Type/name a page',
      nzWidth: '400px',
      nzStyle: { top: '14px' },
      nzFooter: null,
    });
    const output_string = await ref.afterClose.pipe(take(1)).toPromise();
    if (output_string) {
      this.addCustomPage(output_string);
      this.cdr.detectChanges();
    }
  }

  addCustomPage(output_string: string) {
    this.custom_page = {
      name: output_string,
      images: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 99.7 121.95"
              enable-background="new 0 0 99.7 121.95" xml:space="preserve">
              <g>
                <g>
                  <path fill="#FFFFFF" stroke="#CFD4DD" stroke-width="2.2" stroke-miterlimit="10" d="M98.6,39.1v76.75c0,2.762-2.238,5-5,5H6.1
                          c-2.761,0-5-2.238-5-5V6.1c0-2.762,2.239-5,5-5h59.666L98.6,39.1z" />
                  <path fill="none" stroke="#CFD4DD" stroke-width="2.2" stroke-miterlimit="10"
                    d="M97.767,39.1h-28c-2.209,0-4-1.791-4-4v-34" />
                </g>
                <g>
                  <g>
                    <path fill="#C3C8CF" d="M66.34,83.884H55.827l-0.235-0.236L30.144,58.202l0.038-0.38c0.594-5.913,3.752-9.486,9.387-10.622
                            l0.422-0.085l26.35,26.348V83.884z M56.495,82.273h8.234v-8.144l-25.261-25.26c-4.544,1.05-7.048,3.914-7.642,8.737
                            L56.495,82.273z" />
                  </g>
                  <g>
                    <g>
                      <polygon fill="#FFFFFF"
                        points="58.022,47.989 30.497,75.515 40.229,85.249 67.818,57.66 				" />
                    </g>
                    <g>
                      <path fill="#C3C8CF" d="M40.229,86.388L29.356,75.515l28.662-28.662l10.943,10.803L40.229,86.388z M31.635,75.515l8.594,8.594
                              l26.445-26.445l-8.648-8.539L31.635,75.515z" />
                    </g>
                  </g>
                  <g>

                    <rect x="38.573" y="76.591" transform="matrix(0.707 0.7072 -0.7072 0.707 67.1936 -7.3876)"
                      fill="#C3C8CF" width="7.879" height="1.61" />
                  </g>
                  <g>

                    <rect x="56.213" y="58.952" transform="matrix(0.707 0.7072 -0.7072 0.707 59.88 -25.0314)"
                      fill="#C3C8CF" width="7.878" height="1.61" />
                  </g>
                  <g>
                    <path fill="#C3C8CF" d="M56.956,83.205l-1.59-0.252c0.013-0.082,1.393-8.17,10.007-9.945l0.325,1.578
                            C58.163,76.138,56.967,83.134,56.956,83.205z" />
                  </g>
                </g>
              </g>
            </svg>`
    };
    this.selected_custom_page = this.custom_page;
  }

  async openCustomDimension(mobile: boolean, dimension_multiple: boolean) {
    const ref: NzModalRef = this.modal.create({
      nzContent: ModalOutputStringComponent,
      nzBodyStyle: { padding: '10px', overflow: 'auto', background: '#fff' },
      nzComponentParams: {
        size: true
      },
      nzTitle: 'Type/name a dimension',
      nzWidth: '400px',
      nzStyle: { top: '14px' },
      nzFooter: null,
    });
    const output = await ref.afterClose.pipe(take(1)).toPromise();
    if (output) {
      this.addCustomDimension(mobile, dimension_multiple, output.name, output.size);
      this.cdr.detectChanges();
    }
  }

  addCustomDimension(mobile: boolean, dimension_multiple: boolean, name: string, size: string) {
    const temp = {
      name: name,
      size: size,
      images: `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 99.7 121.95"
      enable-background="new 0 0 99.7 121.95" xml:space="preserve">
      <g>
        <g>
          <path fill="#FFFFFF" stroke="#CFD4DD" stroke-width="2.2" stroke-miterlimit="10" d="M98.6,39.1v76.75c0,2.762-2.238,5-5,5H6.1
                  c-2.761,0-5-2.238-5-5V6.1c0-2.762,2.239-5,5-5h59.666L98.6,39.1z" />
          <path fill="none" stroke="#CFD4DD" stroke-width="2.2" stroke-miterlimit="10"
            d="M97.767,39.1h-28c-2.209,0-4-1.791-4-4v-34" />
        </g>
        <g>
          <g>
            <path fill="#C3C8CF" d="M66.34,83.884H55.827l-0.235-0.236L30.144,58.202l0.038-0.38c0.594-5.913,3.752-9.486,9.387-10.622
                    l0.422-0.085l26.35,26.348V83.884z M56.495,82.273h8.234v-8.144l-25.261-25.26c-4.544,1.05-7.048,3.914-7.642,8.737
                    L56.495,82.273z" />
          </g>
          <g>
            <g>
              <polygon fill="#FFFFFF"
                points="58.022,47.989 30.497,75.515 40.229,85.249 67.818,57.66 				" />
            </g>
            <g>
              <path fill="#C3C8CF" d="M40.229,86.388L29.356,75.515l28.662-28.662l10.943,10.803L40.229,86.388z M31.635,75.515l8.594,8.594
                      l26.445-26.445l-8.648-8.539L31.635,75.515z" />
            </g>
          </g>
          <g>

            <rect x="38.573" y="76.591" transform="matrix(0.707 0.7072 -0.7072 0.707 67.1936 -7.3876)"
              fill="#C3C8CF" width="7.879" height="1.61" />
          </g>
          <g>

            <rect x="56.213" y="58.952" transform="matrix(0.707 0.7072 -0.7072 0.707 59.88 -25.0314)"
              fill="#C3C8CF" width="7.878" height="1.61" />
          </g>
          <g>
            <path fill="#C3C8CF" d="M56.956,83.205l-1.59-0.252c0.013-0.082,1.393-8.17,10.007-9.945l0.325,1.578
                    C58.163,76.138,56.967,83.134,56.956,83.205z" />
          </g>
        </g>
      </g>
    </svg>`
    };
    if (mobile) {
      if (dimension_multiple) {
        if (this.custom_dimension_mobile) {
          this.custom_dimension_mobile.push(temp);
        } else {
          this.custom_dimension_mobile = [temp];
        }
        if (this.selected_custom_dimension_mobile) {
          this.selected_custom_dimension_mobile.push(temp);
        } else {
          this.selected_custom_dimension_mobile = [temp];
        }
      } else {
        this.custom_dimension_mobile = {...temp};
        this.selected_custom_dimension_mobile = this.custom_dimension_mobile;
      }
    } else {
      if (dimension_multiple) {
        if (this.custom_dimension) {
          this.custom_dimension.push(temp);
        } else {
          this.custom_dimension = [temp];
        }
        if (this.selected_custom_dimension) {
          this.selected_custom_dimension.push(temp);
        } else {
          this.selected_custom_dimension = [temp];
        }
      } else {
        this.custom_dimension = {...temp};
        this.selected_custom_dimension = this.custom_dimension;
      }
    }

  }

  changeCustomDimension(mobile: boolean, value: any) {
    if (mobile) {
      this.selected_custom_dimension_mobile = [...value];
    } else {
      this.selected_custom_dimension = [...value];
    }
  }

  onSubmit() {
    this.validateForm.patchValue({
      custom_page: this.selected_custom_page ? [this.selected_custom_page] : null,
      length: this.selected_length ? [this.selected_length] : null,
      dimensions: this.selected_custom_dimension ? [...this.selected_custom_dimension] : null,
      dimensions_mobile: this.selected_custom_dimension_mobile ? [...this.selected_custom_dimension_mobile] : null,
      required_file: this.selected_delivery ? [this.selected_delivery] : ['any']
    });

    if (this.form_type == 'add') {
      this.projectStoreSv.createProject$(this.validateForm.value);
    } else if  (this.form_type == 'edit') {
      const temp = { ...this.project_data, ...this.validateForm.value };
      this.projectStoreSv.updateProject$(temp);
    }
  }
}
