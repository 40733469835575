/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { AuthStore } from '@penji/shared/auth/data-access';
import { CustomerService, DrawerService, WatchListService } from '@penji/shared/data-access';
import { ContentHeightDirective } from '@penji/shared/directives';
import { CustomerMembershipStatusComponent } from '@penji/team/customer/customer-ui/customer-membership-status';
import { LastSevenDaysFeedbackComponent } from '@penji/team/customer/customer-ui/last-seven-days-feedback';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { InViewportAction, InViewportDirective } from 'ng-in-viewport';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { MomentModule } from 'ngx-moment';
import { combineLatest, map, of, tap, switchMap } from 'rxjs';

@Component({
  selector: 'penji-w-watched-customer',
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    UserItemComponent,
    SvgIconComponent,
    NzButtonModule,
    LastSevenDaysFeedbackComponent,
    CustomerMembershipStatusComponent,
    MomentModule,
    ContentHeightDirective,
    InViewportDirective,
    NzSkeletonModule,
    NzSpinModule
  ],
  templateUrl: './w-watched-customer.component.html',
  styleUrls: ['./w-watched-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WWatchedCustomerComponent {

  drawerSv = inject(DrawerService);
  afs = inject(AngularFirestore);
  watchListSV = inject(WatchListService);
  authStore = inject(AuthStore);
  customerSV = inject(CustomerService);
  auth_id$ = this.authStore.auth_id$;
  authProfile$ = this.authStore.authProfile$;
  container_height = 0;
  show_view_port: any = {};

  list_customer$ = this.authProfile$.pipe(
    switchMap((authProfile) => {
      if (authProfile && authProfile.customer_following && authProfile.customer_following.length > 0) {
        console.log(authProfile.customer_following);
        return combineLatest(this.customerSV.getListCustomerByListIds(authProfile?.customer_following), this.customerSV.getListCustomerPaymentByListIds(authProfile?.customer_following));
      } else {
        return combineLatest(of([]), of([]));
      }
    }),
    map(([users, payments])=>{
      if(users)
        return users.map(user=>{
          return { ...user, payment: payments.find(p=> p.id === user.id)};
        })
      return [];
    })
  );

  openDrawer(data: any) {
    const temp = {
      type: 'customer',
      data: data
    }
    console.log(temp);

    this.drawerSv.openDrawer(temp);
  }

  getHeight(height: any) {
    this.container_height = height;
  }
  viewPort(event: InViewportAction, id: string): void {
    if (event.visible) {
      this.show_view_port[id] = true;
    }
  }
  trackBy(index: any, item: any) {
    return item.id;
  }
}
