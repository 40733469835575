import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'penji-customer-membership-status',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
  ],
  templateUrl: './customer-membership-status.component.html',
  styleUrls: ['./customer-membership-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerMembershipStatusComponent {

  @Input() membership_status: string | undefined = '';
}
