<div class="flex items-center relative" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
  <penji-input container_class_name="" input_class_name="h-10 pr-5 pl-9" [control]="search_control" icon="search"
    icon_size="20px" placeholder="Search" [enable_clear_button]="true" [auto_focus]="auto_focus" class="w-full">
  </penji-input>

  <div *ngIf="isLoading" class="rounded-full bg-primary-100 inline-flex p-1 items-center ml-2 absolute right-1 top-1/2 -translate-y-1/2 z-30">
    <penji-spinner font_size="25px" color="text-primary-500"></penji-spinner>
  </div>
</div>
<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="max-h-96 overflow-auto !py-0">
    <ng-container *ngIf="list$ | async as vm">
      <ng-container *ngIf="vm.list_user && vm.list_user.length > 0">
        <li nz-menu-item class="cursor-default uppercase font-bold text-primary-500 !bg-transparent py-2">
          Users:
        </li>
        <ng-container *ngFor="let user of vm.list_user">
          <ng-container *ngIf="!select">
            <ng-container [ngSwitch]="user.role">
              <ng-container *ngSwitchCase="'3'">
                <li nz-menu-item class="px-5 py-2.5 capitalize space-x-1.5" (click)="onAddKeyword('customer',user?.user_info?.first_name + ' ' +user?.user_info?.last_name, user?.objectID)">
                  <span>
                    {{user?.user_info?.first_name}} {{user?.user_info?.last_name}}
                  </span>
                  <span>-</span>
                  <span class="text-primary-500"><penji-user-role [role]="user?.role"></penji-user-role></span>
                </li>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <li nz-menu-item class="px-5 py-2.5 capitalize space-x-1.5" (click)="onAddKeyword('member',user?.user_info?.first_name + ' ' +user?.user_info?.last_name, user?.objectID)">
                  <span>
                    {{user?.user_info?.first_name}} {{user?.user_info?.last_name}}
                  </span>
                  <span>-</span>
                  <span class="text-pink-500"><penji-user-role [role]="user?.role"></penji-user-role></span>
                </li>
              </ng-container>
            </ng-container>
          </ng-container>
          <li nz-menu-item *ngIf="select" class="px-5 py-2.5 capitalize space-x-1.5" (click)="selectUser(user?.objectID)">
            <span>
              {{user?.user_info?.first_name}} {{user?.user_info?.last_name}}
            </span>
            <span>-</span>
            <span class="text-pink-500"><penji-user-role [role]="user?.role"></penji-user-role></span>
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="vm.list_project && vm.list_project.length > 0">
        <li nz-menu-item class="cursor-default uppercase font-bold text-primary-500 !bg-transparent py-2">Projects:</li>
        <li nz-menu-item *ngFor="let project of vm.list_project" class="px-5 py-2.5 capitalize space-x-1.5" (click)="onAddKeyword('project', project.title, '', project?.id)">
          <span [innerHTML]="project?.title"></span>
        </li>
      </ng-container>
      <ng-container *ngIf="vm.list_ticket && vm.list_ticket.length > 0">
        <li nz-menu-item class="cursor-default uppercase font-bold text-primary-500 !bg-transparent py-2">Tickets:</li>
        <li nz-menu-item *ngFor="let ticket of vm.list_ticket" class="px-5 py-2.5 capitalize space-x-1.5" (click)="onAddKeyword('ticket', ticket.index_number, '', '', ticket?.id)">
          <b>
            {{ticket?.index_number?('00000' + ticket?.index_number).slice(-6):'Loading..'}}
          </b>
          <span>-</span>
          <penji-ticket-cate-item [ticket_cate_id]="ticket.ticket_sub_category_id"></penji-ticket-cate-item>
        </li>
      </ng-container>
      <!-- No data -->
      <ng-container
        *ngIf="!isLoading && search_control.value !== '' && vm.list_user.length === 0 && vm.list_project.length === 0 && vm.list_ticket.length === 0">
        <nz-empty class="height-empty px-4 py-5" nzNotFoundImage="simple"></nz-empty>
      </ng-container>
    </ng-container>
    <!-- loading -->
    <ng-container *ngIf="isLoading">
      <div class="px-5 py-2">
        <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzParagraph]="{ rows: 6, width: '100%' }"></nz-skeleton>
      </div>
    </ng-container>
    <!-- Suggestion -->
    <ng-container *ngIf="suggestion">
      <ng-container *ngIf="suggest_search$ | async as suggest_search">
        <li nz-menu-item class="cursor-default uppercase font-bold text-primary-500 !bg-transparent py-2">Recent searches:</li>
        <ng-container *ngFor="let item of suggest_search">
          <li nz-menu-item class="px-5 py-2.5 capitalize space-x-1.5" (click)="clickSuggest(item)">
            <span [innerHTML]="item.title"></span>
            <span>-</span>
            <span [class]="item?.type === 'member' ? 'text-pink-500' : item?.type === 'customer' ? 'text-primary-500' : item?.type === 'project' ? 'text-blue-500': ''">
              {{item.type}}
            </span>
          </li>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>
</nz-dropdown-menu>
