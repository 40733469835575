import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from '@penji/shared/ui/element/input';
import { RouterModule } from '@angular/router';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ConvertTimezonePipe } from '@penji/shared/pipes';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { RequestOff, RequestOffService, WhereQueryInterface } from '@penji/shared/data-access';
import { AuthStore } from '@penji/shared/auth/data-access';
import { BehaviorSubject, EMPTY, Observable, from, groupBy, map, mergeMap, of, switchMap, tap, toArray, zip } from 'rxjs';
import * as firebase from 'firebase/firestore';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@Component({
  selector: 'penji-w-request-off-calendar',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    RouterModule,
    NzAlertModule,
    NzIconModule,
    ConvertTimezonePipe,
    SvgIconComponent,
    UserItemComponent,
    NzCalendarModule,
    NzSpinModule
  ],
  templateUrl: './w-request-off-calendar.component.html',
  styleUrls: ['./w-request-off-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WRequestOffCalendarComponent {

  limit = -1;
  requestOffSV = inject(RequestOffService);
  authStore = inject(AuthStore);

  now: Date = new Date();
  request_off_calendar$!: Observable<any>;
  authProfile$ = this.authStore.authProfile$;
  calendar$ = new BehaviorSubject<any>({ approved: 1, cal: { mode: 'month', date: new Date() } });

  constructor() {
    this.request_off_calendar$ = this.calendar$.pipe(
      switchMap((action: any) => {
        const where_query_calendar: Array<WhereQueryInterface> = [];
        if (action) {
          for (const k in action) {
            if (k == 'cal') {
              if (action[k].mode == 'month') {
                where_query_calendar.push({
                  field_name: 'off_at',
                  field_operator: '>',
                  field_value: firebase.Timestamp.fromDate(new Date(action[k].date.getFullYear(), action[k].date.getMonth(), 1))
                });
                where_query_calendar.push({
                  field_name: 'off_at',
                  field_operator: '<=',
                  field_value: firebase.Timestamp.fromDate(new Date(action[k].date.getFullYear(), action[k].date.getMonth() + 1, 0))
                });
              } else {
                where_query_calendar.push({
                  field_name: 'off_at',
                  field_operator: '>',
                  field_value: firebase.Timestamp.fromDate(new Date(action[k].date.getFullYear(), 0, 1))
                });
                where_query_calendar.push({
                  field_name: 'off_at',
                  field_operator: '<=',
                  field_value: firebase.Timestamp.fromDate(new Date(action[k].date.getFullYear(), 11, 31))
                });
              }
            } else if (k == 'approved') {
              where_query_calendar.push({
                field_name: k,
                field_operator: '==',
                field_value: parseInt(action[k])
              });
            } else {
              where_query_calendar.push({
                field_name: k, field_operator: '==',
                field_value: action[k] == 'true' ? true : action[k] == 'false' ? false : action[k]
              });
            }
          }
        }
        return this.requestOffSV.listRequestOffRealTime(this.limit, where_query_calendar);
      })
    ).pipe(
      map(rs => {
        return this.groupBy(rs);
      })
    );
  }
  groupBy(xs: RequestOff[]) {
    return xs.reduce((rv:any, x:RequestOff) => {
      (rv[x.off_at.toDate().getDate() + "-" + x.off_at.toDate().getMonth()] = rv[x.off_at.toDate().getDate() + '-' + x.off_at.toDate().getMonth()] || []).push(x);
      (rv[x.off_at.toDate().getMonth() + "-" + x.off_at.toDate().getFullYear()] = rv[x.off_at.toDate().getMonth() + '-' + x.off_at.toDate().getFullYear()] || []).push(x);
      return rv;
    }, {});
  };
  dateChange(date: Date) {
    const obj: any = this.calendar$.getValue();
    obj.cal.date = date;
    this.calendar$.next(obj);
  }
  panelChange(event: { date: Date; mode: string }) {
    const obj: any = this.calendar$.getValue();
    obj.cal.date = event.date;
    obj.cal.mode = event.mode;
    this.calendar$.next(obj);
  }
}
