/* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NzMessageService } from 'ng-zorro-antd/message';
import { of, switchMap } from 'rxjs';
import { LogService } from './log.service';
import { environment } from '@penji/shared/environments';
import { UserData } from '../models/user.model';
import { Payment } from '../models/payment.model';
import { DocumentSnapshot } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class CustomerService extends LogService<UserData> {
  private api_url = environment.api_algolia;
  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);
  nzMessageService = inject(NzMessageService);
  getListCustomerAlgolia(filter_obj: any) {
    return this.afAuth.idToken.pipe(
      switchMap((res) => {
        if (res) {
          // console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`,
            }),
          };
          const params = { ...filter_obj };
          for (const key in params) {
            if (key === 'team_designer_id' || key === 'team_active') {
              params[key] = params[key].split(',');
            }
          }
          console.log(params);
          return this.http.post(`${this.api_url}/filter-customer`, params, httpOptions);
        } else {
          return of(null);
        }
      })
    );
  }
  async getListCustomerByListIds(ids: string[]){
    const promisses: any[] = [];
    ids.forEach(item=>{
      promisses.push(this.afs.firestore.doc(`user/${item}`).get());
    })
    if(promisses.length > 0){
      const result = await Promise.all(promisses);
      if(result.length > 0){
        return result.map((doc: DocumentSnapshot)=>{
          return { ...doc.data(), id : doc.id} as UserData;
        })
      }
    }
    return [];
  }
  async getListCustomerPaymentByListIds(ids: string[]){
    const promisses: any[] = [];
    ids.forEach(item=>{
      promisses.push(this.afs.firestore.doc(`payment/${item}`).get());
    })
    if(promisses.length > 0){
      const result = await Promise.all(promisses);
      if(result.length > 0){
        return result.map((doc: DocumentSnapshot)=>{
          const data = doc.data();
          if(data)
            return { ...data, id : doc.id} as Payment;
          return {} as Payment
        })
      }
    }
    return [];
  }
  updateCustomer(data: UserData) {
    console.log(UserData.parseObject(data));
    return this.afs
      .collection('user')
      .doc(data.id)
      .update(UserData.parseObject(data))
      .then(() => {
        this.log_model.action = 'update';
        this.log_model.data = { ...new UserData, ...data };
        this.createLog();
        this.nzMessageService.success('updated successfully!');
        return { flag: true, message: 'Your customer successfully updated!' };
      })
      .catch((err) => {
        console.log(err);
        this.nzMessageService.error(err.message);
        return { flag: false, message: err.message };
      });
  }
  getDetailPaymemtOfCustomer(customer_id: string) {
    return this.afs.firestore
      .collection('payment')
      .doc(customer_id)
      .get()
      .then((payment) => {
        // console.log(payment.data());
        const data = payment.data();
        if(data)
          return { ...payment.data(), id: customer_id } as Payment;
        return {} as Payment;
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  exportCustomerData(filter_params: any) {
    const obj_filter = { ...filter_params, "type": "customer" };
    return this.afAuth.idToken.pipe(
      switchMap((res) => {
        if (res) {
          console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              Authorization: `Bearer ${res}`,
            }),
          };
          return this.http.post(
            `http://localhost:3000/api/algolia/export-data`,
            obj_filter,
            httpOptions
          );
        } else {
          return of(null);
        }
      })
    );
  }
}
