import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { AuthService } from '@penji/shared/auth/data-access';
import { Overtime } from '@penji/shared/data-access';
import { HrManagementAssignedComponent } from '@penji/team/hr-management/hr-management-ui/hr-management-assigned';
import { OvertimeStoreService } from '@penji/team/overtime/data-access';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { UserSelectComponent } from '@penji/team/shared/shared-ui/user-select';
import { differenceInCalendarDays } from 'date-fns';
import * as firebase from 'firebase/firestore';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { take } from 'rxjs';

@Component({
  selector: 'penji-overtime-create-update',
  standalone: true,
  imports: [
    CommonModule,
    NzFormModule,
    NzInputModule,
    NzButtonModule,
    NzDatePickerModule,
    ReactiveFormsModule,
    NzSelectModule,
    SvgIconComponent,
    UserItemComponent,
    HrManagementAssignedComponent
  ],
  templateUrl: './overtime-create-update.component.html',
  styleUrls: ['./overtime-create-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OvertimeCreateUpdateComponent {
  @Input() form_type?: string = 'add';
  @Input() overtime?: Overtime;
  loading: boolean = false;
  fb = inject(FormBuilder);
  ref = inject(NzModalRef);
  modal = inject(NzModalService);
  authSV = inject(AuthService);

  validateForm!: UntypedFormGroup;
  _addLinkForm!: UntypedFormGroup;
  today: Date = new Date();
  disabled_overtime_at = (current: Date): boolean => differenceInCalendarDays(current, this.today) > 0;

  visible = false;
  constructor(
    private overtimeStore: OvertimeStoreService,
  ) {
    this.validateForm = this.fb.group({
      id: [""],
      created_at: [null],
      overtime_at: [null],
      overtime_at_tmp: [null, [Validators.required]],
      approved: [0],
      uid: ["", [Validators.required]],
      overtime_link: [[], [Validators.required]],
      why_unapproved: [""],
    });
    this._addLinkForm = this.fb.group({
      _link: ["", [Validators.required]]
    })
  }

  ngOnInit() {
    if (this.form_type == 'add') {
      this.validateForm.patchValue({
        created_at: firebase.Timestamp.now(),
        overtime_at_tmp: (new Date(firebase.Timestamp.now().seconds * 1000)),
        uid: this.overtime?.uid ? this.overtime?.uid : null
      });
    } else if (this.form_type == 'edit') {
      this.validateForm.patchValue({
        id: this.overtime?.id ? this.overtime?.id : "",
        created_at: this.overtime?.created_at ? this.overtime?.created_at : null,
        overtime_at_tmp: this.overtime?.overtime_at ? (new Date(this.overtime?.overtime_at.seconds * 1000)) : null,
        approved: this.overtime?.approved ? this.overtime?.approved : 0,
        uid: this.overtime?.uid ? this.overtime?.uid : "",
        overtime_link: this.overtime?.overtime_link ? this.overtime?.overtime_link : [],
        why_unapproved: this.overtime?.why_unapproved ? this.overtime?.why_unapproved : ""
      });
    }
  }

  addLink() {
    this.validateForm.patchValue({
      overtime_link: [
        ...this.validateForm.get('overtime_link')?.value
        , this._addLinkForm.get('_link')?.value
      ]
    });
    this._addLinkForm.reset();
  }

  private _validateLink(fieldControl: any) {
    const link = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;
    if (fieldControl.value && fieldControl.value.match(link)) {
      return null;
    } else {
      return { format: true }
    }
  }

  removeLink(link: string) {
    let links = [];
    links = this.validateForm.get('overtime_link')?.value;
    const index = links.indexOf(link);
    links.splice(index, 1);
    this.validateForm.patchValue({ overtime_link: links })
  }

  getListUserId(list_out_assign_by: string[]) {
    if (list_out_assign_by && list_out_assign_by.length > 0) {
      this.validateForm.patchValue({ uid: list_out_assign_by[0] });
    }
    this.visible = false;
  }

  onSubmit() {
    this.loading = true;
    this.validateForm.patchValue({
      overtime_at: this.validateForm.controls['overtime_at_tmp'].value ?
        firebase.Timestamp.fromDate(this.validateForm.controls['overtime_at_tmp'].value) : null
    });
    // remove Date format, just keep Timestamp format
    this.validateForm.removeControl('overtime_at_tmp');
    if (this.form_type == 'edit') {
      this.overtimeStore.updateOvertime$(this.validateForm.value);
      this.loading = false;
    } else {
      this.overtimeStore.addOvertime$(this.validateForm.value);
      this.loading = false;
    }
    this.ref.destroy();
  }
}

