

import { createAction, props } from "@ngrx/store";
import { UserSimple } from "../../models/user.model";

//Store
export const createUser = createAction('[User] Create User', props<{user: UserSimple}>());
//Database
export const loadUserDB = createAction('[User] Load User', props<{uid: string}>());

