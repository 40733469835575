/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { CustomerService, UserData } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, map, switchMap, tap } from 'rxjs';

interface CustomerState extends EntityState<UserData> {
    loading: boolean,
    error: string,
    current_user_data: UserData | null,
    nbHits: number,
    page: number,
    hitsPerPage: number
}
const adapter = createEntityAdapter<UserData>();
const initialState: CustomerState = adapter.getInitialState({
    loading: false,
    error: '',
    current_user_data: null,
    nbHits: 0,
    page: 0,
    hitsPerPage: 0
})

@Injectable()
export class CustomerStoreService extends ComponentStore<CustomerState> {

    private customerSV = inject(CustomerService);
    constructor() { super(initialState); }
    readonly data$ = this.select(s => Object.values(s.entities) as UserData[]);
    readonly loading$ = this.select(s => s.loading);
    readonly error$ = this.select((s) => s.error);
    private readonly nbHits$ = this.select((s) => s.nbHits);
    private readonly hitsPerPage$ = this.select((s) => s.hitsPerPage);
    private readonly page$ = this.select((s) => s.page);
    readonly current_user_data$ = this.select((s) => s.current_user_data);
    readonly vm$ = this.select(
        this.data$,
        this.nbHits$,
        this.hitsPerPage$,
        this.page$,
        (data, nbHits, hitsPerPage, page) => ({
            data,
            nbHits,
            hitsPerPage,
            page
        })
    );

    loadCustomers$ = this.effect((params$: Observable<any>) => {
        return params$.pipe(
            tap(() => {
                this.patchState({ loading: true, error: '' });
                this.setState(s => adapter.removeAll(s));
            }),
            switchMap((params: any) => {
                return this.customerSV.getListCustomerAlgolia(params).pipe(
                    map((result: any) => {
                        if (result.success) {
                            let data = result.data.hits as UserData[];
                            data = data.map(item=> {
                                item.id = item.objectID;
                                return item;
                            })
                            this.setState(s => adapter.setAll(data, s))
                            this.patchState({ nbHits: result.data.nbHits, hitsPerPage: result.data.hitsPerPage, page: result.data.page, loading: false, error: '' });
                        } else {
                            this.patchState({ loading: false, error: 'error get customers filter api' });
                        }
                    })
                )
            }),
            catchError(err => {
                this.patchState({ loading: false, error: err as string });
                throw new Error('cannot get api http!');
                return EMPTY;
            })
        )
    });
}
