/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ProjectService, WhereQueryInterface } from '@penji/shared/data-access';
import { ContentHeightDirective } from '@penji/shared/directives';
import { InputComponent } from '@penji/shared/ui/element/input';
import { DesignerTeamNameComponent } from '@penji/team/designer-team/designer-team-ui/designer-team-name';
import { ProjectStoreService } from '@penji/team/project/data-access';
import { ProjectWTableComponent } from '@penji/team/project/project-ui/project-w-table';
import { NavigationTabComponent } from '@penji/team/shared/shared-ui/navigation-tab';
import * as firebase from 'firebase/firestore';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { BehaviorSubject, map, of, switchMap } from 'rxjs';

type DataItem = any;
@Component({
  selector: 'penji-project-by-team-widget',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    NzTabsModule,
    NavigationTabComponent,
    ProjectWTableComponent,
    DesignerTeamNameComponent,
    ContentHeightDirective,
    NzSpinModule,
    RouterModule,
  ],
  templateUrl: './w-project-by-team.component.html',
  styleUrls: ['./w-project-by-team.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectStoreService],
})
export class WProjectByTeamComponent implements OnInit {
  @Input() designer_team_id!: string;
  projectSV = inject(ProjectService);
  store = inject(ProjectStoreService);
  class_table = 'theme-overdue';
  list_project$ = this.store.data$;
  loading$ = this.store.loading$;
  current_tab$ = new BehaviorSubject<string>('Overdue');
  container_height = 0;
  tabs = [
    {
      key: 'todo',
      name: 'Todo',
      items: [
        { key: 'overdue', name: 'Overdue' },
        { key: 'reviewed', name: 'Reviewed' },
        { key: 'unreviewed', name: 'Unreviewed' },
      ],
    },
    {
      key: 'to-verify',
      name: 'To Verify',
      items: [
        { key: 'verify', name: 'To Verify' },
        { key: 'schedule', name: 'Be Scheduled' },
      ],
    },
  ];
  filterDummy = [
    { text: 'Joe', value: 'Joe' },
    { text: 'John', value: 'John' }
  ];
  titleFilterFn = (list: string[], item: DataItem): boolean => list.some(title => item.title.indexOf(title) !== -1);
  ngOnInit(): void {
    if (this.designer_team_id) {
      this.store.loadProjectsForWidget$(this.current_tab$.pipe(
        switchMap(current_tab => {
          let where_query: WhereQueryInterface[] = [];
          let order_by;
          let order_desc;

          if (current_tab == 'Todo') {
            where_query = [
              {
                field_name: 'status',
                field_operator: 'in',
                field_value: [1, 2],
              },
              {
                field_name: 'in_queue',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'pending_review',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'pause',
                field_operator: '==',
                field_value: false,
              },
            ];

            order_by = 'time_change';
            order_desc = true;
            this.class_table = 'theme-default';
          }

          if (current_tab == 'To Verify') {
            where_query = [
              {
                field_name: 'in_queue',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'pending_review',
                field_operator: '==',
                field_value: true,
              },
              {
                field_name: 'verify_progress',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'pause',
                field_operator: '==',
                field_value: false,
              },
            ];

            order_by = 'time_change';
            order_desc = true;
            this.class_table = 'theme-verify';
          }

          if (current_tab == 'Be Scheduled') {
            where_query = [
              {
                field_name: 'in_queue',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'pending_review',
                field_operator: '==',
                field_value: true,
              },
              {
                field_name: 'verify_progress',
                field_operator: '==',
                field_value: true,
              },
              {
                field_name: 'pause',
                field_operator: '==',
                field_value: false,
              },
            ];
            order_by = 'time_change';
            order_desc = true;
            this.class_table = 'theme-schedule';
          }

          if (current_tab == 'Overdue') {
            where_query = [
              {
                field_name: 'status',
                field_operator: 'in',
                field_value: [1, 2],
              },
              {
                field_name: 'in_queue',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'pending_review',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'pause',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'time_change',
                field_operator: '<=',
                field_value: firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 1))),
              },
            ];

            order_by = 'time_change';
            order_desc = true;
            this.class_table = 'theme-overdue';
          }

          if (current_tab == 'Reviewed') {
            where_query = [
              {
                field_name: 'status',
                field_operator: 'in',
                field_value: [1, 2],
              },
              {
                field_name: 'in_queue',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'pending_review',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'pause',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'circle_type',
                field_operator: '==',
                field_value: 2,
              },
              {
                field_name: 'time_change',
                field_operator: '>',
                field_value: firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 1))),
              },
            ];

            order_by = 'time_change';
            order_desc = true;
            this.class_table = 'theme-reviewed';
          }

          if (current_tab == 'Unreviewed') {
            where_query = [
              {
                field_name: 'status',
                field_operator: 'in',
                field_value: [1, 2],
              },
              {
                field_name: 'in_queue',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'pending_review',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'pause',
                field_operator: '==',
                field_value: false,
              },
              {
                field_name: 'circle_type',
                field_operator: '==',
                field_value: 1,
              },
              {
                field_name: 'time_change',
                field_operator: '>',
                field_value: firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 1))),
              },
            ];

            order_by = 'time_change';
            order_desc = true;
            this.class_table = 'theme-unreviewed';
          }

          where_query.push({
            field_name: 'assign_team_designer',
            field_operator: '==',
            field_value: this.designer_team_id,
          });
          return of({ where_query, order_by, order_desc });
        })
      ));
    }
  }
  switchTab(value: string) {
    this.current_tab$.next(value);
  }

  handleTabChange($event: any) {
    this.current_tab$.next(this.tabs[$event.index].items[0].name);
  }
  getHeight(height: any){
    this.container_height = height;
  }
}
