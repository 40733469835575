<ng-container [ngSwitch]="role">
    <ng-container *ngSwitchCase="1">
        <label>Admin</label>
    </ng-container>
    <ng-container *ngSwitchCase="2">
        <label>Team Leader</label>
    </ng-container>
    <ng-container *ngSwitchCase="3">
        <label>Customer</label>
    </ng-container>
    <ng-container *ngSwitchCase="4">
        <ng-container *ngIf="is_pm; else noPM">
            <label>Project Manager</label>
        </ng-container>
        <ng-template #noPM>
            <label>Designer</label>
        </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="7">
        <label>Supporter</label>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <label>N/A</label>
    </ng-container>
</ng-container>
