/* eslint-disable */
import { inject, Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ComponentStore } from "@ngrx/component-store";
import { UserData, UserService, WatchListService } from "@penji/shared/data-access";
import { catchError, combineLatest, debounceTime, distinctUntilChanged, EMPTY, map, Observable, of, shareReplay, switchMap } from "rxjs";
import { AuthService } from "../auth.service";
import { AuthState, inititialState } from "./auth.state";

@Injectable({ providedIn: 'root' })
export class AuthStore extends ComponentStore<AuthState> {
  userSV = inject(UserService);
  watchListSV = inject(WatchListService);
    constructor(
        private afAuth: AngularFireAuth,
        private authSV: AuthService,
    ) {
        super(inititialState);
    }
    readonly auth_id$ = this.select(state => state.auth_profile?.uid).pipe(shareReplay());
    readonly authProfile$ = this.select(state => state.auth_profile).pipe(shareReplay());
    readonly refresh_token$ = this.select(state => state.refresh_token).pipe(shareReplay());
    
    readonly updateAuthProfile = this.updater((state: AuthState, user: UserData) => {
        return {
            ...state,
            auth_profile: { ...state.auth_profile, ...user}
        }
    });
    readonly init = this.effect((trigger$) => {
        return trigger$.pipe(
            switchMap(() => {
                return this.afAuth.authState.pipe(
                    switchMap((auth) => {
                        if(auth) {
                          return this.authSV.getUserDetail(auth.uid).then(userInfo =>  {
                            this.setState((state) => {
                                return {
                                    ...state,
                                    auth_profile: userInfo,
                                    refresh_token: auth.refreshToken
                                }
                            });
                          })
                        } else {
                          return EMPTY;
                        }
                    }), catchError(() => EMPTY )
                )
            })
        )
    })
    updateAuthProfile$ = this.effect((user$: Observable<UserData>) => {
        return user$.pipe(
            distinctUntilChanged(),
            debounceTime(1000),
            switchMap(
                (user) => {
                    return combineLatest(this.userSV.updateUserByAuth(user), of(user));
                }
            ),
            map(([result, user]) => {
                if(result.flag)
                    this.updateAuthProfile(user);
            })
        )
    })
}
