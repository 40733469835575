<div [ngClass]="['relative flex items-center', container_class_name]">
  <input nz-input #input [formControl]="control" [placeholder]="placeholder" [ngClass]="input_class_name" />

  <ng-container *ngIf="showClearButton">
    <svg-icon key="close" [fontSize]="icon_size" class="text-gray-300 hover:text-gray-400 cursor-pointer absolute z-20 right-2 top-1/2 -translate-y-1/2"
      (click)="clear()"></svg-icon>
  </ng-container>
    <ng-container *ngIf="icon">
      <svg-icon [key]="icon" [fontSize]="icon_size" class="text-gray-400 absolute z-10 left-3 top-1/2 -translate-y-1/2"> </svg-icon>
    </ng-container>
</div>
