import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { CategoryClass } from '@penji/shared/data-access';
import { CategoryClassTitleComponent } from '@penji/team/categories/category-ui/category-class-title';
import { CategoryTitleComponent } from '@penji/team/categories/category-ui/category-title';
import * as categorySelectors from '@penji/team/categories/data-access';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-category-class-dropdown',
  standalone: true,
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    CategoryTitleComponent,
    CategoryClassTitleComponent
  ],
  templateUrl: './category-class-dropdown.component.html',
  styleUrls: ['./category-class-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryClassDropdownComponent {

  store = inject(Store);
  category_classes$: Observable<CategoryClass[]> | undefined;
  @Input() get_class_id = false;
  @Input() input_id?: string = '';
  @Input() disabled?: boolean = false;
  @Output() output_id = new EventEmitter();

  ngOnInit(): void {
    this.category_classes$ = this.store.pipe(select(categorySelectors.getAllClass));
  }

  selectCate(event:string){
    this.output_id.emit(event);
  }

}
