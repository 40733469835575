<!--
  // 0: Unavailable
  // 1: PTO
  // 2: UPTO
  // 3: Checkin
  // 4: Late
  // 5: Checkout
-->
<ng-container [ngSwitch]="checkin_status">
  <p *ngSwitchCase="1" class="text-blue-500 space-x-1.5 inline-flex items-center">
    <svg-icon key="circle-fill" fontSize="12px"></svg-icon>
    <span>PTO</span>
  </p>
  <p *ngSwitchCase="2" class="text-orange-500 space-x-1.5 inline-flex items-center">
    <svg-icon key="circle-fill" fontSize="12px"></svg-icon>
    <span>UPTO</span>
  </p>
  <p *ngSwitchCase="3" class="text-green-500 space-x-1.5 inline-flex items-center" nz-tooltip [nzTooltipTitle]="checkin_at ? (checkin_at.toDate() | date : 'medium') : 'N/A'">
    <svg-icon key="circle-fill" fontSize="12px"></svg-icon>
    <span>Checkin</span>
  </p>
  <p *ngSwitchCase="4" class="text-orange-500 space-x-1.5 inline-flex items-center" nz-tooltip [nzTooltipTitle]="checkin_at ? (checkin_at.toDate() | date : 'medium') : 'N/A'">
    <svg-icon key="circle-fill" fontSize="12px"></svg-icon>
    <span>Late</span>
  </p>
  <p *ngSwitchCase="5" class="text-gray-400 space-x-1.5 inline-flex items-center" nz-tooltip [nzTooltipTitle]="checkout_at ? (checkout_at.toDate() | date : 'medium') : 'N/A'">
    <svg-icon key="circle-fill" fontSize="12px"></svg-icon>
    <span>Checkout</span>
  </p>
  <p *ngSwitchDefault class="text-gray-400 space-x-1.5 inline-flex items-center">
    <svg-icon key="circle-fill" fontSize="12px"></svg-icon>
    <span>Unavailable</span>
  </p>
</ng-container>
