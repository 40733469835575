import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'penji-project-review-item',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './project-review-item.component.html',
  styleUrls: ['./project-review-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectReviewItemComponent {

  @Input() overdue?: boolean = false;
  @Input() circle_type?: number = 1;

}
