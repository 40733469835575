<ng-container [ngSwitch]="membership_status">
  <p *ngSwitchCase="'pro'" class="text-green-500 bg-green-100 py-1.5 px-3.5 rounded-3xl space-x-1.5 inline-flex items-center">
    <svg-icon key="circle-fill" fontSize="12px"></svg-icon>
    <span>Pro</span>
  </p>
  <p *ngSwitchCase="'team'" class="text-orange-500 bg-orange-100 py-1.5 px-3.5 rounded-3xl space-x-1.5 inline-flex items-center">
    <svg-icon key="circle-fill" fontSize="12px"></svg-icon>
    <span>Team</span>
  </p>
  <p *ngSwitchCase="'agency'" class="text-blue-500 bg-blue-100 py-1.5 px-3.5 rounded-3xl space-x-1.5 inline-flex items-center">
    <svg-icon key="circle-fill" fontSize="12px"></svg-icon>
    <span>Agency</span>
  </p>
  <p *ngSwitchCase="'invited'" class="text-gray-400 bg-gray-200 py-1.5 px-3.5 rounded-3xl space-x-1.5 inline-flex items-center">
    <svg-icon key="circle-fill" fontSize="12px"></svg-icon>
    <span>Invited</span>
  </p>
  <p *ngSwitchCase="'one-off'" class="text-primary-500 bg-primary-100 py-1.5 px-3.5 rounded-3xl space-x-1.5 inline-flex items-center">
    <svg-icon key="circle-fill" fontSize="12px"></svg-icon>
    <span>One Off</span>
  </p>
</ng-container>
