
<ng-container *ngIf="overdue; else no_overdue">
  <svg-icon key="error" fontSize="18px" class="text-red-500"></svg-icon>
</ng-container>
<ng-template #no_overdue>
  <ng-container *ngIf="circle_type===1">
    <svg-icon key="circle" fontSize="18px" class="text-gray-400"></svg-icon>
  </ng-container>
  <ng-container *ngIf="circle_type===2">
    <svg-icon key="check-fill" fontSize="18px" class="text-green-500"></svg-icon>
  </ng-container>
  <ng-container *ngIf="circle_type===3">
    <svg-icon key="check-circle" fontSize="18px" class="text-green-500"></svg-icon>
  </ng-container>
</ng-template>

