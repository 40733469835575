/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/firestore';
import { Observable, map, of, switchMap } from 'rxjs';
import { Project } from '../models/project.model';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { environment } from '@penji/shared/environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends LogService<Project>{
  private api_url = environment.api_algolia;
  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);

  searchProjectAlgolia(keyword: string) {
    return this.afAuth.idToken.pipe(
      switchMap(res => {
        if (res) {
          console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${res}`
            })
          }
          return this.http.post(`${this.api_url}/search-project`, { keyword }, httpOptions);
        } else {
          return of(null);
        }
      }),
      map((result: any) => {
        console.log(result);
        if (result)
          return result.data.hits as Project[];
        else
          return [];
      }))
  }
  getListProjectAlgolia(filter_obj: Params) {
    return this.afAuth.idToken.pipe(
      switchMap(res => {
        if (res) {
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${res}`
            })
          }
          const params = { ...filter_obj };
          for (const key in params) {
            if (key === 'type_complete') {
              params[key] = parseInt(params[key]);
            } else if (key === 'status') {
              params[key] = params[key].split(',');
              if (params[key].includes('3.6') && params[key].includes('3.7')) {
                delete params['status'];
                params['pending_review'] = true;
              } else if (params[key].includes('3.6')) {
                delete params['status'];
                params['pending_review'] = true;
                params['verify_progress'] = false;
              } else if (params[key].includes('3.7')) {
                delete params['status'];
                params['pending_review'] = true;
                params['verify_progress'] = true;
              }
            } else if (key === 'status_addition'){
                params['pause'] = false;
            }
            else if ( key === 'team_designer_id') {
              params[key] = params[key].split(',');
            }
          }
          console.log(params);
          return this.http.post(`${this.api_url}/filter-project`, params, httpOptions);
        } else {
          return of(null);
        }
      }))
  }
  async listProject(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string,
    order_by?: string,
    order_desc: boolean = true) {
    try {
      let query: Query = this.afs.firestore.collection('project_v2');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }

      if (order_by) {
        query = query.orderBy(order_by, order_desc ? 'desc' : 'asc');
      } else {
        query = query.orderBy('created_at', order_desc ? 'desc' : 'asc');
      }

      if (start_after) {
        const doc = await this.afs.firestore.doc(`project_v2/${start_after}`).get();
        query = query.startAfter(doc);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`project_v2/${end_before}`).get();
        query = query.endBefore(doc);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list: Array<Project> = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data() as Project;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  getListProjectRealTime(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>,
    order_by?: string,
    order_desc: boolean = true,
    not_order_by: boolean = false) {
    try {
      let query: Query = this.afs.firestore.collection('project_v2');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          if(q.field_value === undefined)
            return;
          else
            query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      } else {
        query = query.limit(10)
      }
      if (!not_order_by) {
        if (order_by) {
          query = query.orderBy(order_by, order_desc ? 'desc' : 'asc');
        } else {
          query = query.orderBy('created_at', 'desc');
        }
      }

      if (start_after) {
        query = query.startAfter(start_after);
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        query = query.endBefore(end_before);
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return new Observable<Project[]>(observ => {
        query.onSnapshot(querySnapshot => {
          const list: Array<Project> = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data() as Project;
            data.id = doc.id;
            data.doc = doc;
            list.push(data);
          });
          return observ.next(list);
        })
      });
    } catch (error) {
      console.log(error);
      return of([]);
    }
  }

  getProjectDetail(project_id: string) {
    return this.afs.doc<Project>(`project_v2/${project_id}`).valueChanges({ idField: 'id' });
  }
  getProjectDetailOnce(project_id: string) {
    return this.afs.firestore.doc(`project_v2/${project_id}`).get().then(res => ({ id: res.id, ...res.data() } as Project))
  }

  addProject(project: Project) {
    const _project = { ...project, created_at: firebase.Timestamp.now() }
    return this.afs.collection(`team/${project.team_id}/project`).add(_project).then(rs => {
      this.log_model.action = 'create';
      this.log_model.data = { ... new Project, ...project };
      this.createLog();
      return ({ flag: true, message: 'Project successfully added!', data: { ..._project, id: rs.id } });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message, data: {} as Project });
    });
  }

  updateProject(project: Project) {
    delete project.doc;
    return this.afs.doc(`team/${project.team_id}/project/${project.id}`).update({ ...project }).then(() => {
      this.log_model.action = 'update';
      this.log_model.data = { ... new Project, ...project };
      this.createLog();
      return ({ flag: true, message: 'Project successfully updated!' });
    }).catch((err: any) => {
      console.log(err);
      return ({ flag: false, message: err.message });
    });
  }

  deleteProject(project: Project) {
    delete project.doc;
    return this.afs.doc(`team/${project.team_id}/project/${project.id}`).update({ status: 8 }).then(() => {
      this.log_model.action = 'delete';
      this.log_model.data = { ... new Project, ...project };
      this.createLog();
      return ({ flag: true, message: 'Project successfully deleted!' });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message });
    });
  }

  /**
   * 0: started
   * 0.5: undo started
   * 1.5: question
   * 2: revision
   * 3: pending review
   * 3.5: reject
   * 4: delivered
   * 5: on-hold
   * 6: completed
   *  **/
  async changeStatus(client_team_id: string, project_id: string, status: number) {
    try {
      const data = new Project();
      data.team_id = client_team_id;
      data.id = project_id;
      data.updated_at = firebase.Timestamp.now();
      if (status === 0) { // change to started
        data.pending_review = false;
        data.verify_progress = false;
        data.status_addition = 1;
        data.start_design_at = firebase.Timestamp.now();
      } else if (status === 0.5) { // Undo started design
        data.pending_review = false;
        data.verify_progress = false;
        data.status_addition = 0;
        data.start_design_at = undefined;
      } else if (status === 1.5) { // change to question
        data.pending_review = false;
        data.verify_progress = false;
        data.status_addition = 3;
        data.circle_type = 1;
      } else if (status === 2) { // change to revision
        data.status = 2;
      } else if (status === 3) { // change to pending review
        data.pending_review = true;
        data.verify_progress = false;
        data.status_addition = 0;
      } else if (status === 3.5) { // change to reject
        data.pending_review = false;
        data.verify_progress = false;
        data.status_addition = 2;
      } else if (status === 4) { // change to delivered
        data.status = 4;
      } else if (status === 5) { // change to on hold
        data.status = 5;
      } else if (status === 6) { // change to completed
        data.type_complete = 2;
        data.status = 6;
        data.complete_at = firebase.Timestamp.now();
      }
      console.log(data);

      const kq = await this.updateProject(data);
      return ({ flag: kq.flag, message: kq.message });
    } catch (err) {
      console.log(err);

      return ({ flag: false, message: err });
    }
  }

  // Client-one-off
  getListProjectActive(client_team_id: string) {
    return this.afs.collection('team').doc(client_team_id).collection<DocumentData>('project', ref => {
      return ref
        .where("pause", "==", false)
        .where('status', '<=', 4)
    }).snapshotChanges().pipe(map(action => action.map(res => {
      const data = res.payload.doc.data() as Project;
      data.id = res.payload.doc.id;
      data.doc = res.payload.doc;
      data.action = res.type;
      return data;
    })));
  }
  getListProjectActiveForWidget(user_id: string, designer_team_id: string) {
    if (!user_id && designer_team_id) {
      return this.afs.collection<DocumentData>('project_v2', ref => {
        return ref
          .where("pause", "==", false)
          .where('status', '<=', 2)
          // .where("assigned", "==", true)
          .where("in_queue", "==", false)
          .where("pending_review", "==", false)
          .where("status_addition", "in", [0, 1, 2])
          .where("assign_team_designer", "==", designer_team_id)
      }).snapshotChanges().pipe(map(action => action.map(res => {
        const data = res.payload.doc.data() as Project;
        data.id = res.payload.doc.id;
        data.doc = res.payload.doc;
        data.action = res.type;
        return data;
      })));
    } else if (user_id && !designer_team_id) {
      return this.afs.collection<DocumentData>('project_v2', ref => {
        return ref
          .where("pause", "==", false)
          .where('status', '<=', 2)
          .where("assigned", "==", true)
          .where("in_queue", "==", false)
          .where("pending_review", "==", false)
          .where("status_addition", "in", [0, 1, 2])
          .where("assign_by", "array-contains", user_id);
      }).snapshotChanges().pipe(map(action => action.map(res => {
        const data = res.payload.doc.data() as Project;
        data.id = res.payload.doc.id;
        data.doc = res.payload.doc;
        data.action = res.type;
        return data;
      })));
    } else if (user_id && designer_team_id) {
      return this.afs.collection<DocumentData>('project_v2', ref => {
        return ref
          .where("pause", "==", false)
          .where('status', '<=', 2)
          .where("assigned", "==", true)
          .where("in_queue", "==", false)
          .where("pending_review", "==", false)
          .where("status_addition", "in", [0, 1, 2])
          .where("assign_team_designer", "==", designer_team_id)
          .where("assign_by", "array-contains", user_id);
      }).snapshotChanges().pipe(map(action => action.map(res => {
        const data = res.payload.doc.data() as Project;
        data.id = res.payload.doc.id;
        data.doc = res.payload.doc;
        data.action = res.type;
        return data;
      })));
    } else {
      return of([new Project]);
    }
  }

  async moveToCompleteProject(project: Project) {
    const batch = this.afs.firestore.batch();
    const data_project = {
      'status': project.status,
      'completed_by': project.completed_by,
      'type_complete': project.type_complete,
      'reason': project.reason,
      'feedback': project.feedback,
      'feedback_type': project.feedback_type,
      'complete_at': firebase.Timestamp.now(),
      'lastest_feedback': project.reason
    }
    batch.update(this.afs.firestore.collection('team').doc(project.team_id).collection('project').doc(project.id), data_project);
    const data = {
      'created_at': firebase.Timestamp.now(),
      'content': 'Marked project as complete',
      'type': 'text',
      'user_id': project.completed_by,
      'user_ref': this.afs.firestore.collection('user').doc(project.completed_by),
      'star': 0,
      'hidden': true,
      'remove': false,
      'feedback_type': project.feedback_type,
      'reason': project.reason,
      'feedback': project.feedback,
      'project_id': project.id,
      'project_ref': this.afs.firestore.collection('team').doc(project.team_id).collection('project').doc(project.id)
    };
    if (project.feedback && project.feedback.trim() != '') {
      data.content += '<br />';
      if (project.reason && project.reason.length > 0) {
        project.reason.forEach(item => {
          data.content += '_ ' + item + '<br />';
        });
      }
      data.content += 'Message from feedback: <br />' + project.feedback;
    }
    batch.set(this.afs.firestore.collection('team').doc(project.team_id).collection('project').doc(project.id).collection('discussion').doc(), data);
    batch.update
    return await batch.commit().then(() => ({ flag: true, message: 'Complete project success!' }))
      .catch(error => ({ flag: false, message: error }));
  }
  async getTotalProjectCompleteForMember(uid: string, type_complete: number) {
    try {
      const coll = firebase.collection(firebase.getFirestore(), 'project_v2');
      const q = firebase.query(coll,
        firebase.where("status", "==", 6),
        firebase.where("type_complete", "==", type_complete),
        firebase.where('assign_by', 'array-contains', uid),
        firebase.where('complete_at', '>=', firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 7))))
      );
      const snapshot = await firebase.getCountFromServer(q);
      return snapshot.data().count;
    } catch (error) {
      console.log(error);
      return 0;
    }

  }
  async getTotalProjectCompleteForCustomer(client_team_id: string, type_complete: number) {
    try {
      const coll = firebase.collection(firebase.getFirestore(), 'project_v2');
      const q = firebase.query(coll,
        firebase.where("status", "==", 6),
        firebase.where("type_complete", "==", type_complete),
        firebase.where('team_id', '==', client_team_id),
        firebase.where('complete_at', '>=', firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 7))))
      );
      const snapshot = await firebase.getCountFromServer(q);
      return snapshot.data().count;
    } catch (error) {
      console.log(error);
      return 0;
    }

  }

  getElapsedTime(timer: any) {
    let totalSeconds = Math.floor((new Date().getTime() - timer.toMillis()) / 1000);
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (totalSeconds >= 3600) {
      hours = Math.floor(totalSeconds / 3600);
      totalSeconds -= 3600 * hours;
    }

    if (totalSeconds >= 60) {
      minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= 60 * minutes;
    }

    seconds = totalSeconds;

    return {
      hours: hours,
      minutes: minutes,
      seconds: seconds
    };
  }
}
