<form nzLayout="vertical" nz-form #form="ngForm" (submit)="onSubmit(form)">
  <nz-form-item class="pb-3">
    <nz-form-label>Name</nz-form-label>
    <input nz-input tabindex="51" type="text" name="name" #name="ngModel" required [ngModel]="">
  </nz-form-item>
  <nz-form-item *ngIf="size" class="pb-3">
    <nz-form-label>Size</nz-form-label>
    <input nz-input tabindex="51" type="text" name="size" #size="ngModel" required [ngModel]="">
  </nz-form-item>
  <nz-form-item>
    <button type="submit" nz-button [nzBlock]="true" nzSize="large" nzType="primary">Add</button>
  </nz-form-item>
</form>
