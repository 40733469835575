<form [formGroup]="settings_form">
  <ng-container *ngFor="let item of settings | keyvalue ; let i =index">
    <ng-container [ngSwitch]="item.key">
      <ng-container *ngSwitchCase="'user_id'">
        <p class="mb-2"><span>MEMBER</span></p>
        <penji-input-select-form [settings_form]="settings_form" [key]="item.key"
          [value]="item.value"></penji-input-select-form>
      </ng-container>
      <ng-container *ngSwitchCase="'designer_team_id'">
        <p class="mb-2"><span>DESIGNER TEAM</span></p>
        <penji-input-select-form [settings_form]="settings_form" [key]="item.key"
          [value]="item.value"></penji-input-select-form>
      </ng-container>
      <ng-container *ngSwitchCase="'squad_id'">
        <p class="mb-2"><span>Squad Group</span></p>
        <penji-input-select-form [settings_form]="settings_form" [key]="item.key"
          [value]="item.value"></penji-input-select-form>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <nz-form-item nz-col>
          <nz-form-control nzErrorTip="Please enter input!">
            <input type="number" nz-input nzSize="large" [placeholder]="'Please enter input '+ item.key" class="rounded-sm text-14 text-gray-500"
              [formControlName]="item.key" />
          </nz-form-control>
        </nz-form-item>
      </ng-container>
    </ng-container>
  </ng-container>
</form>
