<ng-container *ngIf="overdue; else countdown">
  <ng-container *ngIf="temp$ | async"></ng-container>
    <span class="text-red-500"
      *ngIf="projectSV.getElapsedTime(project_data.time_change) as elapsed">
      {{elapsed.hours - 24}}:{{elapsed.minutes}}:{{elapsed.seconds}} late
    </span>

</ng-container>
<ng-template #countdown>
  <nz-countdown [nzValue]="deadline" [nzValueStyle]="{ color: '#868E96', 'font-size': '.95rem' }"></nz-countdown>
</ng-template>
