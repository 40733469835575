import { DatePipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { DocumentData, Query, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/firestore';
import { Observable, of } from 'rxjs';
import { Discussion } from '../models/discussion.model';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';

@Injectable({
  providedIn: 'root'
})
export class DiscussionService extends LogService<Discussion>{
  datePipe = inject(DatePipe);

  listDiscussion(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: QueryDocumentSnapshot<DocumentData>,
    end_before?: QueryDocumentSnapshot<DocumentData>,
    start_at?: QueryDocumentSnapshot<DocumentData>,
    end_at?: QueryDocumentSnapshot<DocumentData>) {
      try {
        let query: Query = this.afs.firestore.collection(`discussion_v2`);

        if (where_query && where_query.length > 0) {
          where_query.forEach(q => {
            query = query.where(q.field_name, q.field_operator , q.field_value);
          })
        }

        query = query.orderBy('created_at', 'desc');

        if (start_after) {
          query = query.startAfter(start_after);
          if(limit != -1) query = query.limit(limit);
        } else if (end_before) {
          query = query.endBefore(end_before);
          if(limit != -1) query = query.limitToLast(limit);
        } else if (start_at) {
          query = query.startAt(start_at);
          if(limit != -1) query = query.limit(limit);
        } else if (end_at) {
          query = query.endAt(end_at);
          if(limit != -1) query = query.limitToLast(limit);
        } else {
          if(limit != -1) query = query.limit(limit);
        }

        return query.get().then(querySnapshot => {
          const list: Array<Discussion> = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data() as Discussion;
            data.id = doc.id;
            data.doc = doc;
            data.date = this.datePipe.transform(data.created_at.seconds * 1000, 'MMM d, y');
            data['realtime'] = false;
            data['payload_type'] = 'added';
            list.push(data);
          });
          return list;
        }).catch(error=> 
          {
            console.log(error);
            return [];
          }
          
        );
      } catch (error) {
        return Promise.resolve([]);
      }
  }

  listDiscussionRealtime(
    where_query?: Array<WhereQueryInterface>,
    end_before?: QueryDocumentSnapshot<DocumentData> | null) {
      try {
        let query: Query = this.afs.firestore.collection(`discussion_v2`);
        if (where_query && where_query.length > 0) {
          where_query.forEach(q => {
            query = query.where(q.field_name, q.field_operator , q.field_value);
          })
        }
        query = query.orderBy('created_at', 'desc');

        if (end_before) {
          query = query.endBefore(end_before);
        }
        return new Observable<Discussion[]>(observ => {
          query.onSnapshot(querySnapshot => {
            const list: Array<Discussion> = [];
            querySnapshot.docChanges().forEach((change) => {
              const data = change.doc.data() as Discussion;
              data.id = change.doc.id;
              data.doc = change.doc;
              data['realtime'] = true;
              data['payload_type'] = change.type;
              data.date = this.datePipe.transform(data.created_at.seconds * 1000, 'MMM d, y');
              list.push(data);
            });
            return observ.next(list);
          });
        });
      } catch (error) {
        console.log(error);
        return of([]);
      }
  }

  addDiscussion(client_team_id: string, project_id: string, discussion: Discussion) {
    console.log(client_team_id, project_id, discussion);

    return this.afs.collection(`team/${client_team_id}/project/${project_id}/discussion`).add({...discussion}).then(rs => {
      this.log_model.action = 'create';
      this.log_model.data = {...new Discussion, ...discussion};
      this.createLog();
      return ({flag: true, message: 'Discussion successfully added!', data: {...discussion, id: rs.id}});
    }).catch((err: any) => {
      return ({flag: false, message: err.message, data: {} as Discussion});
    });
  }

  updateDiscussion(client_team_id: string, project_id: string, discussion: Discussion) {
    return this.afs.doc(`team/${client_team_id}/project/${project_id}/discussion/${discussion.id}`).update({...discussion}).then(() => {
      this.log_model.action = 'update';
      this.log_model.data = {...new Discussion, ...discussion};
      this.createLog();
      return ({flag: true, message: 'Discussion successfully updated!'});
    }).catch((err: any) => {
      return ({flag: false, message: err.message});
    });
  }

  deleteDiscussion(client_team_id: string, project_id: string, discussion_id: string) {
    return this.afs.doc(`team/${client_team_id}/project/${project_id}/discussion/${discussion_id}`).delete().then(() => {
      this.log_model.action = 'delete';
      this.log_model.data = { ... new Discussion, discussion_id: discussion_id} as Discussion;
      this.createLog();
      return ({flag: true, message: 'Discussion successfully deleted!'});
    }).catch((err: any) => {
      return ({flag: false, message: err.message});
    });
  }
  setEmailCommentTrigger(project_id: any, email_doc: any) {
    return this.afs.doc(`email_comment_trigger/${project_id}`).set(email_doc);
  }
  async addEmailCommentTrigger(project_id: any, email_doc: any) {
    const batch = this.afs.firestore.batch();
    batch.set(this.afs.firestore.doc(`email_comment_trigger/${project_id}`), email_doc, { merge: true });
    return await batch.commit().then(() => ({ flag: true, message: 'Success!' }))
      .catch(err => {
        console.log(err);
        return ({ flag: false, message: err.message });
      });
  }
  removeEmailCommentTrigger(project_id: string, key: string) {
    const sfDocRef = this.afs.firestore.collection("email_comment_trigger").doc(project_id);
    return this.afs.firestore.runTransaction((transaction) => {
      return transaction.get(sfDocRef).then((sfDoc) => {
        if (!sfDoc.exists) {
          throw "Document does not exist!";
        }
        let i = 0;
        const data = sfDoc.data();
        for (const key in data) {
          if (key.includes('content')) {
            i++;
          }
        }
        if (i > 1) {
          transaction.update(sfDocRef, {
            [key]: firebase.deleteField()
          });
        } else {
          transaction.delete(sfDocRef);
        }
      });
    }).then(() => {
      return ({ flag: true });
    }).catch((err) => {
      return ({ flag: false, message: err.message });
    });

  }
  updateEmailCommentTrigger(project_id: string, key: string, content: string) {
    return this.afs.doc(`email_comment_trigger/${project_id}`).update({
      [key + '.content']: content
    })
    .then(() => ({ flag: true, message: 'Success!' }))
    .catch(err => ({ flag: false, message: err.message }));
  }

  deleteInFeedbackInQueue(client_team_id: string, project_id: string, discussion_id: string) {
    return this.afs.doc(`team/${client_team_id}/project/${project_id}`)
      .set({ feedback_inqueue: firebase.arrayRemove(discussion_id) }, { merge: true })
      .then(() => {
        return ({ flag: true, message: 'Discussion successfully deleted!' });
      }).catch(err => {
        console.log(err);
        return ({ flag: false, message: err.message });
      });
  }

  uploadDesign(client_team_id: string, project_id: string, discussion: any) {
    // const batch = this.afs.firestore.batch();
    return this.afs.collection(`team/${client_team_id}/project/${project_id}/discussion`).add({...discussion}).then(rs => {
      this.log_model.action = 'upload_design';
      this.log_model.data = {...new Discussion, ...discussion};
      this.createLog();
      return ({flag: true, message: 'Discussion successfully added!', data: {...discussion, id: rs.id}});
    }).catch((err: any) => {
      return ({flag: false, message: err.message, data: {} as Discussion});
    });
  }

  getListDesign(project_id: string) {
    let query: Query = this.afs.firestore.collection(`discussion_v2`);
    console.log(query);
    query = query.where('type', '==', 'design')
    .where('project_id', '==', project_id)
    .where('remove', '==', false)
    .orderBy('created_at', 'asc');
    return query.get().then(querySnapshot => {
      const list: Array<Discussion> = [];
      console.log(querySnapshot);

      querySnapshot.forEach((doc)=>{
        const data = doc.data() as Discussion;
        data.id = doc.id;
        list.push(data);
      });
      console.log(list);

      return list;
    }).catch(() => of(null))
  }

}
