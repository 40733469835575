
<div penjiContentHeight (output_height)="getHeight($event)" class="absolute inset-0 py-3.5 px-5">
  <div class="flex justify-between items-center pb-2 pr-20">
    <h6 class="text-primary-500">Project By <penji-squad-group-name [squad_group_id]="squad_id"></penji-squad-group-name></h6>
    <a [routerLink]="['/project']"
    [queryParams]="{ squad_id: squad_id }"
    queryParamsHandling="merge" class="view-all font-bold text-primary-500 hover:text-primary-600 hidden">View All</a>
  </div>

  <nz-tabset nzType="card" class="ant-tabs-custom" nzSize="large" (nzSelectChange)="handleTabChange($event)">
    <nz-tab *ngFor="let tab of tabs" [nzTitle]="tab.name">
      <ul class="flex items-center">
        <li class="[&:not(:first-child)]:ml-8 [&>a]:hover:text-red-500"
          *ngFor="let item of tab.items; first as firstChild">
          <ng-container *ngIf="current_tab$ | async as current_tab">
            <a (click)="switchTab(item.name)" class="text-sm text-gray-500"
              [ngClass]="{'text-red-500 font-bold': (current_tab === item.name)}">{{ item.name }}</a>
          </ng-container>
        </li>
      </ul>
    </nz-tab>
  </nz-tabset>

  <ng-container *ngIf="{list_project:list_project$ | async, loading:loading$ | async} as vm">
    <ng-container *ngIf="vm.list_project && !vm.loading; else loading">
      <ng-container *ngIf="vm.list_project && vm.loading!==null;">
        <penji-project-w-table [container_height]="container_height" [list_project]="vm.list_project" [loading]="vm.loading" [class_table]="class_table"></penji-project-w-table>
      </ng-container>
    </ng-container>
    <ng-template #loading>
      <nz-spin nzTip="Loading..." nzSize="large" class="absolute inset-0"></nz-spin>
    </ng-template>
  </ng-container>
</div>

