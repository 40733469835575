<ng-container *ngIf="{ authProfile: authProfile$ | async, selectedCate: selectedCate$ | async } as vm">
  <ng-container *ngIf="(vm.authProfile?.permission?.['create_project'] && form_type==='add') || (vm.authProfile?.permission?.['edit_project'] && form_type=='edit')">
    <form nz-form nzLayout="vertical" [formGroup]="validateForm" (submit)="onSubmit()">

      <nz-form-item *ngIf="form_type=='add'" class="mb-3">
        <nz-form-label>Select a client <span class="text-red-500 pl-2">*</span></nz-form-label>
        <nz-form-control>
          <div style="display: flex; align-items: center;">
            <penji-search [user]="true" [role]="[3]" [select]="true" (uid_selected)="selectClient($event)"></penji-search>
            <ng-container *ngIf="validateForm.value.owner_id">
              <penji-user-item class="ml-3" [user_id]="validateForm.value.owner_id" [user_name]="true" [user_avatar]="true" user_size="md"></penji-user-item>
              <i class="ml-2 {{ validateForm.value.team_id ? 'color-green' : 'text-red-500' }}" nz-icon [nzType]="validateForm.value.team_id ? 'check' : 'close'" nzTheme="outline"></i>
            </ng-container>
          </div>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item  class="mb-3">
        <nz-form-label>Project title <span class="text-red-500 pl-2">*</span></nz-form-label>
        <nz-form-control>
          <input nz-input tabindex="1" type="text" name="title" id="title" #title="ngModel" required [ngModel]=""
            placeholder="E.g. Blood Type Infographic" autofocus
            autocomplete="off">
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="mb-3">
        <nz-form-label>Design category <span class="text-red-500 pl-2">*</span></nz-form-label>
        <nz-form-control>
          <penji-category-class-dropdown [disabled]="form_type==='edit' ? true : false" [get_class_id]="false" [input_id]="vm.selectedCate?.id" (output_id)="selectCate($event)"></penji-category-class-dropdown>
        </nz-form-control>
      </nz-form-item>

      <ng-container *ngIf="vm.selectedCate">
        <ng-container *ngIf="vm.selectedCate?.pages && vm.selectedCate?.pages.length > 0">
          <nz-form-item class="mb-3">
            <nz-form-label>Custom page <span class="text-red-500 pl-2">*</span></nz-form-label>
            <nz-form-control>
            <p class="mb-2 py-1 px-2 border-orange border bg-yellow-light rounded-sm">
              IMPORTANT: If you need multiple pages designed, please create a
              new project for each page.
            </p>
            <nz-radio-group [(ngModel)]="selected_custom_page" [ngModelOptions]="{standalone: true}">
              <ng-container *ngFor="let item of vm.selectedCate?.pages">
                <label class="project-wrap-item" nz-radio [nzValue]="item">
                  <div class="image-svg" [innerHTML]="item?.images | safeHtml">
                  </div>
                  <p class="font-bold">{{item?.name}}</p>

                </label>
              </ng-container>
              <ng-container *ngIf="custom_page">
                <label class="project-wrap-item" nz-radio [nzValue]="custom_page">
                  <div class="content position-relative">
                    <div class="image-svg" [innerHTML]="custom_page?.images | safeHtml">
                    </div>
                    <p class="font-bold">{{custom_page?.name}}</p>

                  </div>
                </label>
              </ng-container>
            </nz-radio-group>
            <a (click)="openCustomPage()" class="hover:border-blue-500 hover:border-solid hover:bg-white text-blue-400 group flex flex-col items-center justify-center border border-dashed border-blue-300 text-sm p-1 rounded-full w-8 h-8 mt-3"><svg-icon fontSize="16px" key="plus"></svg-icon></a>
            </nz-form-control>
          </nz-form-item>
        </ng-container>

        <ng-container *ngIf="(vm.selectedCate?.dimensions && vm.selectedCate?.dimensions.length > 0) || (vm.selectedCate?.dimensions_mobile && vm.selectedCate?.dimensions_mobile.length > 0)">
          <nz-form-item class="mb-3">
            <nz-form-label>Dimensions</nz-form-label>
            <nz-alert *ngIf="vm.selectedCate?.extra_field?.includes('warning_text')"
              nzMessage="IMPORTANT:  If you need multiple pages designed, we recommend creating a new project for each page.">
            </nz-alert>
          </nz-form-item>
        </ng-container>

        <ng-container *ngIf="vm.selectedCate?.dimensions && vm.selectedCate?.dimensions.length > 0">
          <nz-form-item class="mb-3">
            <nz-form-label>Desktop sizes</nz-form-label>
            <nz-form-control>
              <ng-container *ngIf="vm.selectedCate?.dimension_multiple; else radioDimension">
                <nz-checkbox-wrapper (nzOnChange)="changeCustomDimension(false, $event)">
                  <ng-container *ngFor="let item of vm.selectedCate?.dimensions">
                    <label class="project-wrap-item" nz-checkbox [nzValue]="item" [ngModel]="(selected_custom_dimension && selected_custom_dimension.includes(item)) ? true : false" [ngModelOptions]="{standalone: true}">
                      <div class="image-svg" [innerHTML]="item?.images | safeHtml"></div>
                      <p class="font-bold">{{item?.name}}</p>
                      <p class="m-0">{{item?.size}}</p>

                    </label>
                  </ng-container>
                  <ng-container *ngIf="custom_dimension && custom_dimension.length > 0">
                    <ng-container *ngFor="let item of custom_dimension">
                      <label class="project-wrap-item" nz-checkbox [nzValue]="item" [ngModel]="(selected_custom_dimension && selected_custom_dimension.includes(item)) ? true : false" [ngModelOptions]="{standalone: true}">
                        <div class="image-svg" [innerHTML]="item?.images | safeHtml">
                        </div>
                        <p class="font-bold">{{item?.name}}</p>
                        <p class="m-0">{{item?.size}}</p>

                      </label>
                    </ng-container>
                  </ng-container>
                </nz-checkbox-wrapper>
              </ng-container>
              <ng-template #radioDimension>
                <nz-radio-group [(ngModel)]="selected_custom_dimension" [ngModelOptions]="{standalone: true}">
                  <ng-container *ngFor="let item of vm.selectedCate?.dimensions">
                    <label class="project-wrap-item" nz-radio [nzValue]="item">
                      <div class="image-svg" [innerHTML]="item?.images | safeHtml"></div>
                      <p class="font-bold">{{item?.name}}</p>
                      <p class="m-0">{{item?.size}}</p>
                    </label>
                  </ng-container>
                  <ng-container *ngIf="custom_dimension">
                    <label class="project-wrap-item" nz-radio [nzValue]="custom_dimension">
                      <div class="image-svg" [innerHTML]="custom_dimension?.images | safeHtml"></div>
                      <p class="font-bold">{{custom_dimension?.name}}</p>
                      <p class="m-0">{{custom_dimension?.size}}</p>
                    </label>
                  </ng-container>
                </nz-radio-group>
              </ng-template>
              <a (click)="openCustomDimension(false, vm.selectedCate.dimension_multiple)" class="hover:border-blue-500 hover:border-solid hover:bg-white text-blue-400 group flex flex-col items-center justify-center border border-dashed border-blue-300 text-sm p-1 rounded-full w-8 h-8 mt-3"><svg-icon fontSize="16px" key="plus"></svg-icon></a>
            </nz-form-control>
          </nz-form-item>
        </ng-container>

        <ng-container *ngIf="vm.selectedCate?.dimensions_mobile && vm.selectedCate?.dimensions_mobile.length > 0">
          <nz-form-item class="mb-3">
            <nz-form-label>Mobile sizes</nz-form-label>
            <nz-form-control>
              <ng-container *ngIf="vm.selectedCate?.dimension_multiple; else radioDimensionMobile">
                <nz-checkbox-wrapper>
                  <ng-container *ngFor="let item of vm.selectedCate?.dimensions_mobile">
                    <label class="project-wrap-item" nz-checkbox [nzValue]="item" [ngModel]="(selected_custom_dimension_mobile && selected_custom_dimension_mobile.includes(item)) ? true : false" [ngModelOptions]="{standalone: true}">
                      <div class="image-svg" [innerHTML]="item?.images | safeHtml"></div>
                      <p class="font-bold">{{item?.name}}</p>
                      <p class="m-0">{{item?.size}}</p>

                    </label>
                  </ng-container>
                  <ng-container *ngIf="custom_dimension_mobile && custom_dimension_mobile.length > 0">
                    <ng-container *ngFor="let item of custom_dimension_mobile">
                      <label class="project-wrap-item" nz-checkbox [nzValue]="item" [ngModel]="(selected_custom_dimension_mobile && selected_custom_dimension_mobile.includes(item)) ? true : false" [ngModelOptions]="{standalone: true}">
                          <div class="image-svg" [innerHTML]="item?.images | safeHtml"></div>
                          <p class="font-bold">{{item?.name}}</p>
                          <p class="m-0">{{item?.size}}</p>

                      </label>
                    </ng-container>
                  </ng-container>
                </nz-checkbox-wrapper>
              </ng-container>
              <ng-template #radioDimensionMobile>
                <nz-radio-group [(ngModel)]="selected_custom_dimension_mobile" [ngModelOptions]="{standalone: true}">
                  <ng-container *ngFor="let item of vm.selectedCate?.dimensions_mobile">
                    <label class="project-wrap-item" nz-radio [nzValue]="item">
                      <div class="image-svg" [innerHTML]="item?.images | safeHtml"></div>
                      <p class="font-bold">{{item?.name}}</p>
                      <p class="m-0">{{item?.size}}</p>

                    </label>
                  </ng-container>
                  <ng-container *ngIf="custom_dimension_mobile">
                    <label class="project-wrap-item" nz-radio [nzValue]="custom_dimension_mobile">
                      <div class="image-svg" [innerHTML]="custom_dimension_mobile?.images | safeHtml"></div>
                      <p class="font-bold">{{custom_dimension_mobile?.name}}</p>
                      <p class="m-0">{{custom_dimension_mobile?.size}}</p>

                    </label>
                  </ng-container>
                </nz-radio-group>
              </ng-template>
              <a (click)="openCustomDimension(true, vm.selectedCate.dimension_multiple)" class="hover:border-blue-500 hover:border-solid hover:bg-white text-blue-400 group flex flex-col items-center justify-center border border-dashed border-blue-300 text-sm p-1 rounded-full w-8 h-8 mt-3"><svg-icon fontSize="16px" key="plus"></svg-icon></a>
            </nz-form-control>
          </nz-form-item>
        </ng-container>

        <ng-container *ngIf="vm.selectedCate?.length && vm.selectedCate?.length.length > 0">
          <nz-form-item class="mb-3">
            <nz-form-label>Length</nz-form-label>
            <nz-form-control>
              <nz-radio-group [(ngModel)]="selected_length" [ngModelOptions]="{standalone: true}">
                <ng-container *ngFor="let item of vm.selectedCate?.length">
                  <label class="project-wrap-item" nz-radio [nzValue]="item">
                    <div class="image-svg" [innerHTML]="item?.images | safeHtml">
                    </div>
                    <p class="font-bold">{{item?.name}}</p>
                    <p class="m-0">{{item?.est}}</p>

                  </label>
                </ng-container>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </ng-container>

        <ng-container *ngIf="vm.selectedCate?.extra_field?.includes('example_web_link')">
          <nz-form-item class="mb-3">
            <nz-form-label>If you have an existing website, please link it here</nz-form-label>
            <nz-form-control>
              <input nz-input type="text" name="example_web_link" id="example_web_link" #example_web_link="ngModel" [ngModel]="">
            </nz-form-control>
          </nz-form-item>
        </ng-container>

        <ng-container *ngIf="vm.selectedCate?.extra_field?.includes('example_app_link')">
          <nz-form-item class="mb-3">
            <nz-form-label>If you have an existing app, please link it here</nz-form-label>
            <nz-form-control>
              <input nz-input type="text" name="example_app_link" id="example_app_link" #example_app_link="ngModel" [ngModel]="">
            </nz-form-control>
          </nz-form-item>
        </ng-container>

        <ng-container *ngIf="vm.selectedCate?.extra_field?.includes('presentation_number')">
          <nz-form-item class="mb-3">
            <nz-form-label>Number of slides?</nz-form-label>
            <nz-form-control>
              <nz-select name="presentation_number" id="presentation_number" #presentation_number="ngModel" [ngModel]="">
                <nz-option nzLabel="1" nzValue="1"></nz-option>
                <nz-option nzLabel="2" nzValue="2"></nz-option>
                <nz-option nzLabel="3" nzValue="3"></nz-option>
                <nz-option nzLabel="4" nzValue="4"></nz-option>
                <nz-option nzLabel="5" nzValue="5"></nz-option>
                <nz-option nzLabel="6" nzValue="6"></nz-option>
                <nz-option nzLabel="7" nzValue="7"></nz-option>
                <nz-option nzLabel="8" nzValue="8"></nz-option>
                <nz-option nzLabel="9" nzValue="9"></nz-option>
                <nz-option nzLabel="10" nzValue="10"></nz-option>
                <nz-option nzLabel="11" nzValue="11"></nz-option>
                <nz-option nzLabel="12" nzValue="12"></nz-option>
                <nz-option nzLabel="13" nzValue="13"></nz-option>
                <nz-option nzLabel="14" nzValue="14"></nz-option>
                <nz-option nzLabel="15" nzValue="15"></nz-option>
                <nz-option nzLabel="16" nzValue="16"></nz-option>
                <nz-option nzLabel="17" nzValue="17"></nz-option>
                <nz-option nzLabel="18" nzValue="18"></nz-option>
                <nz-option nzLabel="19" nzValue="19"></nz-option>
                <nz-option nzLabel="20" nzValue="20"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
      </ng-container>

      <nz-form-item class="mb-3">
        <nz-form-label>Description <span class="text-red-500 pl-2">*</span></nz-form-label>
        <nz-form-control>
          <div class="special_include position-absolute rounded-sm bg-yellow-light border border-orange"
            cdkDragLockAxis="x" cdkDrag *ngIf="vm.selectedCate?.description; let description">
            <!-- <i nz-icon nzType="fullscreen" title="Move content" nzTheme="outline" cdkDragHandle></i>
              <i nz-icon nzType="close" nzTheme="outline" (click)="toogleInclude()"></i> -->
            <div class="special_include_content pt-3 px-3" *ngIf="description?.guideline; let guideline">
              <h5>Content guideline</h5>
              <ul class="p-0">
                <li class="d-flex align-items-center py-1" *ngFor="let item of guideline">
                  <i class="mr-2 color-green" nz-icon nzType="check" nzTheme="outline"></i>
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="special_include_content pt-3 px-3" *ngIf="description?.include; let include">
              <h5>What to include</h5>
              <ul class="p-0">
                <li class="d-flex align-items-center py-1" *ngFor="let item of include">
                  <i class="mr-2 color-green" nz-icon nzType="check" nzTheme="outline"></i>
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="special_include_content pt-3 px-3" *ngIf="description?.notice; let notice">
              <h5>Special notice</h5>
              <ul class="p-0">
                <li class="d-flex align-items-center py-1" *ngFor="let item of notice">
                  <i class="mr-2 text-red-500" nz-icon nzType="close" nzTheme="outline"></i>
                  {{ item }}
                </li>
              </ul>
            </div>
            <div class="special_include_content pt-3 px-3" *ngIf="description?.variation; let variation">
              <h5>Variations</h5>
              <p>{{ description?.variation_note }}</p>
              <ul class="p-0">
                <li class="d-flex align-items-center py-1" *ngFor="let item of variation">
                  <i class="mr-2 {{item?.type === 'check' ? 'color-green' : 'text-red-500'}}" nz-icon [nzType]="item?.type"
                    nzTheme="outline"></i>
                  {{ item?.content }}
                </li>
              </ul>
            </div>
          </div>
          <p class="my-2">
            Format your paragraphs and create checklists to make your description easy to read and follow.
            Well written instructions = better designs.
          </p>
          <quill-editor style="height: '150px'; width: '100% !important'" [ngModel]="" name="description" #description="ngModel"></quill-editor>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="mb-3">
        <nz-form-label>File deliverables</nz-form-label>
        <nz-form-control>
          <p>Select which source file type you want.</p>
          <nz-radio-group [(ngModel)]="selected_delivery" [ngModelOptions]="{standalone: true}">
            <ng-container *ngFor="let item of list_delivery">
              <label class="project-wrap-item" nz-radio [nzValue]="item.value">
                <div class="image-svg d-flex align-items-center justify-content-center"
                  [innerHTML]="item.images.toString() | safeHtml">
                </div>
                <p class="font-bold">{{item?.name}}</p>

              </label>
            </ng-container>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <button class="px-6" [disabled]="validateForm.invalid" [nzBlock]="true"
          type="submit" nz-button nzSize="large" nzType="primary">Submit</button>
      </nz-form-item>
    </form>
    <pre>{{validateForm.value | json}}</pre>
  </ng-container>
</ng-container>



