<ng-container *ngIf="auth_profile$ | async as auth_profile">
  <ng-container *ngIf="auth_profile.customer_following?.includes(customer_id); else watch">
    <span (click)="unfollowClient(auth_profile, customer_id)" class="cursor-pointer">
      <svg-icon key="heart" [fontSize]="icon_size+4+'px'" class="[&>svg]:fill-red-500 [&>svg>path]:fill-red-500 [&>svg>path]:stroke-red-500 ml-1.5"  nz-tooltip nzTooltipTitle=""></svg-icon>
    </span>
  </ng-container>
  <ng-template #watch>
    <svg-icon (click)="followClient(auth_profile, customer_id)" key="heart" [fontSize]="icon_size+4+'px'" class="[&>svg]:fill-none [&>svg>path]:fill-none [&>svg>path]:stroke-gray-500 ml-1.5 cursor-pointer"  nz-tooltip nzTooltipTitle=""></svg-icon>
  </ng-template>
</ng-container>
