import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { SETTINGS } from '@angular/fire/compat/firestore';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, Store, StoreModule } from '@ngrx/store';
import { UserEffect, UserReducer } from '@penji/shared/data-access';
import { environment } from '@penji/shared/environments';
import { CategoryClassEffect, CategoryClassReducer } from '@penji/team/categories/data-access';
import { MediaEffect, MediaReducer } from '@penji/team/media/data-access';
import { AppInit, ApplicationEffects, PenjiErrorHandler } from '@penji/team/shared/app-init';
import { SvgIconModule } from '@penji/team/shared/shared-ui/svg-icon';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ColorPickerModule } from 'ngx-color-picker';
import { AppComponent } from './app.component';
import { ShellModule } from '@penji/client-one-off/shell';
import { AngularFireAuthGuard, AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['media_redux'],
    rehydrate: true
  })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    RouterModule,
    ShellModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzNotificationModule,
    NzMessageModule,
    NzIconModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    StoreModule.forRoot({
      'media_redux': MediaReducer,
      'user_redux': UserReducer,
      'category_redux': CategoryClassReducer
    },{metaReducers}
    ),
    EffectsModule.forRoot([
      ApplicationEffects,
      MediaEffect,
      CategoryClassEffect,
      UserEffect
    ]),
    ColorPickerModule,
    SvgIconModule,
  ],
  providers: [
    AngularFireAuthGuard,
    { provide: NZ_I18N, useValue: en_US },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    // Emulator firestore setting
    {
      provide: SETTINGS,
      useValue: !environment.useEmulators ? undefined : {
        host: 'localhost:8080',
        ssl: false
      }
    },
    // End emulator firestore setting
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store) => () => {
        store.dispatch(AppInit());
      },
      multi: true,
      deps: [Store],
    },
    {
      provide: ErrorHandler,
      useClass: PenjiErrorHandler
    },
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
