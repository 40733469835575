/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { TeamService } from '@penji/shared/data-access';
import * as firebase from 'firebase/firestore';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { EMPTY, map, Observable } from 'rxjs';
import { NgChartsModule } from 'ng2-charts';
import { ChartData } from 'chart.js';

@Component({
  selector: 'penji-customer-threemonth-icon',
  standalone: true,
  imports: [
    CommonModule,
    NgChartsModule,
    NzProgressModule
  ],
  templateUrl: './customer-threemonth-icon.component.html',
  styleUrls: ['./customer-threemonth-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerThreemonthIconComponent {

  @Input() client_team_id?: string;
  @Input() icon_size?: number;

  teamSv = inject(TeamService);
  pieChartData$: Observable<any> | undefined;
  pieChartOptions = {
    plugins: {
      tooltip: {
        enabled: false
      }
    }
  };
  ngOnChanges(): void {
    if (this.client_team_id) {
      this.pieChartData$ = this.teamSv.getTeamDetail(this.client_team_id).pipe(
        map(rs => {
          if (rs && rs.created_at) {
            const final_90 = firebase.Timestamp.fromDate(new Date()).toDate().getTime() - rs.created_at.toDate().getTime();
            const days = Math.ceil(final_90 / (1000 * 3600 * 24))
            if (days > 90) {
              return {};
            } else {
              return {
                datasets: [
                  {
                    data: [days, 90 - days < 0 ? 0 : 90 - days],
                    backgroundColor: ['#22C55E', '#a8f0c2'],
                    borderColor: '#a8f0c2'
                  },
                ],
              }
            }
          } else {
            return {}
          }
        })
      );
    }
  }

}
