import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProjectService } from './project.service';

@Injectable({ providedIn: 'root' })
export class DrawerService {
  private projectSV = inject(ProjectService);
  data$ = new BehaviorSubject<{ visible: boolean, value: { type: string, data: any } }>({ visible: false, value: { type: '', data: null } });
  openDrawer(input_data: any) {
    const temp = {
      visible: true,
      value: {
        type: input_data.type,
        data: input_data.data
      }
    }
    if (input_data.type === 'project') {
      this.projectSV.getProjectDetailOnce(input_data.data.id).then(res=>{
        temp.value.data = res;
        this.data$.next(temp);
      }).catch(err=> console.log(err));
    } else {
      this.data$.next(temp);
    }
  }
  close() {
    this.data$.next({ visible: false, value: { type: '', data: null } })
  }
}
