
<div class="flex items-center py-2.5 px-4 bg-white border-b border-solid border-gray-200">
  <div class="left-header flex items-center space-x-4 mr-auto">
    <!-- <a class="inline-flex group">
      <svg-icon class="text-gray-400 group-hover:text-blue-400" key="menu" fontSize="19px"></svg-icon>
    </a> -->
    <div class="flex items-center space-x-4">
      <a routerLink="./">
        <img src="./assets/images/logo-penji.svg" alt="Logo" width="50">
      </a>
      <!-- <span class="rounded-md bg-green-600 text-white py-1.5 px-3 text-[13px] cursor-pointer hover:bg-green-500 min-h-[32px] font-satoshi-bold">Upgrade</span> -->
    </div>
  </div>
  <div class="right-header flex space-x-4 items-center">
    <!-- <a class="inline-flex group">
      <svg-icon class="text-gray-400 group-hover:text-blue-400" key="bell" fontSize="18px"></svg-icon>
    </a> -->
    <ng-container *ngIf="uid$ | async as uid">
      <div nz-dropdown [nzDropdownMenu]="menu" nzPlacement="bottomRight">
        <div class="flex items-center group space-x-2">
          <penji-user-item [user_id]="uid" [user_avatar]="true" user_size="md" class="pointer-events-none"></penji-user-item>
          <svg-icon class="text-gray-500 group-hover:text-blue-400" key="arrow-down" fontSize="10px"></svg-icon>
        </div>
      </div>
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu class="min-w-[120px]">
          <li nz-menu-item [routerLink]="['/profile', uid]">My profile</li>
          <li nz-menu-item (click)="authSV.logOut()">Log Out</li>
        </ul>
      </nz-dropdown-menu>
    </ng-container>

  </div>
</div>

