
<ng-container *ngIf="{user:user$ | async, auth_profile:auth_profile$ | async} as vm">
  <ng-container *ngIf="vm.user && vm.auth_profile">
    <div class="flex items-center space-x-3">
      <div *ngIf="user_avatar" [style.width.px]="setImageWidth" nz-tooltip [nzTooltipTitle]="clickable?vm.user.first_name+' '+vm.user.last_name:null">
        <div class="overflow-hidden rounded-full" [style.width.px]="setImageWidth" [style.height.px]="setImageWidth">
          <ng-container *ngIf="clickable && vm.user.id !== '8i7FhXui3qU9Q2DWgIOY3t3oV3l1'; else no_click">
            <a [routerLink]="[vm.user.role === 3 ? '/customer' : '/member', vm.user.id]" [title]="vm.user.first_name+' '+vm.user.last_name" class="cursor-pointer">
              <ng-container *ngTemplateOutlet="avatar"></ng-container>
            </a>
          </ng-container>
          <ng-template #no_click>
            <ng-container *ngTemplateOutlet="avatar"></ng-container>
          </ng-template>
        </div>
      </div>
      <div class="w-auto flex items-center {{user_size==='md'?'space-y-0.5':user_size==='lg'?'space-y-1':user_size==='xl'?'space-y-1.5':''}}" *ngIf="user_name || user_email">
        <ng-container *ngTemplateOutlet="name"></ng-container>
        <ng-container *ngTemplateOutlet="email"></ng-container>
      </div>
    </div>

    <ng-template #avatar>
      <ng-container *ngIf="user_avatar">
        <penji-media-ref [media_ref]="vm.user.avatar" media_size="avatar"></penji-media-ref>
      </ng-container>
    </ng-template>
    <ng-template #name>
      <ng-container *ngIf="clickable && vm.user.id !== '8i7FhXui3qU9Q2DWgIOY3t3oV3l1'; else no_click">
        <a [routerLink]="[vm.user.role === 3 ? '/customer' : '/member', vm.user.id]" [title]="vm.user.first_name+' '+vm.user.last_name" class="cursor-pointer hover:text-primary-500">
          <p *ngIf="vm.user.first_name && vm.user.last_name && user_name" [style.font-size.px]="setFontSize" class="flex items-center max-w-[150px]">
            <span nz-tooltip [nzTooltipTitle]="clickable?vm.user.first_name+' '+vm.user.last_name:null" class="font-{{font_weight}} capitalize truncate pr-2">{{ vm.user.first_name }} {{ vm.user.last_name }}</span>
            <ng-container>
              <penji-customer-daytime-icon [customer_id]="vm.user.id" [icon_size]="setFontSize"></penji-customer-daytime-icon>
              <penji-customer-threemonth-icon [client_team_id]="vm.user.client_team_id" [icon_size]="setFontSize"></penji-customer-threemonth-icon>
              <!-- <penji-designer-team-fire-icon [isFire]="true"></penji-designer-team-fire-icon> -->
            </ng-container>
          </p>
        </a>
        <penji-customer-watch-icon *ngIf="vm.user.role === 3 && vm.auth_profile.role !== 3" [customer_id]="vm.user.id" [icon_size]="setFontSize"></penji-customer-watch-icon>
      </ng-container>
      <ng-template #no_click>
        <p *ngIf="vm.user.first_name && vm.user.last_name && user_name" [style.font-size.px]="setFontSize" class="flex items-center max-w-[150px]">
          <span nz-tooltip [nzTooltipTitle]="clickable?vm.user.first_name+' '+vm.user.last_name:null" class="font-{{font_weight}} capitalize truncate">{{ vm.user.first_name }} {{ vm.user.last_name }}</span>
          <ng-container *ngIf="vm.user.role === 3 && vm.auth_profile.role !== 3">
            <penji-customer-daytime-icon [customer_id]="vm.user.id" [icon_size]="setFontSize"></penji-customer-daytime-icon>
            <penji-customer-threemonth-icon [client_team_id]="vm.user.client_team_id" [icon_size]="setFontSize"></penji-customer-threemonth-icon>
            <penji-customer-watch-icon class="flex" [customer_id]="vm.user.id" [icon_size]="setFontSize"></penji-customer-watch-icon>
          </ng-container>
        </p>
      </ng-template>
    </ng-template>
    <ng-template #email>
      <p class="flex items-center" *ngIf="vm.user.email && user_email" [style.font-size.px]="setFontSize" nz-typography nzCopyable [nzCopyText]="vm.user.email">
        {{ vm.user.email }}
      </p>
    </ng-template>
  </ng-container>
</ng-container>
