/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Injectable, ViewContainerRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/firestore';
import { NzModalService } from 'ng-zorro-antd/modal';
import { of, shareReplay, switchMap } from 'rxjs';
import { Dashboard } from '../models/dashboard.model';
import { Widget } from '../models/widget.model';
import { WProjectByTeamComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-project-by-team';
import { WProjectBySquadComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-project-by-squad';
import { WFormComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-form';
import { WRequestOffComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-request-off';
import { WOvertimeComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-overtime';
import { WProjectByDesignerComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-project-by-designer';
import { WMemberByTeamComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-member-by-team';
import { WWatchedCustomerComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-watched-customer';
import { WMemberBySquadComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-member-by-squad';
import { WUpcomingOffComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-upcoming-off';
import { WRequestOffCalendarComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-request-off-calendar';
import { WCustomerNewThirtyDaysComponent } from '@penji/team/dashboard/dashboard-ui/widgets/w-customer-new-thirty-days';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  list_widgets = [
    // {
    //   name: 'Project Todo',
    //   componentName: 'penji-w-project-todo',
    //   componentType: WProjectTodoComponent,
    //   cols: 20,
    //   rows: 10,
    //   settings: {
    //     user_id: null,
    //     designer_team_id: null
    //   },
    //   selected: false
    // },
    {
      name: 'Project By Team',
      componentName: 'penji-w-project-by-team',
      componentType: WProjectByTeamComponent,
      cols: 20,
      rows: 11,
      settings: {
        designer_team_id: null
      },
      selected: false
    },
    {
      name: 'Project By Squad Group',
      componentName: 'penji-w-project-by-squad',
      componentType: WProjectBySquadComponent,
      cols: 20,
      rows: 11,
      settings: {
        squad_id: null
      },
      selected: false
    },
    {
      name: 'Project By Designer',
      componentName: 'penji-w-project-by-designer',
      componentType: WProjectByDesignerComponent,
      cols: 20,
      rows: 11,
      selected: false
    },
    {
      name: 'Request Off',
      componentName: 'penji-w-request-off',
      componentType: WRequestOffComponent,
      cols: 10,
      rows: 8,
      selected: false
    },
    {
      name: 'Overtime',
      componentName: 'penji-w-overtime',
      componentType: WOvertimeComponent,
      cols: 10,
      rows: 8,
      selected: false
    },

    {
      name: 'Watched Customer',
      componentName: 'penji-w-watched-customer',
      componentType: WWatchedCustomerComponent,
      cols: 10,
      rows: 8,
      selected: false
    },
    {
      name: 'Member By Team',
      componentName: 'penji-w-member-by-team',
      componentType: WMemberByTeamComponent,
      cols: 10,
      rows: 8,
      settings: {
        designer_team_id: null
      },
      selected: false
    },
    {
      name: 'Member By Squad',
      componentName: 'penji-w-member-by-squad',
      componentType: WMemberBySquadComponent,
      cols: 10,
      rows: 8,
      settings: {
        squad_id: null
      },
      selected: false
    },
    {
      name: 'Upcoming Off',
      componentName: 'penji-w-upcoming-off',
      componentType: WUpcomingOffComponent,
      cols: 10,
      rows: 8,
      selected: false
    },
    {
      name: 'Request Off Calendar',
      componentName: 'penji-w-request-off-calendar',
      componentType: WRequestOffCalendarComponent,
      cols: 20,
      rows: 11,
      selected: false
    },
    {
      name: 'New Customer 30 days',
      componentName: 'penji-w-customer-new-thirty-days',
      componentType: WCustomerNewThirtyDaysComponent,
      cols: 10,
      rows: 8,
      selected: false
    },
  ];
  constructor(
    private afs: AngularFirestore, private afAuth: AngularFireAuth
  ) { }
  getDashboard() {
    return this.afAuth.authState.pipe(
      switchMap(res => {
        if (res) {
          return this.afs.firestore.collection('dashboard').doc(res.uid).get().then((doc) => {
            const data = { id: doc.id, ...doc.data() } as Dashboard;
            if (data.widgets) {
              data.widgets = data.widgets.map(widget => {
                const t_widget = this.list_widgets.find(res => res.componentName === widget.componentName);
                return { ...widget, ...t_widget, settings: widget.settings };
              })
            }
            return data;
          })
        } else {
          return of(null);
        }
      })
    )
  }

  updateDashboard(widgets: Widget[] = []) {
    return this.afAuth.authState.pipe(
      switchMap(res => {
        if (res) {
          const dashboard = new Dashboard;
          dashboard.owner_id = res.uid;
          dashboard.created_at = firebase.Timestamp.now();
          if (widgets.length > 0) {
            widgets.forEach(item => {
              const data = structuredClone({ ...item, componentType: '', componentForm: '' });
              dashboard.widgets.push(data);
            })
          }
          return this.afs.firestore.collection('dashboard').doc(res.uid).set({ ...dashboard }, { merge: true }).then(() => {
            return {
              flag: true,
              message: 'update the dashboard successfully!',
              data: widgets
            };
          }).catch(err => {
            return {
              flag: false,
              message: err,
              data: null
            }
          })
        } else {
          return of(null);
        }
      }),
      shareReplay()
    )
  }
  selectWidget(modal: NzModalService, viewContainerRef: ViewContainerRef, widget?: Widget) {
    return modal.create({
      nzTitle: widget?.name + ' Setting',
      nzContent: WFormComponent,
      nzStyle: { top: '20px' },
      nzWidth: '30%',
      nzViewContainerRef: viewContainerRef,
      nzFooter: null,
      nzComponentParams: {
        widget_data: widget
      }
    })
  }
}
