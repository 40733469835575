import { Pipe, PipeTransform } from '@angular/core';
import { DocumentReference } from '@angular/fire/compat/firestore';

@Pipe({
  name: 'dataFromRef',
  standalone: true,
})
export class DataFromRefPipe implements PipeTransform {
  transform(value: DocumentReference, args?: any) {
    return value.get().then((rs) => {
      const data = rs.data() as any;
      data.id = rs.id;
      return data;
    });
  }
}
