
<ng-container *ngIf="category_classes$ | async as category_classes">
  <nz-select [nzDisabled]="disabled" class="w-full" nzPlaceHolder="Select categories" nzShowSearch nzAllowClear [(ngModel)]="input_id" (ngModelChange)="selectCate($event)">
    <nz-option [nzValue]="" [nzLabel]="'None'"></nz-option>
    <ng-container *ngIf="get_class_id; else get_cate_id">
      <nz-option *ngFor="let class of category_classes" [nzValue]="class.id" [nzLabel]="class.title?class.title:'N/A'"></nz-option>
    </ng-container>
    <ng-template #get_cate_id>
      <nz-option-group *ngFor="let class of category_classes" [nzLabel]="classTitle">
        <ng-template #classTitle>
          <p class="text-sm">{{class.title}}</p>
        </ng-template>
        <ng-container *ngIf="class.categories&&class.categories.length>0">
          <nz-option *ngFor="let category of class.categories" [nzValue]="category.id" [nzLabel]="category.title?category.title:'N/A'"></nz-option>
        </ng-container>
      </nz-option-group>
    </ng-template>
  </nz-select>
</ng-container>

