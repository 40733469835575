/* eslint-disable */
import { Directive, ElementRef, Input, OnInit, inject } from '@angular/core';
import { PermissionService, initPermission, permission_type } from '@penji/shared/data-access';
import { take } from 'rxjs';

@Directive({
  selector: '[penjiPermission]',
  standalone: true
})
export class PermissionDirective implements OnInit {
  @Input('penjiPermission') extra_action: permission_type = 'all-team';
  permissionSV = inject(PermissionService);
  el = inject(ElementRef);
  ngOnInit(): void {
    this.permissionSV.data$.pipe(take(1)).toPromise().then(permission => {
      if (permission && permission['extra-action']) {
        let isBoolean = false;
        for (const key in permission['extra-action']) {
          const temp = { ...permission['extra-action'] } as any;
          if (key === this.extra_action) {
            isBoolean = temp[key];
            break;
          }
        }
        this.el.nativeElement.disabled = !isBoolean;
      } else {
        this.el.nativeElement.disabled = true;
      }
    });
  }

}
