<ng-container *ngIf="result$ | async as result">
  <div class="flex space-x-3 items-center">
    <p class="inline-flex items-center space-x-1">
      <svg-icon key="face-positive" fontSize="22px" class="text-green-500 -mt-0.5"></svg-icon>
      <span>{{result[0]}}</span>
    </p>
    <p class="inline-flex items-center space-x-1">
      <svg-icon key="face-neutral" fontSize="22px" class="text-yellow-500 -mt-0.5"></svg-icon>
      <span>{{result[1]}}</span>
    </p>
    <p class="inline-flex items-center space-x-1">
      <svg-icon key="face-negative" fontSize="22px" class="text-red-500 -mt-0.5"></svg-icon>
      <span>{{result[2]}}</span>
    </p>
  </div>
</ng-container>
