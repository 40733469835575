import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShellRoutingModule } from './shell.routing';

@NgModule({
  imports: [
    CommonModule,
    ShellRoutingModule
  ],
})
export class ShellModule {}
