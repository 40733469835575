
<div nz-row class="mb-4">
  <div nz-col [nzSpan]="24">
    <penji-search [user]="true" [role]="role" [select]="true" [auto_focus]="true" (uid_selected)="selectUser($event)"></penji-search>
  </div>
</div>
<div *ngIf="temp_list_user_id.length > 0" class="max-h-64 overflow-auto">
  <ng-container *ngIf="multiple_select; else single_select">
    <nz-checkbox-wrapper (nzOnChange)="onClickUser($event)">
      <ng-container *ngFor="let item of temp_list_user_id">
        <label class="flex items-center px-4 py-2 hover:bg-gray-100" nz-checkbox [nzValue]="item" [ngModel]="(temp_list_user_id && temp_list_user_id.includes(item)) ? true : false">
          <penji-user-item [user_id]="item" [user_name]="true" [user_avatar]="true" user_size="md" [clickable]="false" font_weight="regular"></penji-user-item>
        </label>
      </ng-container>
    </nz-checkbox-wrapper>
  </ng-container>
  <ng-template #single_select>
    <nz-radio-group nz-row>
      <ng-container *ngFor="let item of temp_list_user_id">
        <label class="flex items-center px-4 py-2 hover:bg-gray-100" nz-radio [nzValue]="item" [ngModel]="true">
          <penji-user-item [user_id]="item" [user_name]="true" [user_avatar]="true" user_size="sm" [clickable]="false" font_weight="regular"></penji-user-item>
        </label>
      </ng-container>
    </nz-radio-group>
  </ng-template>
</div>

<div nz-row class="pt-5">
  <div nz-col [nzSpan]="24">
    <button nz-button type="button" nzType="primary" [nzBlock]="true" (click)="onSubmit()">Save Changes</button>
  </div>
</div>


