
<nz-table #table_list [nzData]="list_project" [nzBordered]="false" [nzFrontPagination]="false"
[nzShowPagination]="false" [nzSize]="'middle'" [nzPageSize]="20" [nzScroll]="{ x: '81.250vw', y: container_height + 'px' }"
class="theme {{ class_table }}" [nzLoading]="loading">
  <thead>
    <tr>
      <th nzLeft nzWidth="50px"></th>
      <th nzLeft nzWidth="360px">
        Title
      </th>
      <th nzWidth="140px">
        Status
      </th>
      <th nzWidth="150px">
        Assigned
      </th>
      <th nzWidth="200px">
        Customer
      </th>
      <th nzWidth="100px">
        Deadline
      </th>
      <th nzWidth="100px">Action</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let item of table_list.data; trackBy:trackBy">
      <tr inViewport (inViewportAction)="viewPort($event, item.id)">
        <ng-container *ngIf="show_view_port[item.id!]; else loading_tr">
          <td nzLeft>
            <penji-project-review-item [overdue]="false" [circle_type]="item.circle_type ?? 1"
              class="flex items-center"></penji-project-review-item>
          </td>
          <td nzLeft class="relative">
            <p [routerLink]="['/project/' + item?.id]" class="text-sm flex font-bold text-gray-900 hover:text-primary-500 cursor-pointer  max-w-[300px]">
              <span class="truncate" nz-tooltip [nzTooltipTitle]="item?.title">{{ item?.title }}</span>
            </p>

            <button (click)="openDrawer(item)" nz-button nzType="default" nzSize="small" nz-tooltip
              nzTooltipTitle="Open in side peek" nzTooltipPlacement="bottom"
              nzTooltipOverlayClassName="tooltip-simple"
              class="hidden show-on-hover p-2 justify-center items-center space-x-1.5 absolute right-2 top-1/2 -translate-y-1/2">
              <svg-icon key="layout-sidebar-inset-reverse"></svg-icon>
              <span class="hidden md:inline-block text-sm mt-0.5">OPEN</span>
            </button>
          </td>
          <td>
            <penji-project-status [project_data]="item"></penji-project-status>
          </td>
          <td>
            <penji-project-assigned [project_data]="item" [reassign_on]="true"></penji-project-assigned>
          </td>
          <td>
            <penji-user-item [user_id]="item.owner_id" [user_avatar]="true" [user_name]="true"
              user_size="md"></penji-user-item>
          </td>
          <td>
            <penji-project-deadline-item [project_data]="item"></penji-project-deadline-item>
          </td>
          <td>
            <div class="items-center space-x-3 hidden show-on-hover">
              <penji-copy-item [id]="item.id"></penji-copy-item>
              <a nz-dropdown [nzDropdownMenu]="menu" class="flex items-center">
                <svg-icon key="dots" fontSize="24px"></svg-icon>
              </a>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu class="bg-white rounded shadow-2xl py-0 [&>li]:py-1.5 [&>li]:px-5">
                  <li nz-menu-item>
                    <a (click)="editProject(item)"
                      class="flex items-center [&>svg-icon>svg>path]:hover:text-blue-600 [&>span]:hover:text-blue-600 py-2">
                      <svg-icon key="pencil" fontSize="24px"></svg-icon>
                      <span class="hidden md:inline-block ml-[0.5rem] text-blue-500 text-sm font-normal">Edit</span>
                    </a>
                  </li>
                  <li nz-menu-item>
                    <ng-container *ngIf="item.status !== 8">
                      <a nz-popconfirm nzPopconfirmTitle="Are you sure delete this item?"
                        (nzOnConfirm)="deleleProject(item)"
                        class="flex items-center [&>svg-icon>svg>path]:hover:text-red-600 [&>span]:hover:text-red-600 py-2">
                        <svg-icon key="delete2" fontSize="24px"></svg-icon>
                        <span class="hidden md:inline-block ml-[0.5rem] text-red-500 text-sm font-normal">Delete</span>
                      </a>
                    </ng-container>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </td>
        </ng-container>
      </tr>
      <ng-template #loading_tr>
        <td [colSpan]="7">
          <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzParagraph]="{ rows: 1, width: '100%' }"></nz-skeleton>
        </td>
      </ng-template>
    </ng-container>
  </tbody>
</nz-table>

