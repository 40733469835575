/* eslint-disable */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from '@penji/team/shared/shared-ui/search';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'penji-user-select',
  standalone: true,
  imports: [
    CommonModule,
    SearchComponent,
    NzCheckboxModule,
    FormsModule,
    NzButtonModule,
    NzGridModule,
    NzRadioModule,
    UserItemComponent
  ],
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserSelectComponent {
  @Input() list_user_id!: string[];
  @Input() role!: number[];
  @Input() multiple_select?: boolean = true;
  @Output() list_user_id_output = new EventEmitter<string[]>();
  temp_list_user_id: string[] = [];
  is_loading = false;

  ngOnInit() {
    if (this.list_user_id) {
      this.temp_list_user_id = [...this.list_user_id];
    }
  }

  selectUser(user_id: string) {
    if (this.multiple_select) {
      if (this.temp_list_user_id && !this.temp_list_user_id.includes(user_id)) {
        if(Array.isArray(this.temp_list_user_id)) this.temp_list_user_id.push(user_id);
      }
    } else {
      this.temp_list_user_id = [user_id];
    }

  }

  onClickUser(list_user_id: string[]) {
    this.temp_list_user_id = [...list_user_id];
  }

  onSubmit() {
    this.is_loading = true;
    this.list_user_id_output.emit(this.temp_list_user_id);
    this.is_loading = false;
  }
}
