/* eslint-disable */
import { ChangeDetectionStrategy, Component, Input, inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSimple } from '@penji/shared/data-access';
import { Observable, tap } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as userActions from '@penji/shared/data-access';
import { RouterModule } from '@angular/router';
import { CustomerDaytimeIconComponent } from '@penji/team/customer/customer-ui/customer-daytime-icon';
import { CustomerThreemonthIconComponent } from '@penji/team/customer/customer-ui/customer-threemonth-icon';
import { MediaRefComponent } from '@penji/team/media/media-ui/media-ref';
import { AuthStore } from '@penji/shared/auth/data-access';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CustomerWatchIconComponent } from '@penji/team/customer/customer-ui/customer-watch-icon';
import { DesignerTeamFireIconComponent } from '@penji/team/designer-team/designer-team-ui/designer-team-fire-icon';

type ImageSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
const ImageSizesInPx: { [key in ImageSize]: number } = {
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  xxl: 28
};

@Component({
  selector: 'penji-user-item',
  standalone: true,
  imports: [
    CommonModule,
    MediaRefComponent,
    RouterModule,
    CustomerDaytimeIconComponent,
    CustomerThreemonthIconComponent,
    CustomerWatchIconComponent,
    NzTypographyModule,
    NzToolTipModule,
    DesignerTeamFireIconComponent,
  ],
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserItemComponent {
  @Input() user_id?: string;
  @Input() user_name = false;
  @Input() user_avatar = false;
  @Input() user_email = false;
  @Input() clickable = true;
  @Input() user_size: ImageSize = 'md';
  @Input() client_app = false;
  @Input() font_weight: 'bold' | 'regular' = 'bold';

  authStore = inject(AuthStore);
  user$?: Observable<UserSimple> | undefined;
  auth_profile$ = this.authStore.authProfile$;

  store = inject(Store);

  get setImageWidth(): number {
    return ImageSizesInPx[this.user_size] * 2;
  }
  get setFontSize(): number {
    return (ImageSizesInPx[this.user_size] * 1) - 2;
  }

  ngOnChanges() {
    if (this.user_id) {
      this.user$ = this.store.pipe(
        select(userActions.getUser(this.user_id)),
        tap((rs) => {
          if (!rs) {
            this.store.dispatch(
              userActions.loadUserDB({ uid: this.user_id ?? '' })
            );
          }
        })
      );
    }
  }
}
