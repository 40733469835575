import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Project } from '@penji/shared/data-access';
import { SvgIconComponent } from '@ngneat/svg-icon';

type StatusKey = 'in queue' | 'new' | 'question' | 'started' | 'revision' | 'to verify' | 'scheduled verify' | 'reject' | 'delivered' | 'on hold' | 'completed' | 'draft' | 'deleted' | 'pause';
type StatusTypes = {
  color: string , key: StatusKey
}
@Component({
  selector: 'penji-project-status',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent
  ],
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectStatusComponent {
  PROJECT_STATUS: {[key: string]: StatusTypes} = {
    'in queue': { color: 'text-gray-700', key: 'in queue' }, // in_queue = true
    'new': { color: 'text-blue-500', key: 'new' }, // 1
    'question': { color: 'text-secondary-500', key: 'question' }, // 1.5 , status_addition = 3
    'started': { color: 'text-cyan-500', key: 'started' }, // 0, status_addition = 1
    'revision': { color: 'text-yellow-500', key: 'revision' }, // 2
    'to verify': { color: 'text-orange-500', key: 'to verify' }, // 3
    'scheduled verify': { color: 'text-yellow-500', key: 'scheduled verify' }, // verify_progress = true
    'reject': { color: 'text-red-500', key: 'reject' }, // 3.5, status_addition = 2
    'delivered': { color: 'text-primary-500', key: 'delivered' }, // 4
    'on hold': { color: 'text-gray-400', key: 'on hold' }, // 5
    'completed': { color: 'text-green-500', key: 'completed' }, // 6
    'draft': { color: 'text-blue-300', key: 'draft' }, // 7
    'deleted': { color: 'text-red-500', key: 'deleted' }, // 8
    'pause': { color: 'text-gray-400', key: 'pause' }, // pause = true
  };

  @Input() project_data!: Project;

  result_status = '';
  ngOnChanges() {
    if(this.project_data) {
      if (this.project_data.status === 8) {
        this.result_status = this.PROJECT_STATUS['deleted']['key'];
      } else {
        if (this.project_data.in_queue) {
          this.result_status = this.PROJECT_STATUS['in queue']['key'];
        } else if (this.project_data.pause) {
          this.result_status = this.PROJECT_STATUS['pause']['key'];
        } else {
          if (this.project_data.pending_review) {
            if (this.project_data.verify_progress) {
              this.result_status = this.PROJECT_STATUS['scheduled verify']['key'];
            } else {
              this.result_status = this.PROJECT_STATUS['to verify']['key'];
            }
          } else {
            if (!this.project_data.status_addition || this.project_data.status_addition == 0) {
              if (this.project_data.status === 1) {
                this.result_status = this.PROJECT_STATUS['new']['key'];
              } else if (this.project_data.status === 2) {
                this.result_status = this.PROJECT_STATUS['revision']['key'];
              } else if (this.project_data.status === 4) {
                this.result_status = this.PROJECT_STATUS['delivered']['key'];
              } else if (this.project_data.status === 5) {
                this.result_status = this.PROJECT_STATUS['on hold']['key'];
              } else if (this.project_data.status === 6) {
                this.result_status = this.PROJECT_STATUS['completed']['key'];
              } else if (this.project_data.status === 7) {
                this.result_status = this.PROJECT_STATUS['draft']['key'];
              }
            } else {
              if (this.project_data.status_addition === 1) {
                this.result_status = this.PROJECT_STATUS['started']['key'];
              } else if (this.project_data.status_addition === 2) {
                this.result_status = this.PROJECT_STATUS['reject']['key'];
              } else if (this.project_data.status_addition === 3) {
                this.result_status = this.PROJECT_STATUS['question']['key'];
              }
            }
          }
        }
      }
    }
  }
}
