<button nz-button nzType="primary" nzShape="circle" nzSize="small"
  nz-popover
  nzPopoverTitle="Assign Designer"
  [(nzPopoverVisible)]="visible"
  nzPopoverTrigger="click"
  [nzPopoverContent]="reassign_content"
  nzPopoverOverlayClassName="popover-filter"
  class="inline-flex items-center justify-center"
>
  <svg-icon key="plus" fontSize="12px"></svg-icon>
</button>

<ng-template #reassign_content>
  <svg-icon key="cancel-shape" fontSize="26px" class="text-gray-800 hover:text-gray-500 cursor-pointer -top-7 right-4 absolute z-10" (click)="visible = false"></svg-icon>
  <ng-container *ngIf="project_data.assign_by&&project_data.assign_by.length>0">
    <penji-user-select [list_user_id]="project_data.assign_by" [role]="[2,4,7]" (list_user_id_output)="getListUserId($event)"></penji-user-select>
  </ng-container>
</ng-template>
