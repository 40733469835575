/* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Params } from '@angular/router';
import { environment } from '@penji/shared/environments';
import * as firebase from 'firebase/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, of, switchMap, take, throttleTime } from 'rxjs';
import { UserData } from '../models/user.model';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';
import { Query } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class MemberService extends LogService<UserData> {
  private api_url = environment.api_algolia;
  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);
  nzMessageService = inject(NzMessageService);
  getListMemberAlgolia(filter_obj: Params) {
    return this.afAuth.idToken.pipe(
      switchMap(res => {
        if (res) {
          console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${res}`
            })
          }
          const params = { ...filter_obj };
          for (const key in params) {
            if (key === 'team_designer_id') {
              params[key] = params[key].split(',');
            } else if (key === 'role') {
              params[key] = parseInt(params[key]);
            }
          }
          console.log(params);
          return this.http.post(`${this.api_url}/filter-member`, params, httpOptions);
        } else {
          return of(null);
        }
      }))
  }
  getListMemberRealTime(limit: number = 20, where_query?: Array<WhereQueryInterface>) {
    try {
      let query: Query = this.afs.firestore.collection('user');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      } else {
        query = query.limit(10);
      }
      query = query.orderBy('created_at', 'desc');
      if (limit != -1) query = query.limit(limit);

      return new Observable<UserData[]>(observ => {
        query.onSnapshot(querySnapshot => {
          const list: Array<UserData> = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data() as UserData;
            data.id = doc.id;
            data.doc = doc;
            list.push(data);
          });
          return observ.next(list);
        })
      });
    } catch (error) {
      console.log(error);
      return of([]);
    }
  }

  updateMember(data: UserData,) {
    console.log(data, UserData.parseObject(data));
    return this.afs.firestore.collection('user').doc(data.id).update(UserData.parseObject(data)).then(() => {
      this.log_model.action = 'update';
      this.log_model.data = { ...new UserData, ...data };
      this.createLog();
      if(!data.last_url_routing)
        this.nzMessageService.success('updated successfully!');
      return { flag: true, message: 'updated successfully!' };
    }).catch(err => {
      console.log(err);
      this.nzMessageService.error(err.message);
      return ({ flag: false, message: err.message });
    });
  }
  async disableMember(uid?: string, role?: number, is_pm?: boolean) {
    const batch = this.afs.firestore.batch();
    if (role == 4) {
      batch.update(this.afs.firestore.doc(`designer_project/${uid}`), { disabled: true });
      batch.update(this.afs.firestore.doc(`user/${uid}`), { disabled: true, deleted: true });
      return await batch.commit().then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ...new UserData, uid: uid, role: role };
        this.createLog();
        this.nzMessageService.success((is_pm ? 'PM' : 'Designer') + ' successfully disabled!');
        return ({ flag: true, message: (is_pm ? 'PM' : 'Designer') + ' successfully disabled!' });
      }).catch(err => {
        this.nzMessageService.error(err.message);
        return ({ flag: false, message: err.message });
      });
    } else if (role == 7) {
      batch.update(this.afs.firestore.doc(`supporter_ticket/${uid}`), { disabled: true });
      batch.update(this.afs.firestore.doc(`user/${uid}`), { disabled: true, deleted: true });
      return await batch.commit().then(() => {
        this.log_model.action = 'delete';
        this.log_model.data = { ...new UserData, uid: uid, role: role };
        this.createLog();
        this.nzMessageService.success('Supporter successfully disabled!');
        return ({ flag: true, message: 'Supporter successfully disabled!' });
      }).catch(err => {
        this.nzMessageService.error(err.message);
        return ({ flag: false, message: err.message });
      });
    } else {
      this.nzMessageService.error('You can NOT disable this role');
      return ({ flag: false, message: 'You can NOT disable this role' });
    }
  }
  async createMember(user_data: UserData) {
    try {
      const data = {
        token: '',
        role: user_data.role,
        team_designer_id: user_data?.team_id,
        first_name: user_data.user_info.first_name,
        last_name: user_data.user_info.last_name,
        email: user_data.user_info.email,
        auto_assign: user_data.auto_assign ? user_data.auto_assign : false,
        // assign_revision: user_data.assign_revision ? user_data.assign_revision : false,
        auto_verify: user_data.auto_verify ? user_data.auto_verify : false,
        enable_pto: user_data.enable_pto ? user_data.enable_pto : false,
        out_put_design: user_data.out_put_design,
        //weight: user_data.weight,
        // permission: this.permission,
        // permission_all_team: this.permissionAllTeam,
        // permission_multiple_team: this.permissionAllTeam ? [] : this.listChecked
      }
      const rs = await this.afAuth.idToken.pipe(take(1)).toPromise();
      if (rs) {
        data.token = rs
        const httpOptions = {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        return this.http.post(environment.api_link + '/api/user/create-new', data, httpOptions)
          .pipe(throttleTime(1500))
          .toPromise()
          .then((rs: any) => {
            if (rs['success']) {
              this.log_model.action = 'create';
              this.log_model.data = { ... new UserData, ...user_data };
              this.createLog();
              this.nzMessageService.success('User created successfully!');
              return { flag: true, message: 'User created successfully!' };
            } else {
              this.nzMessageService.error(rs['err']);
              return { flag: false, message: rs['err'] };
            }
          })
          .catch(error => {
            this.nzMessageService.error(error.message);
            return { flag: false, message: error.message };
          });
      } else {
        this.nzMessageService.error('Can NOT get token');
        return { flag: false, message: 'Can NOT get token' };
      }
    } catch {
      this.nzMessageService.error('Can NOT get token');
      return { flag: false, message: 'Can NOT get token' };
    }
  }
  async changeTeamForDesigner(current_designer_team_id: string, new_designer_team_id: string, designer_id: string) {
    const user = await this.afs.firestore.doc(`user/${designer_id}`).get().then(res => res.data() as UserData);
    let filteredArr = [];
    filteredArr = user?.permission_multiple_team ? user?.permission_multiple_team.filter(item => item !== current_designer_team_id) : [];
    filteredArr.push(new_designer_team_id);

    if (user?.is_pm) {
      const result = await this.afs.firestore.collection('team').where('payment', '==', true).where('pm_uid', '==', designer_id).orderBy('created_at', 'desc').limit(1).get();
      if (result.size > 0) {
        return ({ flag: false, message: 'Please reassign all client to another PM!' });
      } else {
        await this.afs.firestore.collection(`team_designer/${current_designer_team_id}/team_pm/`).doc(designer_id).update({
          type: 'change_team'
        });

        const batch = this.afs.firestore.batch();
        let tempRef = this.afs.firestore.collection(`team_designer/${current_designer_team_id}/team_pm/`).doc(designer_id);
        batch.delete(tempRef);
        tempRef = this.afs.firestore.collection(`team_designer/${new_designer_team_id}/team_pm/`).doc(designer_id);
        batch.set(tempRef, {
          created_at: firebase.Timestamp.now(),
          designer_id: designer_id,
          designer_ref: this.afs.firestore.doc(`user/${designer_id}`),
        }, { merge: true });

        tempRef = this.afs.firestore.collection(`user`).doc(designer_id);
        batch.update(tempRef, {
          team_designer_active: new_designer_team_id,
          team_id: new_designer_team_id,
          team_ref: this.afs.firestore.doc(`team_designer/${new_designer_team_id}`),
          squad_id: new_designer_team_id,
          permission_multiple_team: filteredArr,
        });

        return await batch.commit()
          .then(() => ({ flag: true, message: 'Success!' }))
          .catch(err => {
            console.log(err);
            return ({ flag: false, message: err.message });
          });
      }
    } else {
      await this.afs.firestore.collection(`team_designer/${current_designer_team_id}/team_member/`).doc(designer_id).update({
        type: 'change_team'
      });

      const batch = this.afs.firestore.batch();
      let tempRef = this.afs.firestore.collection(`team_designer/${current_designer_team_id}/team_member/`).doc(designer_id);
      batch.delete(tempRef);
      tempRef = this.afs.firestore.collection(`team_designer/${new_designer_team_id}/team_member/`).doc(designer_id);
      batch.set(tempRef, {
        created_at: firebase.Timestamp.now(),
        designer_id: designer_id,
        designer_ref: this.afs.firestore.doc(`user/${designer_id}`),
      }, { merge: true });

      tempRef = this.afs.firestore.collection(`user`).doc(designer_id);
      batch.update(tempRef, {
        team_designer_active: new_designer_team_id,
        team_id: new_designer_team_id,
        team_ref: this.afs.firestore.doc(`team_designer/${new_designer_team_id}`),
        squad_id: new_designer_team_id,
        permission_multiple_team: filteredArr,
      });

      return await batch.commit()
        .then(() => {
          this.nzMessageService.success('change successfully to new team!')
          return { flag: true, message: 'Success!' };
        })
        .catch(err => {
          console.log(err);
          this.nzMessageService.error(err.message);
          return ({ flag: false, message: err.message });
        });
    }
  }
  async changeSquadGroupForDesigner(new_squad_id: string, designer_id: string) {
    return this.afs.firestore.doc(`user/${designer_id}`).update({ squad_id: new_squad_id }).then(() => {
      this.nzMessageService.success('change successfully to new squad group!')
      return { flag: true, message: 'success!' };
    }).catch(err => {
      this.nzMessageService.error(err.message);
      return ({ flag: false, message: err.message });
    })
  }
}
