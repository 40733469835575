/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MemberService, UserData, WhereQueryInterface } from '@penji/shared/data-access';
import { ContentHeightDirective } from '@penji/shared/directives';
import { MemberCheckinStatusByUidComponent } from '@penji/team/member/member-ui/member-checkin-status-by-uid';
import { MemberLastSevenDaysFeedbackComponent } from '@penji/team/member/member-ui/member-last-seven-days-feedback';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { InViewportAction, InViewportDirective } from 'ng-in-viewport';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'penji-w-member-by-squad',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NzTableModule,
    NzDividerModule,
    UserItemComponent,
    MemberCheckinStatusByUidComponent,
    MemberLastSevenDaysFeedbackComponent,
    ContentHeightDirective,
    InViewportDirective,
    NzSkeletonModule,
    NzSpinModule
  ],
  templateUrl: './w-member-by-squad.component.html',
  styleUrls: ['./w-member-by-squad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WMemberBySquadComponent implements OnInit {
  @Input() limit = -1;
  @Input() squad_id: string | undefined;
  list_member$!: Observable<UserData[]>;
  private memberSV = inject(MemberService);
  sortName = (a: UserData, b: UserData) => a.user_info.first_name!.localeCompare(b.user_info.first_name!);
  sortActiveProject = (a: UserData, b: UserData) => (a.total_active_project ?? 0) - (b.total_active_project ?? 0);
  container_height = 0;
  show_view_port: any = {};

  ngOnInit(): void {
    if (this.squad_id) {
      const where_query: WhereQueryInterface[] = [
        {
          field_name: 'squad_id', field_operator: '==', field_value: this.squad_id
        }
      ];
      this.list_member$ = this.memberSV.getListMemberRealTime(this.limit, where_query);
    }
  }
  getHeight(height: any){
    this.container_height = height;
  }
  viewPort(event: InViewportAction, id: string): void {
    if(event.visible){
      this.show_view_port[id] = true;
    }
  }
  trackBy(index:any, item: any) {
    return item.id;
  }
}
