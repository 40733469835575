import { RouterModule } from '@angular/router';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { AuthStore } from '@penji/shared/auth/data-access';
import { ProjectService, WhereQueryInterface } from '@penji/shared/data-access';
import { ContentHeightDirective } from '@penji/shared/directives';
import { ProjectStoreService } from '@penji/team/project/data-access';
import { ProjectWTableComponent } from '@penji/team/project/project-ui/project-w-table';
import { NavigationTabComponent } from '@penji/team/shared/shared-ui/navigation-tab';
import * as firebase from 'firebase/firestore';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { BehaviorSubject, combineLatest, of, switchMap } from 'rxjs';

/* eslint-disable */
@Component({
  selector: 'penji-w-project-by-designer',
  standalone: true,
  imports: [
    CommonModule,
    NzTabsModule,
    NavigationTabComponent,
    ProjectWTableComponent,
    ContentHeightDirective,
    NzSpinModule,
    RouterModule
  ],
  templateUrl: './w-project-by-designer.component.html',
  styleUrls: ['./w-project-by-designer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectStoreService],
})
export class WProjectByDesignerComponent implements OnInit {

  projectSV = inject(ProjectService);
  store = inject(ProjectStoreService);
  authStore = inject(AuthStore);
  class_table = 'theme-overdue';
  list_project$ = this.store.data$;
  loading$ = this.store.loading$;
  current_tab$ = new BehaviorSubject<string>('Overdue');
  container_height = 0;
  auth_id$ = this.authStore.auth_id$;

  tabs = [
    {
      key: 'todo',
      name: 'Todo',
      items: [
        { key: 'overdue', name: 'Overdue' },
        { key: 'reviewed', name: 'Reviewed' },
        { key: 'unreviewed', name: 'Unreviewed' }
      ]
    },
    {
      key: 'to-verify',
      name: 'To Verify',
      items: [
        { key: 'verify', name: 'To Verify' },
        { key: 'schedule', name: 'Be Scheduled' },
      ]
    }
  ]
  ngOnInit(): void {

    this.store.loadProjectsForWidget$(combineLatest(this.current_tab$, this.authStore.auth_id$).pipe(
      switchMap(([current_tab, auth_id]) => {
        let where_query: WhereQueryInterface[] = [];
        let order_by;
        let order_desc;

        if (current_tab == 'Todo') {
          where_query = [
            {
              field_name: 'status',
              field_operator: 'in',
              field_value: [1,2]
            },
            {
              field_name: 'in_queue',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'pending_review',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'pause',
              field_operator: '==',
              field_value: false
            }
          ]
          order_by = 'time_change';
          order_desc = true;
          this.class_table = 'theme-default';
        }

        if (current_tab == 'To Verify') {
          where_query = [
            {
              field_name: 'in_queue',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'pending_review',
              field_operator: '==',
              field_value: true
            },
            {
              field_name: 'verify_progress',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'pause',
              field_operator: '==',
              field_value: false
            }
          ]
          order_by = 'time_change';
          order_desc = true;
          this.class_table = 'theme-verify';
        }

        if (current_tab == 'Be Scheduled') {
          where_query = [
            {
              field_name: 'in_queue',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'pending_review',
              field_operator: '==',
              field_value: true
            },
            {
              field_name: 'verify_progress',
              field_operator: '==',
              field_value: true
            },
            {
              field_name: 'pause',
              field_operator: '==',
              field_value: false
            }
          ];
          order_by = 'time_change';
          order_desc = true;
          this.class_table = 'theme-schedule';
        }

        if (current_tab == 'Overdue') {
          where_query = [
            {
              field_name: 'status',
              field_operator: 'in',
              field_value: [1,2]
            },
            {
              field_name: 'status_addition',
              field_operator: '!=',
              field_value: 3,
            },
            {
              field_name: 'in_queue',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'pending_review',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'pause',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'time_change',
              field_operator: '<=',
              field_value: firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 1)))
            },
          ];
          order_by = 'time_change';
          order_desc = true;
          this.class_table = 'theme-overdue';
        }

        if (current_tab == 'Reviewed') {
          where_query = [
            {
              field_name: 'status',
              field_operator: 'in',
              field_value: [1,2]
            },
            {
              field_name: 'in_queue',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'pending_review',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'pause',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'circle_type',
              field_operator: '==',
              field_value: 2
            },
            {
              field_name: 'time_change',
              field_operator: '>',
              field_value: firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 1)))
            },
          ];

          order_by = 'time_change';
          order_desc = true;
          this.class_table = 'theme-reviewed';
        }

        if (current_tab == 'Unreviewed') {
          where_query = [
            {
              field_name: 'status',
              field_operator: 'in',
              field_value: [1,2]
            },
            {
              field_name: 'in_queue',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'pending_review',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'pause',
              field_operator: '==',
              field_value: false
            },
            {
              field_name: 'circle_type',
              field_operator: '==',
              field_value: 1
            },
            {
              field_name: 'time_change',
              field_operator: '>',
              field_value: firebase.Timestamp.fromDate(new Date(new Date().setDate(new Date().getDate() - 1)))
            },
          ];

          order_by = 'time_change';
          order_desc = true;
          this.class_table = 'theme-unreviewed';
        }

        where_query.push({
          field_name: 'assign_by',
          field_operator: 'array-contains',
          field_value: auth_id
        })

        return of({ where_query, order_by, order_desc });
      })
    ));
  }
  switchTab(value: string) {
    this.current_tab$.next(value);
  }

  handleTabChange($event: any) {
    this.current_tab$.next(this.tabs[$event.index].items[0].name);
  }
  getHeight(height: any){
    this.container_height = height;
  }
}

