import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'penji-copy-item',
  standalone: true,
  imports: [
    CommonModule,
    NzTypographyModule,
    SvgIconComponent
  ],
  templateUrl: './copy-item.component.html',
  styleUrls: ['./copy-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyItemComponent {

  @Input() id: string | undefined = '';

}
