<ng-container *ngIf="ticketItem$ | async as ticketItem">
    <ng-container [ngSwitch]="clickable">
        <ng-container *ngSwitchCase="true">
            <a [ngStyle]="{'color':ticketItem.color, 'font-size':font_size, 'font-weight': '600'}"
                [routerLink]="['/ticket/'+ ticket_id]">
                {{ticketItem.title}}
            </a>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <p [ngStyle]="{'color':ticketItem.color, 'font-size':font_size, 'font-weight': '600'}">
                {{ ticketItem.title }}
            </p>
        </ng-container>
    </ng-container>
</ng-container>