/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { RequestOff, RequestOffService, WhereQueryInterface } from '@penji/shared/data-access';
import { ContentHeightDirective } from '@penji/shared/directives';
import { ConvertTimezonePipe } from '@penji/shared/pipes';
import { InputComponent } from '@penji/shared/ui/element/input';
import { MemberCheckinStatusComponent } from '@penji/team/member/member-ui/member-checkin-status';
import { RequestOffButtonUpdateComponent } from '@penji/team/request-off/request-off-ui/request-off-button-update';
import { RequestOffTypeComponent } from '@penji/team/request-off/request-off-ui/request-off-type';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { InViewportAction, InViewportDirective } from 'ng-in-viewport';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-w-request-off',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    NzTableModule,
    NzDividerModule,
    RouterModule,
    NzButtonModule,
    NzAlertModule,
    NzIconModule,
    ConvertTimezonePipe,
    NzPopoverModule,
    SvgIconComponent,
    NzPopconfirmModule,
    UserItemComponent,
    RequestOffTypeComponent,
    RequestOffButtonUpdateComponent,
    MemberCheckinStatusComponent,
    ContentHeightDirective,
    InViewportDirective,
    NzSkeletonModule,
    NzSpinModule
  ],
  templateUrl: './w-request-off.component.html',
  styleUrls: ['./w-request-off.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WRequestOffComponent {

  @Input() limit = -1;

  requestOffSV = inject(RequestOffService);
  expand = new Set<string>();
  list_request_off$!: Observable<RequestOff[]>;

  sortOfAt = (a: RequestOff, b: RequestOff) => (a.off_at < b.off_at) ? 1 : -1;
  lisPtoFilter = [
    { text: 'PTO', value: 1 },
    { text: 'UPTO', value: 2 }
  ];
  filterPto = (type: number, item: RequestOff) => item.pto == type;
  container_height = 0;
  show_view_port: any = {};

  constructor() {
    const where_query_list: Array<WhereQueryInterface> = [
      { field_name: 'approved', field_operator: '==', field_value: 0 }
    ];
    this.list_request_off$ = this.requestOffSV.listRequestOffRealTime(this.limit, where_query_list);
  }
  getHeight(height: any) {
    this.container_height = height;
  }
  expandRequestOff(id: string) {
    if (this.expand.has(id)) {
      this.expand.delete(id);
    } else {
      this.expand.clear();
      this.expand.add(id);
    }
  }
  viewPort(event: InViewportAction, id: string): void {
    if (event.visible) {
      this.show_view_port[id] = true;
    }
  }
  trackBy(index: any, item: any) {
    return item.id;
  }

}
