<ng-container [ngSwitch]="action">
    <ng-container *ngSwitchCase="'approve'">
        <button nz-button nzType="default" class="btn-green" nzSize="small" nz-popover nzPopoverTitle="Are you sure?"
            [(nzPopoverVisible)]="visible" nzPopoverTrigger="click" [nzPopoverContent]="approve_template">
            Approved
        </button>
        <ng-template #approve_template>
            <p>Are you sure <b>Approved</b> this request?</p>
            <div class="flex space-x-3 pt-5">
                <button (click)="visible=!visible" nz-button nzType="default" nzSize="small"
                    class="!font-normal flex-1">No</button>
                <button (click)="approvedOvertime()" nz-button nzType="primary" nzSize="small"
                    class="flex-1">Yes</button>
            </div>
        </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'reject'">
        <button nz-button nzType="default" nzDanger nzSize="small" nz-popover nzPopoverTitle="Are you sure?"
            [(nzPopoverVisible)]="visible" nzPopoverTrigger="click" [nzPopoverContent]="reject_template">
            Reject
        </button>
        <ng-template #reject_template>
            <p>Are you sure <b>Reject</b> this request?</p>
            <div class="flex space-x-3 pt-5">
                <button (click)="visible=!visible" nz-button nzType="default" nzSize="small"
                    class="!font-normal flex-1">No</button>
                <button (click)="rejectOvertime()" nz-button nzType="primary" nzSize="small"
                    class="flex-1">Yes</button>
            </div>
        </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'update'">
        <button nz-button nzType="default" nzSize="small" (click)="updateOvertime()">Update</button>
    </ng-container>
    <ng-container *ngSwitchCase="'delete'">
        <button nz-button nzType="default" nzDanger nzSize="small" nz-popover nzPopoverTitle="Are you sure?"
            [(nzPopoverVisible)]="visible" nzPopoverTrigger="click" [nzPopoverContent]="delete_template">
            Delete
        </button>
        <ng-template #delete_template>
            <p>Are you sure <b>Delete</b> this request?</p>
            <div class="flex space-x-3 pt-5">
                <button (click)="visible=!visible" nz-button nzType="default" nzSize="small"
                    class="!font-normal flex-1">No</button>
                <button (click)="deleteOvertime()" nz-button nzType="primary" nzSize="small"
                    class="flex-1">Yes</button>
            </div>
        </ng-template>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <button nz-button nzType="default" nzSize="small" [nzLoading]="true">Loading...</button>
    </ng-container>
</ng-container>