import { Directive, Output, EventEmitter, ElementRef, HostListener, inject } from '@angular/core';

@Directive({
  selector: '[penjiContentHeight]',
  standalone: true
})
export class ContentHeightDirective {

  @Output() output_height = new EventEmitter();
  element_ref = inject(ElementRef);
  @HostListener('window:resize', ['$event'])
  resize(event:any){
    this.resizeContainer();
  }

  ngAfterViewInit(): void {
    this.resizeContainer();
  }
  resizeContainer() {
    if(this.element_ref){
      this.output_height.emit(this.element_ref.nativeElement.clientHeight);
    }
  }
}

