import { TemplateAnswerStoreService } from '@penji/team/template-answer/data-access';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppInit } from './app-init.action';
import { tap } from "rxjs";
import { Store } from '@ngrx/store';
import * as categoryActions from '@penji/team/categories/data-access';
import { DesignerTeamStoreService } from '@penji/team/designer-team/data-access';
import { TicketCateStoreService } from '@penji/team/ticket/data-access';
import { AuthStore } from '@penji/shared/auth/data-access';
import { SquadGroupStoreService } from '@penji/team/squad-group/data-access';

@Injectable()
export class ApplicationEffects {

  store = inject(Store);
  actions$ = inject(Actions);
  authStore = inject(AuthStore);
  ticketCateStore = inject(TicketCateStoreService);
  DesignerTeamStoreService = inject(DesignerTeamStoreService);
  SquadGroupStoreService = inject(SquadGroupStoreService);
  initAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppInit),
        tap(() => {
          this.authStore.init();
          this.ticketCateStore.init();
          this.store.dispatch(categoryActions.loadCateAndClassDB());
          this.DesignerTeamStoreService.loadDesignerTeam$();
          this.SquadGroupStoreService.loadSquadGroup$();
        })
      ),
    {
      dispatch: false
    }
  );
}
