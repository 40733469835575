

<div *ngIf="uid_array && uid_array.length > 0" class="flex justify-start -space-x-1.5">
  <ng-container *ngFor="let uid of uid_array.slice(0, 3)">
    <penji-user-item [user_id]="uid" [user_avatar]="true" [user_size]="user_size" [clickable]="true"></penji-user-item>
  </ng-container>
  <ng-container *ngIf="uid_array.length > 3">
    <div class="relative group h-8 w-8 rounded-full border border-gray-400 bg-white flex items-center justify-center hover:bg-transparent hover:border-transparent">
      <svg-icon key="thin-plus" fontSize="16px" class="-mr-0.5"></svg-icon>
      {{ uid_array.length - 3 }}
      <div class="absolute hidden group-hover:flex -space-x-1.5 left-0 z-50">
        <p *ngFor="let uid of uid_array.slice(3, uid_array.length)">
          <penji-user-item [user_id]="uid" [user_avatar]="true" [user_size]="user_size" [clickable]="true"></penji-user-item>
        </p>
      </div>
    </div>
  </ng-container>
</div>

