<div [ngSwitch]="pto">
    <p *ngSwitchCase="0" class="text-gray-400 space-x-1.5 inline-flex items-center">
        <svg-icon *ngIf="style=='design'" key="circle-fill" fontSize="12px"></svg-icon>
        <span>UNSET</span>
    </p>
    <p *ngSwitchCase="1" class="text-blue-500 space-x-1.5 inline-flex items-center">
        <svg-icon *ngIf="style=='design'" key="circle-fill" fontSize="12px"></svg-icon>
        <span>PTO</span>
    </p>
    <p *ngSwitchCase="2" class="text-orange-500 space-x-1.5 inline-flex items-center">
        <svg-icon *ngIf="style=='design'" key="circle-fill" fontSize="12px"></svg-icon>
        <span>UPTO</span>
    </p>
    <p *ngSwitchDefault class="text-gray-400 space-x-1.5 inline-flex items-center">
        <svg-icon *ngIf="style=='design'" key="circle-fill" fontSize="12px"></svg-icon>
        <span>N/A</span>
    </p>
</div>