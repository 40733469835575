import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { CustomerService, Payment } from '@penji/shared/data-access';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

@Component({
  selector: 'penji-customer-daytime-icon',
  standalone: true,
  imports: [CommonModule, NzToolTipModule, SvgIconComponent],
  templateUrl: './customer-daytime-icon.component.html',
  styleUrls: ['./customer-daytime-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerDaytimeIconComponent {

  @Input() customer_id?: string;
  @Input() icon_size?: number;

  customerSv = inject(CustomerService);
  payment$: Promise<Payment> | undefined;

  ngOnChanges(): void {
    if(this.customer_id){
      this.payment$ = this.customerSv.getDetailPaymemtOfCustomer(this.customer_id);
    }
  }
}
