<div penjiContentHeight (output_height)="getHeight($event)" class="absolute inset-0 py-3.5 px-5">
  <div class="flex justify-between items-center pb-2 pr-20">
    <h6 class="text-primary-500">Request Overtime</h6>
    <a [routerLink]="['/hr-management']" class="view-all font-bold text-primary-500 hover:text-primary-600 hidden">View All</a>
  </div>
  <ng-container *ngIf="{list_overtime:list_overtime$ | async} as vm">
    <ng-container *ngIf="vm.list_overtime && vm.list_overtime.length > 0; else loading">
      <nz-table #table_list [nzData]="vm.list_overtime" [nzBordered]="false" [nzFrontPagination]="false"
        [nzShowPagination]="false" [nzSize]="'middle'" class="theme theme-default"
        [nzScroll]="{ x: '36.146vw', y: container_height - 135 + 'px' }">
        <thead>
          <tr>
            <th nzLeft nzWidth="240px">User Name</th>
            <th nzWidth="155px" [nzSortFn]="sortOvertimeAt">Day Overtime</th>
            <th nzWidth="190px"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of table_list.data; trackBy:trackBy">
            <tr inViewport (inViewportAction)="viewPort($event, item.id)" (click)="expandOvertime(item.id)" class="cursor-pointer">
              <ng-container *ngIf="show_view_port[item.id!]; else loading_tr">
                <td nzLeft>
                  <div class="flex items-center justify-between relative pl-6">
                    <a
                      class="flex items-center justify-center absolute left-0 top-1/2 -translate-y-1/2 text-blue-500">
                      <svg-icon key="double-chevron-right" fontSize="14px"
                        [class]="expand.has(item.id) ? 'rotate-90' : 'rotate-0'"></svg-icon>
                    </a>
                    <penji-user-item [user_name]="true" [user_avatar]="true" [user_id]="item.uid"></penji-user-item>
                  </div>
                </td>
                <td>{{ item.overtime_at ? (item.overtime_at.toDate() | date:'mediumDate') : 'N/A' }}</td>
                <td>
                  <div class="w-full flex space-x-1 justify-center">
                    <ng-container *ngIf="item.approved === 0">
                      <penji-overtime-button-update [action]="'approve'"
                        [overtime]="item"></penji-overtime-button-update>
                      <penji-overtime-button-update [action]="'reject'"
                        [overtime]="item"></penji-overtime-button-update>
                    </ng-container>
                  </div>
                </td>
              </ng-container>
            </tr>
            <tr [nzExpand]="expand.has(item.id)" *ngIf="item.overtime_link && item.overtime_link.length > 0">
              <penji-overtime-link-expand [overtime_link]="item.overtime_link"></penji-overtime-link-expand>
            </tr>
          </ng-container>
          <ng-template #loading_tr>
            <td [colSpan]="4">
              <nz-skeleton [nzActive]="true" [nzTitle]="false" [nzParagraph]="{ rows: 1, width: '100%' }"></nz-skeleton>
            </td>
          </ng-template>
        </tbody>
      </nz-table>
    </ng-container>
    <ng-template #loading>
      <nz-spin nzTip="Loading..." nzSize="large" class="absolute inset-0"></nz-spin>
    </ng-template>
  </ng-container>
</div>
