import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { UserData } from '@penji/shared/data-access';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private cookieSV: CookieService,
    private router: Router,
  ) {

  }

  async loginWithEmail(email: string, password: string) {
    try {
      await this.afAuth.signInWithEmailAndPassword(email, password);
      this.router.navigate([`/`]);
      return { success: true, message: "Login success." };
    } catch (error: any) {
      console.log(error);
      return { success: false, message: error.message };
    }
  }

  resetPassword(email: string): Promise<void> {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  async logOut() {
    await this.afAuth.signOut();
    if (this.cookieSV.get('login_penji')) {
      this.cookieSV.delete('login_penji', '/', 'penji.co');
    }
    this.router.navigate([`/login-process`]);
    // this.router.navigate([`/login`]);
  }

  async getUserDetail(uid: string): Promise<UserData | null> {
    try {
      const doc_user = await this.afs.firestore.collection("user").doc(uid).get();
      if (doc_user.exists) {
        // console.log(doc_user.data());
        const data_user = doc_user.data() as UserData;
        data_user.uid = doc_user.id;
        data_user.id = doc_user.id;
        return data_user;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }



  async loginWithToken(token: string) {
    try {
      const login = await this.afAuth.signInWithCustomToken(token);
      if (login && login.user?.uid) {
        const user = await this.getUserDetail(login.user?.uid);
        if (user) {
          return { success: true, data: user, message: "Login success." };
        } else {
          return { success: false, message: "Error." };
        }
      } else {
        return { success: false, message: "Error." };
      }
    } catch (error: any) {
      console.log(error);
      return { success: false, message: error.message };
    }
  }

  async getTokenWithEmail(email: string, password: string) {
    try {
      const credential = await this.afAuth.signInWithEmailAndPassword(email, password);
      if (credential) {
        const token = await credential.user?.getIdToken(true);
        if (token)
          return { success: true, data: token, message: "Login success." };
      }
      return { success: false, message: "Login failed." };
    } catch (error: any) {
      console.log(error);
      return { success: false, message: error.message };
    }
  }
}
