/* eslint-disable */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { CheckinService, WhereQueryInterface } from '@penji/shared/data-access';
import { MemberCheckinStatusComponent } from '@penji/team/member/member-ui/member-checkin-status';
import * as firebase from 'firebase/firestore';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'penji-member-checkin-status-by-uid',
  standalone: true,
  imports: [CommonModule, MemberCheckinStatusComponent],
  templateUrl: './member-checkin-status-by-uid.component.html',
  styleUrls: ['./member-checkin-status-by-uid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberCheckinStatusByUidComponent implements OnInit {
  @Input() uid!: string;
  @Input() hidden = false;
  @Output() checkin_status_output = new EventEmitter();
  private checkinSV = inject(CheckinService);
  checkin_status$: Observable<number> | undefined;
  ngOnInit(): void {
    if (this.uid) {
      this.checkin_status$ = this.checkinSV.getCheckinDetailByUID(this.uid).pipe(map(item => {
        let checkin_status = 0;
        if (item) {
          if (item.checkin_at) {
            checkin_status = 3;
            if (item.lateness) {
              checkin_status = 4;
            }
          }
          if (item.checkout_at) {
            checkin_status = 5;
          }
          if (item.pto && item.pto != 0) {
            checkin_status = item.pto;
          }
        }
        this.checkin_status_output.emit(checkin_status);
        return checkin_status;
      }));
    }
  }
}
