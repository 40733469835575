import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'penji-overtime-link-expand',
  standalone: true,
  imports: [CommonModule, SvgIconComponent,],
  templateUrl: './overtime-link-expand.component.html',
  styleUrls: ['./overtime-link-expand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OvertimeLinkExpandComponent {
  @Input() overtime_link: string[] = []
}
