import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as firebase from 'firebase/firestore';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WatchListService {

  afs = inject(AngularFirestore);

  constructor() {}

  getClientTeamFollowList(user_id: string) {
    return this.afs.collection(`watch_list/${user_id}/client_team_follow`).snapshotChanges().pipe(map(snapshot => {
        const items: string[] = [];
        snapshot.map(a => {
          const id = a.payload.doc.id;
          items.push(id);
        });
        return items;
      }),
    );
  }

  followClientTeam(user_id: string, client_team_id: string) {
    try {
      return this.afs.doc(`watch_list/${user_id}/client_team_follow/${client_team_id}`).set({
        created_at: firebase.Timestamp.now(),
        team_ref: this.afs.firestore.doc(`team/${client_team_id}`)
      })
        .then(() => ({ flag: true, message: 'Client team watched successfully!' }))
        .catch(err => {
          console.log(err);
          return ({ flag: false, message: err.message });
        });
    } catch (err: any) {
      return ({ flag: false, message: err.message });
    }
  }

  unfollowClientTeam(user_id: string, client_team_id: string) {
    try {
      return this.afs.doc(`watch_list/${user_id}/client_team_follow/${client_team_id}`).delete()
        .then(() => ({ flag: true, message: 'Client team unwatched successfully!' }))
        .catch(err => {
          console.log(err);
          return ({ flag: false, message: err.message });
        });
    } catch (err: any) {
      return ({ flag: false, message: err.message });
    }
  }

}
