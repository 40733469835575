/* eslint-disable */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectService } from '@penji/shared/data-access';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'penji-member-last-seven-days-feedback',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './member-last-seven-days-feedback.component.html',
  styleUrls: ['./member-last-seven-days-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MemberLastSevenDaysFeedbackComponent implements OnInit {
  @Input() uid!: string;
  projectSV = inject(ProjectService);
  result$: Promise<any> | undefined;
  ngOnInit(): void {
    if (this.uid) {
      this.result$ = Promise.all([
        this.projectSV.getTotalProjectCompleteForMember(this.uid, 2), 
        this.projectSV.getTotalProjectCompleteForMember(this.uid, 7),
        this.projectSV.getTotalProjectCompleteForMember(this.uid, 4)
      ]);
    }
  }
}
