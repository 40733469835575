import { CommonModule } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'penji-modal-output-string',
  standalone: true,
  imports: [
    CommonModule,
    NzGridModule,
    FormsModule,
    NzInputModule,
    NzButtonModule
  ],
  templateUrl: './modal-output-string.component.html',
  styleUrls: ['./modal-output-string.component.scss']
})
export class ModalOutputStringComponent {

  @Input() size = false;
  btnSubmit = false;
  ref = inject(NzModalRef);

  onSubmit(form: NgForm) {
    this.btnSubmit = true;
    if (this.size) {
      this.ref.destroy(form.value);
    } else {
      this.ref.destroy(form.value.name);
    }
    form.resetForm();
    this.btnSubmit = false;
  }
}
