export class Permission {
    'id'?: string;
    'customer'?: boolean;
    'member'?: boolean;
    'project'?: boolean;
    'designer-team'?: boolean;
    'squad-group'?: boolean;
    'category'?: boolean;
    'ticket'?: boolean;
    'checkin'?: boolean;
    'request-off'?: boolean;
    'logs'?: boolean;
    'overtime'?: boolean;
    'file-manager'?: boolean;
    'view-design'?: boolean;
    'template-answer'?: boolean;
    'style-preference'?: boolean;
    'affiliate'?: boolean;
    'hr-management'?: boolean;
    'extra-action'?: {
        'project-create'?: boolean;
        'project-edit'?: boolean;
        'project-review'?: boolean;
        'project-change-status'?: boolean;
        'project-manual-assign-designers'?: boolean;
        'project-verify-designs'?: boolean;
        'project-upload-designs'?: boolean;
        'project-mask-feature'?: boolean;
        'project-edit-anyone-comments'?: boolean;
        'project-delete-anyone-comments'?: boolean;
        'customer-view-email'?: boolean;
        'customer-update-account'?: boolean;
        'customer-update-membership'?: boolean;
        'member-view-list'?: boolean;
        'member-create'?: boolean;
        'member-update'?: boolean;
        'member-disable'?: boolean;
        'checkin-view-list'?: boolean;
        'checkin-update-list'?: boolean;
        'checkin-delete'?: boolean;
        'request-off-view-list'?: boolean;
        'request-off-update'?: boolean;
        'overtime-view-list'?: boolean;
        'overtime-update'?: boolean;
        'overtime-delete'?: boolean;
        'payroll-view'?: boolean;
        'payroll-update'?: boolean;
        'ticket-view'?: boolean;
        'ticket-create'?: boolean;
        'search'?: boolean;
        'all-team'?: boolean;
    }
    'log-type' = 'permission';
    static parseObject(data: any) {
        const transform: any = {};
        for (const key in data) {
            const value = data[key];
            if (typeof data[key] === 'object') {
                for (const keyDeep in data[key]) {
                    if (key === 'extra-action') {
                        transform[`extra-action.${keyDeep}`] = data[key][keyDeep];
                    } else {
                        transform[key] = value;
                    }
                }
            } else {
                transform[key] = value;
            }
        }
        return transform;
    }
}
export type permission_type = keyof typeof initPermission['extra-action'];
export const initPermission = {
    'customer': false,
    'member': false,
    'project': false,
    'designer-team': false,
    'squad-group': false,
    'category': false,
    'ticket': false,
    'checkin': false,
    'request-off': false,
    'logs': false,
    'overtime': false,
    'file-manager': false,
    'view-design': false,
    'template-answer': false,
    'style-preference': false,
    'affiliate': false,
    'hr-management': false,
    'extra-action': {
        'project-create': false,
        'project-edit': false,
        'project-review': false,
        'project-change-status': false,
        'project-manual-assign-designers': false,
        'project-verify-designs': false,
        'project-upload-designs': false,
        'project-mask-feature': false,
        'project-edit-anyone-comments': false,
        'project-delete-anyone-comments': false,
        'customer-view-email': false,
        'customer-update-account': false,
        'customer-update-membership': false,
        'member-view-list': false,
        'member-create': false,
        'member-update': false,
        'member-disable': false,
        'checkin-view-list': false,
        'checkin-update-list': false,
        'checkin-delete': false,
        'request-off-view-list': false,
        'request-off-update': false,
        'overtime-view-list': false,
        'overtime-update': false,
        'overtime-delete': false,
        'payroll-view': false,
        'payroll-update': false,
        'ticket-view': false,
        'ticket-create': false,
        'search': false,
        'all-team': false,
    }
}
export const initLeaderPermission = {
    'customer': true,
    'member': true,
    'project': true,
    'designer-team': true,
    'squad-group': true,
    'category': true,
    'ticket': true,
    'checkin': true,
    'request-off': true,
    'logs': true,
    'overtime': true,
    'file-manager': true,
    'view-design': true,
    'template-answer': true,
    'style-preference': true,
    'affiliate': false,
    'hr-management': true,
}
export const initMemberPermission = {
    'customer': true,
    'member': false,
    'project': true,
    'designer-team': false,
    'squad-group': false,
    'category': false,
    'ticket': true,
    'checkin': true,
    'request-off': true,
    'logs': false,
    'overtime': false,
    'file-manager': true,
    'view-design': true,
    'template-answer': false,
    'style-preference': true,
    'affiliate': false,
    'hr-management': false,
}