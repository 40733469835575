import { Component, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'penji-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  authSV = inject(AngularFireAuth);

  logout(){
    this.authSV.signOut().then(rs=> console.log(rs));
  }
}
