
<ng-container *ngIf="image_url">
  <ng-container *ngIf="image_auto_fit; else no_fit">
    <img
      nz-image
      [nzDisablePreview]="true"
      nzPlaceholder="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ccc' class='bi bi-image' viewBox='0 0 16 16'%3E%3Cpath d='M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z'/%3E%3Cpath d='M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z'/%3E%3C/svg%3E"
      [nzSrc]="image_url"
      alt="penji"
      class="bg-gray-100 rounded"
    />
  </ng-container>
  <ng-template #no_fit>
    <div style="background-image: url({{image_url}});"></div>
  </ng-template>
  <a *ngIf="clickable" class="absolute inset-0 z-50" (click)="openImage()"></a>
</ng-container>
