import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { DrawerService } from '@penji/shared/data-access';
import { ContentHeightDirective } from '@penji/shared/directives';
import { CustomerMembershipStatusComponent } from '@penji/team/customer/customer-ui/customer-membership-status';
import { LastSevenDaysFeedbackComponent } from '@penji/team/customer/customer-ui/last-seven-days-feedback';
import { CustomerStoreService } from '@penji/team/customer/data-access';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { InViewportAction, InViewportDirective } from 'ng-in-viewport';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { of } from 'rxjs';

@Component({
  selector: 'penji-w-customer-new-thirty-days',
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    UserItemComponent,
    NzButtonModule,
    SvgIconComponent,
    LastSevenDaysFeedbackComponent,
    CustomerMembershipStatusComponent,
    ContentHeightDirective,
    InViewportDirective,
    NzSkeletonModule,
    NzSpinModule
  ],
  templateUrl: './w-customer-new-thirty-days.component.html',
  styleUrls: ['./w-customer-new-thirty-days.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CustomerStoreService]
})
export class WCustomerNewThirtyDaysComponent {

  drawerSv = inject(DrawerService);
  customerStore = inject(CustomerStoreService);

  list_customer$ = this.customerStore.vm$;
  isLoading$ = this.customerStore.loading$;
  container_height = 0;
  show_view_port: any = {};

  ngOnInit(): void {
    this.customerStore.loadCustomers$(of(
      {
        status: 'active',
        start_at: new Date(new Date().setDate(new Date().getDate() - 30)).getTime(),
        end_at: new Date().getTime(),
        limit: 1000
      }
    ));
  }
  getHeight(height: any){
    this.container_height = height;
  }
  openDrawer(data: any){
    const temp = {
      type: 'customer',
      data: data
    }
    this.drawerSv.openDrawer(temp);
  }
  viewPort(event: InViewportAction, id: string): void {
    if(event.visible){
      this.show_view_port[id] = true;
    }
  }
  trackBy(index:any, item: any) {
    return item.id;
  }
}
