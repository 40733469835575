/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { UserData } from '../models/user.model';
import { environment } from '@penji/shared/environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { lastValueFrom, map, of, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private api_url = environment.api_algolia;
  http = inject(HttpClient);
  afAuth = inject(AngularFireAuth);
  constructor(private afs: AngularFirestore) { }

  searchUserAlgolia(keyword: string, roles: number[]) {
    return this.afAuth.idToken.pipe(
      switchMap(res => {
        if (res) {
          // console.log(res);
          const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${res}`
            })
          }
          return this.http.post(`${this.api_url}/search-user`, { keyword, roles }, httpOptions);
        } else {
          return of(null);
        }
      }),
      map((result: any) => {
        // console.log(result);
        if (result)
          return result.data.hits as UserData[];
        else
          return [];
      }))
  }
  getUserDetail(user_id: string) {
    return this.afs.collection('user').doc<UserData>(user_id).valueChanges().pipe(map(user => ({ id: user_id, ...user } as UserData)));
  }

  updateUser(user_id: string, user_data: UserData) {
    try {
      return this.afs.doc(`user/${user_id}/`).update({ ...user_data })
        .then(() => ({ flag: true, message: 'User updated successfully!' }))
        .catch(err => {
          return Promise.resolve({ flag: false, message: err.message });
        });
    } catch (err: any) {
      return Promise.resolve({ flag: false, message: err.message });
    }
  }
  updateUserByAuth(user_data: UserData) {
    return lastValueFrom(this.afAuth.authState.pipe(
      switchMap(user => {
        if (user)
          return this.afs.doc(`user/${user.uid}/`).update({ ...user_data }).then(() => true);
        return of(false);
      }),
      map(result => {
        if(result)
          return { flag: true, message: 'User updated successfully!' };
        else
          return  { flag: false, message: 'User updated successfully!' }
      })
    ).pipe(take(1)));
  }
}
