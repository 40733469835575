import { Injectable } from '@angular/core';
import { Query } from '@angular/fire/compat/firestore';
import { SquadGroup } from '../models/squad-group.model';
import { LogService } from './log.service';
import { WhereQueryInterface } from '../interfaces/where-query-interface';

@Injectable({
  providedIn: 'root'
})
export class SquadGroupService extends LogService<SquadGroup>{

  async listSquadGroup(
    limit: number = 20,
    where_query?: Array<WhereQueryInterface>,
    start_after?: string,
    end_before?: string) {
    try {
      let query: Query = this.afs.firestore.collection('squad_group');

      if (where_query && where_query.length > 0) {
        where_query.forEach(q => {
          query = query.where(q.field_name, q.field_operator, q.field_value);
        })
      }
      query = query.orderBy('created_at', 'desc');

      if (start_after) {
        const doc = await this.afs.firestore.doc(`squad_group/${start_after}`).get();
        query = query.startAfter(doc)
        if (limit != -1) query = query.limit(limit);
      } else if (end_before) {
        const doc = await this.afs.firestore.doc(`squad_group/${end_before}`).get();
        query = query.endBefore(doc)
        if (limit != -1) query = query.limitToLast(limit);
      } else {
        if (limit != -1) query = query.limit(limit);
      }

      return query.get().then(querySnapshot => {
        const list = Array<SquadGroup>();
        querySnapshot.forEach((doc) => {
          const data = doc.data() as SquadGroup;
          data.id = doc.id;
          data.doc = doc;
          list.push(data);
        });
        return list;
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  getSquadGroupDetail(squad_group_id: string) {
    return this.afs.doc<SquadGroup>(`squad_group/${squad_group_id}`).valueChanges({ idField: 'id' });
  }

  addSquadGroup(squad_group: SquadGroup) {
    return this.afs.collection(`squad_group`).add({ ...squad_group }).then(rs => {
      this.log_model.action = 'create';
      this.log_model.data = { ... new SquadGroup, ...squad_group };
      this.createLog();
      return ({ flag: true, message: 'Squad group successfully added!', data: { ...squad_group, id: rs.id } });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message, data: {} as SquadGroup });
    });
  }

  updateSquadGroup(squad_group: SquadGroup) {
    delete squad_group.doc;
    return this.afs.doc(`squad_group/${squad_group.id}`).update({ ...squad_group }).then(() => {
      this.log_model.action = 'update';
      this.log_model.data = { ... new SquadGroup, ...squad_group };
      this.createLog();
      return ({ flag: true, message: 'Squad group successfully updated!' });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message });
    });
  }

  deleteSquadGroup(squad_group_id: string) {
    return this.afs.doc(`squad_group/${squad_group_id}`).delete().then(() => {
      this.log_model.action = 'delete';
      this.log_model.data = { ... new SquadGroup, squad_group_id: squad_group_id } as SquadGroup;
      this.createLog();
      return ({ flag: true, message: 'Squad group successfully deleted!' });
    }).catch((err: any) => {
      return ({ flag: false, message: err.message });
    });
  }
}
