import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { RequestOff, RequestOffService, WhereQueryInterface } from '@penji/shared/data-access';
import { ContentHeightDirective } from '@penji/shared/directives';
import { ConvertTimezonePipe } from '@penji/shared/pipes';
import { InputComponent } from '@penji/shared/ui/element/input';
import { RequestOffTypeComponent } from '@penji/team/request-off/request-off-ui/request-off-type';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import * as firebase from 'firebase/firestore';
import { InViewportAction, InViewportDirective } from 'ng-in-viewport';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-w-upcoming-off',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    NzTableModule,
    RouterModule,
    NzButtonModule,
    NzAlertModule,
    NzIconModule,
    ConvertTimezonePipe,
    NzPopoverModule,
    SvgIconComponent,
    NzPopconfirmModule,
    UserItemComponent,
    RequestOffTypeComponent,
    ContentHeightDirective,
    InViewportDirective,
    NzSkeletonModule,
    NzSpinModule
  ],
  templateUrl: './w-upcoming-off.component.html',
  styleUrls: ['./w-upcoming-off.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WUpcomingOffComponent {

  limit = 50;
  requestOffSV = inject(RequestOffService);
  list_upcoming_off$!: Observable<RequestOff[]>;
  container_height = 0;
  show_view_port: any = {};
  sortOffAt = (a: RequestOff, b: RequestOff) => (a.off_at < b.off_at) ? 1 : -1;
  expand = new Set<string>();

  constructor() {
    const where_query_list: Array<WhereQueryInterface> = [
      { field_name: 'approved', field_operator: '==', field_value: 1 },
      { field_name: 'off_at', field_operator: '>', field_value: firebase.Timestamp.fromDate(new Date((new Date()).setHours(23, 59, 59))) }
    ];
    this.list_upcoming_off$ = this.requestOffSV.listRequestOffRealTime(this.limit, where_query_list);
  }
  getHeight(height: any){
    this.container_height = height;
  }
  viewPort(event: InViewportAction, id: string): void {
    if(event.visible){
      this.show_view_port[id] = true;
    }
  }
  trackBy(index:any, item: any) {
    return item.id;
  }
  expandRequestOff(id: string) {
    if (this.expand.has(id)) {
      this.expand.delete(id);
    } else {
      this.expand.clear();
      this.expand.add(id);
    }
  }
}
