/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { Permission, initLeaderPermission, initMemberPermission } from '../models/permission.model';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { LogService } from './log.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
    providedIn: 'root'
})
export class PermissionService extends LogService<Permission> {
    nzMessageService = inject(NzMessageService);
    afa = inject(AngularFireAuth);
    data$ = this.afa.authState.pipe(
        switchMap(user => {
            return this.afs.collection('permission').doc<Permission>(user?.uid).valueChanges().pipe(map(rs => ({ id: user?.uid, ...rs } as Permission)));
        })
    )
    constructor(){
        super();
        this.data$.subscribe(rs=>console.log('permission', rs));
    }
    createPermission(user_id: string, type: 'leader' | 'member' = 'member') {
        let permission: Permission;
        if (type === 'leader')
            permission = initLeaderPermission as Permission;
        else
            permission = initMemberPermission as Permission;
        return this.afs.firestore.collection('permission').doc(user_id).set({
            ...permission
        }, { merge: true }).then(() => {
            this.log_model.action = 'create';
            this.log_model.data = { ...new Permission, ...permission };
            this.createLog();
            this.nzMessageService.success('create successfully permission!');
            return { flag: true, message: 'create successfully permission!' };
        })
    }
    updatePermission(user_id: string, key: string, value: boolean) {
        return this.afs.firestore.collection('permission').doc(user_id).set({ [key]: value }, { merge: true }).then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ...new Permission, ...{ [key]: value } };
            this.createLog();
            this.nzMessageService.success('update successfully permission!');
            return { flag: true, message: 'update successfully permission!' };
        })
    }
    updateExtraAction(user_id: string, key: string, value: boolean) {

        return this.afs.firestore.collection('permission').doc(user_id).update({ [`extra-action.${key}`]: value }).then(() => {
            this.log_model.action = 'update';
            this.log_model.data = { ...new Permission, ...{ [`extra_action.${key}`]: value } };
            this.createLog();
            this.nzMessageService.success('update successfully permission!');
            return { flag: true, message: 'update successfully permission!' };
        })
    }
    getPermissionUser(user_id: string) {
        return this.afs.collection('permission').doc<Permission>(user_id).valueChanges().pipe(map(rs => ({ id: user_id, ...rs } as Permission)));
    }
}



