import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { arrowDownIcon, attachIcon, bellIcon, cancelCircleIcon, cancelShapeIcon, checkCircleIcon, checkFillIcon, checkIcon, chevronDownIcon, circleFillIcon, circleIcon, closeIcon, cloudUploadIcon, copyIcon, creditCardIcon, dashboardIcon, daytimetIcon, deleteIcon, deleteIcon2, discoverIcon, dotIcon, doubleChevronRightIcon, downloadIcon, editIcon, emailIcon, errorIcon, fileTextIcon, folderIcon, fontIcon, goBackIcon, homeIcon, infoIcon, loadingAnimatedIcon, lockIcon, logoPurpleIcon, menuIcon, noSeeIcon, notification, optionIcon, pictureIcon, plusCircleIcon, plusIcon, projectIcon, searchIcon, seeIcon, sendIcon, settings, thinPlusIcon, threeMonthIcon, usersIcon, zoomIcon, heartIcon, arrowCircleRightIcon, arrowCircleLeftIcon, userIcon, userPlusIcon, briefCaseIcon, userListIcon, dotVerticalIcon, ticket, logs, templateAnswer, faceNegativeIcon, faceNeutralIcon, facePositiveIcon, calendarIcon, itemListIcon, layoutSidebarInsetReverseIcon, arrowMoveIcon, threeDotsIcon, chevronRightIcon, filterIcon, fireIcon, noSeeIconFilled, seeIconFilled, exportIcon, folderFillIcon, clockFillIcon, fullScreenIcon, squareFillIcon, angleExpandIcon, pencilIcon } from './svg-icon/icon';

@NgModule({
  imports: [CommonModule],
  providers: [
    provideSvgIcons([
      homeIcon,
      searchIcon,
      plusIcon,
      thinPlusIcon,
      logoPurpleIcon,
      loadingAnimatedIcon,
      closeIcon,
      editIcon,
      infoIcon,
      cancelCircleIcon,
      attachIcon,
      optionIcon,
      deleteIcon,
      deleteIcon2,
      pictureIcon,
      fontIcon,
      fileTextIcon,
      cloudUploadIcon,
      dashboardIcon,
      menuIcon,
      bellIcon,
      arrowDownIcon,
      discoverIcon,
      folderIcon,
      usersIcon,
      projectIcon,
      lockIcon,
      goBackIcon,
      downloadIcon,
      zoomIcon,
      emailIcon,
      seeIcon,
      noSeeIcon,
      creditCardIcon,
      cancelShapeIcon,
      sendIcon,
      settings,
      notification,
      // add 7/13/2023
      circleIcon,
      checkFillIcon,
      errorIcon,
      checkCircleIcon,
      copyIcon,
      checkIcon,
      // Add 7/14/2023
      circleFillIcon,
      // Add 7/20/2023
      doubleChevronRightIcon,
      layoutSidebarInsetReverseIcon,
      // Add 7/21/2023
      daytimetIcon,
      threeMonthIcon,
      dotIcon,
      dotVerticalIcon,
      plusCircleIcon,
      // Add 7/25/2023
      chevronDownIcon,
      heartIcon,
      // Add 7/26/2023
      arrowCircleRightIcon,
      arrowCircleLeftIcon,
      userIcon,
      userPlusIcon,
      briefCaseIcon,
      userListIcon,
      ticket,
      logs,
      templateAnswer,
      //7/28/2023
      facePositiveIcon,
      faceNeutralIcon,
      faceNegativeIcon,
      calendarIcon,
      itemListIcon,
      arrowMoveIcon,
      threeDotsIcon,
      //7/28/2023
      chevronRightIcon,
      filterIcon,
      fireIcon,
      //8/7/2023
      seeIconFilled,
      noSeeIconFilled,
      exportIcon,
      folderFillIcon,
      clockFillIcon,
      fullScreenIcon,
      squareFillIcon,
      angleExpandIcon,
      pencilIcon
    ]),
  ],
})
export class SvgIconModule {}
