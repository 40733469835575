import { ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { AuthStore } from '@penji/shared/auth/data-access';
import { UserData, UserService, WatchListService } from '@penji/shared/data-access';

@Component({
  selector: 'penji-customer-watch-icon',
  standalone: true,
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './customer-watch-icon.component.html',
  styleUrls: ['./customer-watch-icon.component.scss'],
})
export class CustomerWatchIconComponent {

  @Input() icon_size: number = 20;
  @Input() customer_id!: string;

  cdr = inject(ChangeDetectorRef);
  watchSV = inject(WatchListService);
  userSV = inject(UserService);
  authStore = inject(AuthStore);
  auth_profile$ = this.authStore.authProfile$;

  async followClient(user: UserData, customer_id: string) {
    if (user.customer_following) {
      user.customer_following.push(customer_id);
    } else {
      user.customer_following = [...customer_id];
    }
    const rs = await this.userSV.updateUser(user.uid+'', user);
    if (rs.flag) {
      this.authStore.updateAuthProfile$(user);
      this.cdr.detectChanges();
    }
  }
  async unfollowClient(user: UserData, customer_id: string) {
    if (user.customer_following) {
      const index = user.customer_following.indexOf(customer_id);
      user.customer_following.splice(index, 1);
    }
    const rs = await this.userSV.updateUser(user.uid+'', user);
    if (rs.flag) {
      this.authStore.updateAuthProfile$(user);
      this.cdr.detectChanges();
    }
  }
}
