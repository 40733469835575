import { Pipe, PipeTransform, inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Pipe({
  name: 'stringToRef',
  standalone: true
})
export class StringToRefPipe implements PipeTransform {

  afs = inject(AngularFirestore);
  transform(value: string, args?: any) {
    if(value)
      return this.afs.firestore.doc(value);
    return null;
  }

}
