import { inject, Injectable } from '@angular/core';
import { Query } from '@angular/fire/compat/firestore';
import { NzMessageService } from 'ng-zorro-antd/message';
import { of } from 'rxjs';

import { LogService } from './log.service';
import { Discussion } from '../models/discussion.model';

@Injectable({
  providedIn: 'root'
})
export class MessageNoteService extends LogService<Discussion>{

  nzMessageService = inject(NzMessageService);

  getListComment(design_id: string) {
    let query: Query = this.afs.firestore.collection("discussion_v2");

    // if(id_link){
    //   query = query.where('id_link', '==', id_link);
    // }

    query = query.where('discussion_design_id', '==', design_id);
    query = query.where('removed', '==', false);
    query = query.orderBy('created_at', 'desc');

    return query.get().then(querySnapshot => {
      const list: Array<Discussion> = [];
      console.log(querySnapshot);

      querySnapshot.forEach(doc=>{
        const data = doc.data() as Discussion;
        data.id = doc.id;
        list.push(data);
      });
      return list;
    }).catch((err) => {
      console.log(err);
      return of(null)
    });
  }

  // addMessageNote(client_team_id: string, project_id: string, design_id: string, data: Comment) {
  //   return this.afs.firestore.collection("team").doc(client_team_id)
  //     .collection("project").doc(project_id)
  //     .collection("discussion").doc(design_id)
  //     .collection("comment").add({...data})
  //     .then((rs)=> {
  //       this.log_model.action = 'add';
  //       this.log_model.data = {...new Discussion, ...data};
  //       this.createLog();
  //       const comment_temp = {...data} as Comment;
  //       comment_temp.id = rs.id;
  //       this.nzMessageService.success('Added successfully!');
  //       return { flag: true, message: 'Comment successfully added!', data: comment_temp}
  //     })
  //     .catch(error=> ({ flag: false, message: error.message, data: {} as Comment }));
  // }

  // updateMessageNote(client_team_id: string, project_id: string, design_id: string, data: Comment) {
  //   return this.afs.firestore.collection("team").doc(client_team_id)
  //     .collection("project").doc(project_id)
  //     .collection("discussion").doc(design_id)
  //     .collection("comment").doc(data.id).update({...data})
  //     .then(()=> {
  //       this.log_model.action = 'update';
  //       this.log_model.data = {...new Comment, ...data};
  //       this.createLog();
  //       this.nzMessageService.success('Comment updated successfully!');
  //       return { flag: true, message: 'Comment updated successfully!'}
  //     })
  //     .catch(error=> ({ flag: false, message: error.message }));
  // }

  // deleteMessageNote(client_team_id: string, project_id: string, design_id: string, data: Comment) {
  //   return this.afs.firestore.collection("team").doc(client_team_id)
  //     .collection("project").doc(project_id)
  //     .collection("discussion").doc(design_id)
  //     .collection("comment").doc(data.id).update({...data})
  //     .then(()=> {
  //       this.log_model.action = 'delete';
  //       this.log_model.data = {...new Comment, ...data};
  //       this.createLog();
  //       this.nzMessageService.success('Comment deleted successfully!');
  //       return { flag: true, message: 'Comment deleted successfully!'}
  //     })
  //     .catch(error=> ({ flag: false, message: error.message }));
  // }

}
