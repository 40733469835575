import { Injectable, inject } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Overtime, OvertimeService, WhereQueryInterface } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, concatMap, map, mergeMap, of, switchMap, tap } from 'rxjs';
import * as firebase from 'firebase/firestore';

export interface OvertimeState extends EntityState<Overtime> {
    loading: boolean,
    disable_previous: boolean,
    disable_next: boolean,
    error: string
}
export const adapter = createEntityAdapter<Overtime>();
export const initialState: OvertimeState = adapter.getInitialState({
    loading: false,
    disable_previous: false,
    disable_next: false,
    error: ''
})
const { selectAll } = adapter.getSelectors();
@Injectable({
    providedIn: 'root'
})
export class OvertimeStoreService extends ComponentStore<OvertimeState>{

    limit = 20;

    OvertimeSV = inject(OvertimeService);
    constructor() { super(initialState); }

    data$ = this.select(selectAll);
    loading$ = this.select((s) => s.loading);
    disable_previous$ = this.select((s) => s.disable_previous);
    disable_next$ = this.select((s) => s.disable_next);
    error$ = this.select((s) => s.error);

    loadOvertime$ = this.effect((params$: Observable<{}>) => {
        return params$.pipe(
            tap(() => {
                this.setState((state) => adapter.removeAll(state));
                this.patchState({ loading: true, disable_previous: false, disable_next: false, error: '' })
            }),
            switchMap((params: any) => {
                const where_query: Array<WhereQueryInterface> = [];
                let start_after;
                let end_before;
                if (params) {
                    for (const k in params) {
                        if (k == 'limit') {
                            this.limit = params[k];
                        } else if (k == 'start_after') {
                            start_after = params[k];
                        } else if (k == 'end_before') {
                            end_before = params[k];
                        } else if (k == 'start_at') {
                            where_query.push({
                                field_name: 'created_at',
                                field_operator: '>=',
                                field_value: firebase.Timestamp.fromDate(new Date(parseInt(params[k])))
                            });
                        } else if (k == 'end_at') {
                            where_query.push({
                                field_name: 'created_at',
                                field_operator: '<=',
                                field_value: firebase.Timestamp.fromDate(new Date(parseInt(params[k])))
                            });
                        }else if (k == 'approved') {
                            where_query.push({
                                field_name: k, field_operator: '==', field_value: parseInt(params[k])
                            });
                        } else {
                            where_query.push({
                                field_name: k, field_operator: '==',
                                field_value: params[k] == 'true' ? true : params[k] == 'false' ? false : params[k]
                            });
                        }
                    }
                }
                return this.OvertimeSV.listOvertime(this.limit, where_query, start_after, end_before).then(rs => {
                    if (rs && rs.length > 0) {
                        const promise = [
                            this.OvertimeSV.listOvertime(1, where_query, undefined, rs[0].id),
                            this.OvertimeSV.listOvertime(1, where_query, rs[rs.length - 1].id, undefined)
                        ]
                        return combineLatest(of(rs), Promise.all(promise)).pipe(
                            map(([rs, before_after]) => {
                                if (before_after[0] && before_after[0].length > 0) {
                                    this.patchState({ disable_previous: false });
                                } else {
                                    this.patchState({ disable_previous: true });
                                }
                                if (before_after[1] && before_after[1].length > 0) {
                                    this.patchState({ disable_next: false });
                                } else {
                                    this.patchState({ disable_next: true });
                                }
                                return rs;
                            })
                        )
                    } else {
                        return of([] as Overtime[]);
                    }
                }).catch(err => {
                    console.log(err);
                    return of([] as Overtime[]);
                })
            }),
            switchMap(rs => rs),
            map(final_list => {
                if (final_list && final_list.length > 0) {
                    this.setState((state) => adapter.setAll(final_list, state));
                    this.patchState({ loading: false });
                }
                if (final_list.length == 0 || final_list.length < this.limit) {
                    this.patchState({ loading: false });
                    if (final_list.length == 0) {
                        this.patchState({ disable_previous: true, disable_next: true });
                    }
                }
            }),
            catchError(err => {
                this.patchState({ loading: false });
                return EMPTY;
            })
        )
    })

    updateOvertime$ = this.effect((Overtime$: Observable<Overtime>) => {
        return Overtime$.pipe(
            tap(() => this.patchState({ loading: true, error: '' })),
            mergeMap((Overtime: Overtime) => {
                return combineLatest(this.OvertimeSV.updateOvertimeVer5(Overtime), of(Overtime));
            }),
            map(([rs, Overtime]) => {
                if (rs.flag) {
                    this.setState((state) => adapter.updateOne({ id: Overtime.id, changes: Overtime }, state));
                    this.patchState({ loading: false });
                } else {
                    this.patchState({ loading: false, error: rs.message });
                }
            }),
            catchError(err => {
                this.patchState({ loading: false, error: err as string });
                return EMPTY;
            })
        )
    })

    deleteOvertime$ = this.effect((overtime_id$: Observable<string>) => {
        return overtime_id$.pipe(
            tap(() => this.patchState({ loading: true, error: '' })),
            mergeMap((overtime_id: string) => {
                return combineLatest(this.OvertimeSV.deleteOvertimeVer5(overtime_id), of(overtime_id));
            }),
            map(([rs, overtime_id]) => {
                if (rs.flag) {
                    this.setState((state) => adapter.removeOne(overtime_id, state));
                    this.patchState({ loading: false });
                } else {
                    console.log(rs);
                    this.patchState({ loading: false, error: rs.message });
                }
            }),
            catchError(err => {
                console.log(err);
                this.patchState({ loading: false, error: err as string });
                return EMPTY;
            })
        )
    })

    addOvertime$ = this.effect((overtime$: Observable<Overtime>) => {
        return overtime$.pipe(
            tap(() => this.patchState({ loading: true, error: '' })),
            mergeMap((Overtime: Overtime) => {
                return this.OvertimeSV.addOvertimeVer5(Overtime);
            }),
            map(rs => {
                if (rs.flag) {
                    this.setState((state) => adapter.setOne(rs.data, state));
                    this.patchState({ loading: false });
                } else {
                    this.patchState({ loading: false, error: rs.message });
                }
            }),
            catchError(err => {
                this.patchState({ loading: false, error: err as string });
                return EMPTY;
            })
        )
    })
}
