import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SquadGroupStoreService } from '@penji/team/squad-group/data-access';
import { SquadGroup } from '@penji/shared/data-access';
import { Observable } from 'rxjs';

@Component({
  selector: 'penji-squad-group-name',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './squad-group-name.component.html',
  styleUrls: ['./squad-group-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SquadGroupNameComponent {

  @Input() squad_group_id: string | undefined = '';

  squadGroupStoreSv = inject(SquadGroupStoreService);
  squad_group$: Observable<SquadGroup> | undefined;

  ngOnChanges(): void {
    if(this.squad_group_id)
      this.squad_group$ = this.squadGroupStoreSv.getOneSquadGroup(this.squad_group_id);
  }
}
