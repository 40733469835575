<div class="flex space-x-1">
    <ng-container *ngIf="assign_by && assign_by.length > 0">
        <penji-user-array-item [uid_array]="assign_by ? assign_by:[]"></penji-user-array-item>
    </ng-container>
    <ng-container *ngIf="reassign_on">
        <button nz-button nzType="primary" nzShape="circle" nzSize="small" nz-popover nzPopoverTitle="Assign Designer"
            [(nzPopoverVisible)]="visible" nzPopoverTrigger="click" [nzPopoverContent]="reassign_content"
            nzPopoverOverlayClassName="popover-filter">
            <svg-icon key="plus" fontSize="12px"></svg-icon>
        </button>
        <ng-template #reassign_content>
            <svg-icon key="cancel-shape" fontSize="26px"
                class="text-gray-800 hover:text-gray-500 cursor-pointer -top-7 right-4 absolute z-10"
                (click)="visible = false"></svg-icon>
            <penji-user-select [list_user_id]="assign_by ? assign_by:[]" [role]="[2,4,7]" [multiple_select]="false"
                (list_user_id_output)="getListUserId($event)"></penji-user-select>
        </ng-template>
    </ng-container>
</div>