import { ChangeDetectorRef, Component, Input, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { Project, ProjectService } from '@penji/shared/data-access';
import { Observable, interval, map } from 'rxjs';

@Component({
  selector: 'penji-project-deadline-item',
  standalone: true,
  imports: [CommonModule, NzStatisticModule],
  templateUrl: './project-deadline-item.component.html',
  styleUrls: ['./project-deadline-item.component.scss'],
})
export class ProjectDeadlineItemComponent {

  projectSV = inject(ProjectService);
  cdr = inject(ChangeDetectorRef);
  @Input() project_data!: Project;
  overdue: boolean = false;
  overdue_time = 3600 * 24;// one day
  now: any = new Date().getTime() / 1000;
  deadline: any;

  temp$!: Observable<any>;

  ngOnInit() {
    this.temp$ = interval(1000).pipe(map(() => {
      this.cdr.detectChanges();
    }));
  }
  ngOnChanges() {
    if (this.project_data) {
      if (this.project_data.time_change) {
        this.deadline = Date.now() + 1000 * ((this.project_data.time_change.seconds + 3600 * 24) - this.now);
        if ((this.project_data?.time_change?.seconds + this.overdue_time) < this.now && this.project_data.status != 4 && this.project_data.status != 5 && this.project_data.status != 6 && this.project_data.in_queue == false)
          this.overdue = true;
      }
    }
  }
}

