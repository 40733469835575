import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { Overtime } from '@penji/shared/data-access';
import { OvertimeStoreService } from '@penji/team/overtime/data-access';
import { OvertimeCreateUpdateComponent } from '@penji/team/overtime/overtime-ui/overtime-create-update';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@Component({
  selector: 'penji-overtime-button-update',
  standalone: true,
  imports: [CommonModule, NzButtonModule, NzPopoverModule],
  templateUrl: './overtime-button-update.component.html',
  styleUrls: ['./overtime-button-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OvertimeButtonUpdateComponent {

  @Input() action: string = 'approve'; // approve, reject, update
  @Input() overtime!: Overtime;
  modal = inject(NzModalService);
  overtimeStore = inject(OvertimeStoreService);
  visible = false;

  approvedOvertime() {
    if (this.overtime) {
      this.overtime.approved = 1;
      this.overtimeStore.updateOvertime$(this.overtime);
    }
    this.visible = false;
  }
  rejectOvertime() {
    if (this.overtime) {
      this.overtime.approved = 2;
      this.overtimeStore.updateOvertime$(this.overtime);
    }
    this.visible = false;
  }
  updateOvertime() {
    const ref: NzModalRef = this.modal.create({
      nzContent: OvertimeCreateUpdateComponent,
      nzBodyStyle: { padding: '0', overflow: 'auto', background: '#fff' },
      nzComponentParams: {
        form_type: 'edit',
        overtime: this.overtime
      },
      nzWidth: '550px',
      nzStyle: { top: '14px' },
      nzFooter: null,
    });
  }
  deleteOvertime() {
    if (this.overtime && this.overtime.id)
      this.overtimeStore.deleteOvertime$(this.overtime.id);
  }
}
