import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { SvgIconComponent } from '@ngneat/svg-icon';

@Component({
  selector: 'penji-user-array-item',
  standalone: true,
  imports: [
    CommonModule,
    UserItemComponent,
    NzDropDownModule,
    NzButtonModule,
    SvgIconComponent
  ],
  templateUrl: './user-array-item.component.html',
  styleUrls: ['./user-array-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserArrayItemComponent {

  @Input() uid_array?: string[];
  @Input() user_size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' = 'md';
}
