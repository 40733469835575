import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { UserItemComponent } from '@penji/team/shared/shared-ui/user-item';
import { AuthService, AuthStore } from '@penji/shared/auth/data-access';

@Component({
  selector: 'penji-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SvgIconComponent,
    NzDropDownModule,
    UserItemComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {

  authStore = inject(AuthStore);
  authSV = inject(AuthService);

  uid$ = this.authStore.auth_id$;
}
