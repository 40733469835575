/* eslint-disable */
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Project, ProjectService, WhereQueryInterface } from '@penji/shared/data-access';
import { EMPTY, Observable, catchError, combineLatest, distinctUntilChanged, map, mergeMap, of, switchMap, take, tap } from 'rxjs';
import * as firebase from 'firebase/firestore';

interface ProjectState extends EntityState<Project> {
  loading: boolean,
  error: string,
  current_project_data: Project | null,
  nbHits: number,
  page: number,
  hitsPerPage: number
}
const adapter = createEntityAdapter<Project>();
const initialState: ProjectState = adapter.getInitialState({
  loading: false,
  error: '',
  current_project_data: null,
  nbHits: 0,
  page: 0,
  hitsPerPage: 0
})
@Injectable()
export class ProjectStoreService extends ComponentStore<ProjectState> {

  projectSV = inject(ProjectService);
  constructor() { super(initialState); }

  readonly data$ = this.select(s => {
    const list = Object.values(s.entities) as Project[];
    return list.sort((a: any, b: any) => (a.created_at < b.created_at) ? 1 : -1);
  });
  readonly loading$ = this.select(s => s.loading);
  readonly error$ = this.select((s) => s.error);
  private readonly nbHits$ = this.select((s) => s.nbHits);
  private readonly hitsPerPage$ = this.select((s) => s.hitsPerPage);
  private readonly page$ = this.select((s) => s.page);
  readonly current_project_data$ = this.select((s) => s.current_project_data);
  readonly vm$ = this.select(
    this.data$,
    this.nbHits$,
    this.hitsPerPage$,
    this.page$,
    (data, nbHits, hitsPerPage, page) => ({
      data,
      nbHits,
      hitsPerPage,
      page
    })
  );
  loadProjects$ = this.effect((params$: Observable<any>) => {
    return params$.pipe(
      tap(() => {
        this.patchState({ loading: true, error: '' });
        this.setState(s => adapter.removeAll(s));
      }),
      switchMap((params: any) => {
        return this.projectSV.getListProjectAlgolia(params).pipe(
          map((result: any) => {
            if (result.success) {
              const data = result.data.hits as Project[];
              this.setState(s => adapter.setAll(data, s))
              this.patchState({ nbHits: result.data.nbHits, hitsPerPage: result.data.hitsPerPage, page: result.data.page, loading: false, error: '' });
            } else {
              this.patchState({ loading: false, error: 'error get projects filter api' });
            }
          })
        )
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        throw new Error('cannot get api!');
        return EMPTY;
      })
    )
  });
  loadProjectsForWidget$ = this.effect((params$: Observable<any>) => {
    return params$.pipe(
      tap(() => {
        this.patchState({ loading: true, error: '' });
        this.setState(s => adapter.removeAll(s));
      }),
      switchMap((params: any) => {
        return this.projectSV.getListProjectRealTime(-1, params.where_query,undefined, undefined, params.order_by, params.order_desc, params.not_order_by).pipe(
          map(rs => {
            rs = rs.filter(pj => pj.status_addition != 3);
            return rs;
          })
        );
      }),
      map(list => {
        this.setState(state => adapter.setAll(list, state));
        this.patchState({ loading: false, error: '' });
        return list;
      })
    );
  })
  createProject$ = this.effect((project$: Observable<Project>) => {
    return project$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((project: Project) => {
        return this.projectSV.addProject(project);
      }),
      map(rs => {
        if (rs.flag) {
          this.setState((state) => adapter.setOne(rs.data, state));
          this.patchState({ loading: false });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  updateProject$ = this.effect((project$: Observable<Project>) => {
    return project$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((project: Project) => {
        return combineLatest(this.projectSV.updateProject(project), of(project), this.current_project_data$);
      }),
      map(([rs, project, current_project]) => {
        if (rs.flag) {
          this.setState((state) => adapter.updateOne({ id: project.id, changes: project }, state));
          
          //update current project state
          if(current_project && project.id == current_project.id)
            this.patchState({current_project_data: project});

          this.patchState({ loading: false });
        } else {
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  deleteProject$ = this.effect((project$: Observable<Project>) => {
    return project$.pipe(
      tap(() => this.patchState({ loading: true, error: '' })),
      mergeMap((project: Project) => {
        return combineLatest(this.projectSV.deleteProject(project), of(project));
      }),
      map(([rs, project]) => {
        if (rs.flag) {
          project.status = 8;
          this.setState((state) => adapter.updateOne({ id: project.id, changes: project }, state));
          this.patchState({ loading: false });
        } else {
          console.log(rs);
          this.patchState({ loading: false, error: rs.message });
        }
      }),
      catchError(err => {
        console.log(err);
        this.patchState({ loading: false, error: err as string });
        return EMPTY;
      })
    )
  });

  getProjectDetail$ = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      distinctUntilChanged((prev, curr) => prev['project_id'] === curr['project_id']),
      tap(() => this.patchState({ current_project_data: null })),
      switchMap((params: Params) => {
        return this.select((s) => s.entities[params['project_id']]).pipe(
          take(1),
          switchMap(rs => {
            if (rs) {
              this.patchState({ current_project_data: rs as Project });
              return EMPTY;
            } else {
              console.log(params['project_id']);
              return this.projectSV.getProjectDetail(params['project_id']).pipe(
                take(1),
                switchMap(rs => {
                  if (rs) {
                    this.setState((state) => adapter.setOne(rs, state));
                    this.patchState({ current_project_data: rs as Project });
                    return EMPTY;
                  } else {
                    this.patchState({ current_project_data: null });
                    return EMPTY;
                  }
                })
              )
            }
          })
        )
      })
    )
  });
}
