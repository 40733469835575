import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Project } from '@penji/shared/data-access';
import { ProjectStoreService } from '@penji/team/project/data-access';
import { UserSelectComponent } from '@penji/team/shared/shared-ui/user-select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

@Component({
  selector: 'penji-project-reassign',
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    NzButtonModule,
    NzPopoverModule,
    UserSelectComponent
  ],
  templateUrl: './project-reassign.component.html',
  styleUrls: ['./project-reassign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectReassignComponent {

  @Input() project_data!: Project;

  ProjectStoreService = inject(ProjectStoreService);

  visible = false;

  getListUserId(list_id: string[]){
    let data = new Project();
    data = {...this.project_data};
    data.assign_by = list_id;
    this.ProjectStoreService.updateProject$(data);
    this.visible = false;
  }
}
